import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 *
 * @export
 * @class CustomCurrencyPipe
 * @implements {PipeTransform}
 */
@Pipe({name: 'customCurrency'})
export class CustomCurrencyPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {number} value
   * @returns {string}
   * @memberof CustomCurrencyPipe
   */
  public transform(value: number): string {
    let p = value.toFixed(2).split('.');
    let chars = p[0].split('').reverse();
    let newstr = '';
    let count = 0;
    for (let x of chars) {
        count++;
        if(count%3 === 1 && count !== 1) {
            newstr = `${x},${newstr}`;
        } else {
            newstr = x + newstr;
        }
    }
    let cents: string = p[1].toString();

    return newstr.concat(`.<span class="cents">${ cents }</span><em class="coin"> MXN</em>`);
  }
}

