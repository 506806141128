/**
 * Credit Card Full Data
 *
 * @export
 * @class CreditCard
 */
export class CreditCard {
  /**
   * numero de tarjeta 
   *
   * @type {string}
   * @memberof CreditCard
   */
  public cardNumber: string;
  /**
   * buc del usuario 
   *
   * @type {string}
   * @memberof CreditCard
   */
  public buc: string;
  /**
   * cuenta del usuario 
   *
   * @type {string}
   * @memberof CreditCard
   */
  public account: string;
  /**
   * sucursal del aplicativo
   *
   * @type {string}
   * @memberof CreditCard
   */
  public sucursalAp: string;
  /**
   * branch de la tarjeta 
   *
   * @type {string}
   * @memberof CreditCard
   */
  public cardBrand: string;
  /**
   * nombre de usuario 
   *
   * @type {string}
   * @memberof CreditCard
   */
  public userName: string;
  /**
   * the balance 
   *
   * @type {number}
   * @memberothef CreditCard
   */
  public balance: number;
  /**
   * limite de credito 
   *
   * @type {number}
   * @memberof CreditCard
   */
  public creditLimit: number;
  /**
   * pago minimo 
   *
   * @type {number}
   * @memberof CreditCard
   */
  public minPayment: number;
  /**
   * the crad limit 
   *
   * @type {number}
   * @memberof CreditCard
   */
  public limit: number;
  /**
   * fecha de corte 
   *
   * @type {string}
   * @memberof CreditCard
   */
  public cutoffData: string;
  /**
   * tipo de tarjeta
   *
   * @type {string}
   * @memberof CreditCard
   */
  public cardType: string;
  /**
   * descripcion de tarjeta 
   *
   * @type {string}
   * @memberof CreditCard
   */
  public cardDesc: string;
  /**
   * producto de la tarjeta 
   *
   * @type {number}
   * @memberof CreditCard
   */
  public cardProductID: number;

  /**
   * Creates an instance of CreditCard.
   * @param {string} _cn
   * @param {string} _buc
   * @param {string} _account
   * @param {string} _sucursalAp
   * @param {string} _cb
   * @param {string} _un
   * @param {number} _bl
   * @param {number} _mp
   * @param {string} _cd
   * @param {string} _ct
   * @param {number} _limit
   * @param {string} _cdesc
   * @param {number} [_cp=2]
   * @memberof CreditCard
   */
  constructor(
    _cn: string,
    _buc: string,
    _account: string,
    _sucursalAp: string,
    _cb: string,
    _un: string,
    _bl: number,
    _mp: number,
    _cd: string,
    _ct: string,
    _limit: number,
    _cdesc: string,
    _cp = 2
  ) {
    this.cardNumber = _cn;
    this.buc = _buc;
    this.account = _account;
    this.sucursalAp = _sucursalAp;
    this.cardBrand = _cb;
    this.userName = this.formatName(_un);
    this.balance = _bl;
    this.minPayment = _mp;
    this.cutoffData = _cd;
    this.cardType = _ct;
    this.creditLimit = _limit;
    this.cardDesc = _cdesc;
    this.cardProductID = _cp;
  }

  /**
   * Format the name of the user.
   *
   * @param {string} v
   * @returns {string}
   * @memberof CreditCard
   */
  public formatName(v: string): string {
    let res = '';
    res = v.replace('/', ' ');
    return res;
  }
}
