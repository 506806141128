import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
// import { PersistenceService, StorageType } from 'angular-persistence';
import { Observable, throwError } from "rxjs";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { map, catchError, timeout } from "rxjs/operators";
import * as _ from "lodash";

// Constants
import { ConstantsService } from "./constants.service";
// Modal component
import { AlertComponent } from "./../partials/alert";
import { DataProxyService } from "./data-proxy.service";

//Services
import { SessionStorageService } from "./tdd/session-storage.service";
import { Router } from "@angular/router";

/**
 *
 *
 * @export
 * @class DataService
 */
@Injectable()
export class DataService {
  /**
   * the time out
   *
   * @private
   * @type {number}
   * @memberof DataService
   */
  private timeout: number;
  /**
   * the midelware url
   *
   * @private
   * @memberof DataService
   */
  private MDW_URL = "";
  /**
   * the rest url
   *
   * @private
   * @memberof DataService
   */
  private REST_URL = "";
  /**
   * the catalogs url
   *
   * @private
   * @memberof DataService
   */
  private CATALOGS_URL = "";
  /**
   * the user rest url
   *
   * @private
   * @memberof DataService
   */
  private USER_REST_URL = "";
  /**
   * the wallet rest url
   *
   * @private
   * @memberof DataService
   */
  private WALLET_REST_URL = "";
  /**
   * the folios rest url
   *
   * @private
   * @memberof DataService
   */
  private FOLIOS_REST_URL = "";
  /**
   * the sso token url
   *
   * @private
   * @memberof DataService
   */
  private SSO_TOKEN_URL = "";
  /**
   * the url of the service debit
   *
   * @private
   * @memberof DataService
   */
  private DEBIT_URL = "";
  /**
   * the rating url
   *
   * @private
   * @memberof DataService
   */
  private RATING_URL = "";
  /**
   * Modal reference
   *
   * @private
   * @type {BsModalRef}
   * @memberof DataService
   */
  private modalRef: BsModalRef;

  /**
   * Creates an instance of DataService.
   * @param {HttpClient} http
   * @param {ConstantsService} constantsService
   * @param {BsModalService} modalService
   * @param {DataProxyService} dataProxyService
   * @param {DataProxyService} dataProxy
   * @param {PersistenceService} persistenceService
   * @param {SessionStorageService} storage
   * @memberof DataService
   */
  constructor(
    private http: HttpClient,
    private constantsService: ConstantsService,
    private modalService: BsModalService,
    private dataProxyService: DataProxyService,
    private dataProxy: DataProxyService,
    // private persistenceService: PersistenceService,
    private httpClient: HttpClient,
    private storage: SessionStorageService,
    private router: Router
  ) {
    // persistenceService.defineProperty(
    //   this, 'RATING_URL', 'ratingUrlProperty',
    //   {type: StorageType.MEMORY});
  }

  /**
   * Set URL's
   *
   * @param {string} env
   * @memberof DataService
   */
  public setUris(env: string) {
    // Search the enviroment
    const enviroment = _.find(this.constantsService.ENVIROMENT, (item) => {
      return item.env === env;
    });
    // Define
    if (process.env.NODE_ENV === "development") {
      this.MDW_URL = enviroment.url;
    } else {
      this.MDW_URL = "/";
    }
    this.REST_URL = this.MDW_URL.concat("v2/api-backend");
    this.CATALOGS_URL = this.MDW_URL.concat("v2/catalogs");
    this.USER_REST_URL = this.MDW_URL.concat("v1/api-user");
    this.WALLET_REST_URL = this.MDW_URL.concat("v2/cards");
    this.FOLIOS_REST_URL = this.MDW_URL.concat("v2/folios");
    this.SSO_TOKEN_URL = this.MDW_URL.concat("v2/sso");
    this.DEBIT_URL = this.MDW_URL.concat("v1/debitcards");
    this.setRatingUrl(enviroment.rating);
  }

  /**
   * Set the URL rating.
   *
   * @param {string} value
   * @memberof DataService
   */
  public setRatingUrl(value: string): void {
    this.RATING_URL = value;
  }

  /**
   * Get the rating URL.
   *
   * @returns {string}
   * @memberof DataService
   */
  public getRatingUrl(): string {
    return this.RATING_URL;
  }

  /**
   * Get data from URL.
   *
   * @param {string} url
   * @returns {Observable<any>}
   * @memberof DataService
   */
  public getData(url: string): Observable<any> {
    return this.http
      .get(url)
      .pipe(map((data: Response) => this.extractData(data)));
  }

  /**
   * Rest request.
   *
   * @param {string} url
   * @param {String} [type='GET']
   * @param {*} [params={
   *         meta : 'post'
   *       }]
   * @param {string} [service='backend']
   * @param {String} [tokenOauth='']
   * @returns {Observable<any>}
   * @memberof DataService
   */
  public restRequest(
    url: string,
    type: string = "GET",
    params: any = {
      meta: "post",
    },
    service = "backend",
    tokenOauth: string = "",
    clientId?: string
  ): Observable<any> {
    let apiUrl: string = this.getApiURL(service);
    let headers = this.headersConstructor(clientId, tokenOauth, url);
    let options = {
      headers
    };
    if (type === "GET") {
      return this.http
        .get(apiUrl.concat(url), { headers: headers })
        .pipe(
          map((data: Response) => this.extractData(data)),
          catchError((error: any) =>
            throwError(this.handleError(url, error, service))
          )
        );
    } else {
      return this.http.post(apiUrl.concat(url), params, options).pipe(
        map((data: Response) => this.extractData(data)),
        timeout(90000),
        catchError((error: any) =>
          throwError(this.handleError(url, error, service))
        )
      );
    }
  }

  /**
   * Create headers for request
   *
   * @private
   * @param {string} clientId
   * @param {string} tokentemp
   * @returns {HttpHeaders}
   * @memberof DataService
   */
  private headersConstructor(
    clientId: string,
    tokentemp: string,
    url?: string
  ): HttpHeaders {
    let headers: HttpHeaders;
    headers = new HttpHeaders({
      "Content-Type": "application/json",
      ...(url === "/token_validator" && { token: tokentemp }),
    });
    return headers;
  }

  /**
   * Request endpoint to reload session
   *
   * @param {string} service
   * @returns {*}
   * @memberof DataService
   */
  public reloadRequest(service: string): any {
    const env =
      this.dataProxy.getEnviroment() === undefined
        ? this.storage.getFromLocal("enviroment")
        : this.dataProxy.getEnviroment();
    const reloadSessionService = _.find(
      this.constantsService.ENVIROMENT,
      (item) => {
        return item.env == env;
      }
    );
    if (reloadSessionService !== undefined) {
      // Header to prevent OPTIONS Request
      let headers = new HttpHeaders({ "Content-Type": "application/json" });
      let options = {
        headers,
        withCredentials: true,
      };
      const sNetSessionParam = JSON.stringify(this.dataProxy.sessionparam);
      this.http
        .post(
          `${reloadSessionService.snetSession}?json=${sNetSessionParam}`,
          { meta: "post" },
          { headers: headers, withCredentials: true }
        )
        .subscribe();
    }
  }
  /**
   * Get movements from the endpoint.
   *
   * @param {string} ID
   * @returns {*}
   * @memberof DataService
   */
  public getMovements(ID: string): any {
    const params = {
      extract: ID,
    };
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    let options = {
      headers,
      withCredentials: true,
    };
    let promise = new Promise((resolve, reject) => {
      let apiUrl = `${this.USER_REST_URL}/moves/`;
      this.http
        .post(apiUrl, params, { headers: headers, withCredentials: true })
        .toPromise()
        .then(
          (response) => {
            // Success
            resolve(response);
          },
          (error) => {
            // Error
            reject(error);
          }
        );
    });
    return promise;
  }

  /**
   * Dummy request.
   *
   * @param {string} url
   * @returns
   * @memberof DataService
   */
  public dummyRequest(url: string) {
    return this.http.get(url).pipe(
      map((data: Response) => this.extractData(data)),
      catchError((error: any) => throwError(this.handleError(url, error)))
    );
  }

  /**
   * Handle error.
   *
   * @param {(Response | any)} error
   * @param {string} [type='']
   * @returns
   * @memberof DataService
   */
  public handleError(
    url: string = "",
    error: Response | any,
    type: string = ""
  ) {
    let options: any = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true,
    };
    //TOFIX: check why servicesError is closed if there is one open
    if (type !== "catalogs") {
      for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
        this.modalService.hide(i);
      }
      this.modalRef = this.modalService.show(AlertComponent, options);
      if (
        (error.name === "TimeoutError" ||
          error.status == 0 ||
          error.status == 504) &&
        url === "/clarifications/"
      ) {
        this.modalRef.content.type = "timeoutError";
      } else if (error.name === "Error") {
        this.modalRef.content.type = "cancelExecuteBlock";
      } else {
        this.modalRef.content.type = "servicesError";
        if (
          this.router.url === "/summary-atm" ||
          this.router.url === "/summary"
        ) {
          this.modalRef.content.action = () => {
            this.router.navigate(["welcome"]);
            this.modalRef.hide();
          };
        }
      }
    }
    let errMsg = error.message ? error.message : error.toString();
    Promise.reject(errMsg);
    return errMsg;
  }

  /**
   * Get the API URL according to the service.
   *
   * @public
   * @param {string} service
   * @returns {string}
   * @memberof DataService
   */
  public getApiURL(service: string): string {
    let apiUrl = "";
    switch (service) {
      case "":
        apiUrl = this.REST_URL;
        break;
      case "config":
      case "token":
        apiUrl = "./";
        break;
      case "user":
        apiUrl = this.USER_REST_URL;
        break;
      case "catalogs":
        apiUrl = this.CATALOGS_URL;
        break;
      case "cards":
        apiUrl = this.WALLET_REST_URL;
        break;
      case "folios":
        apiUrl = this.FOLIOS_REST_URL;
        break;
      case "sso_token":
        apiUrl = this.SSO_TOKEN_URL;
        break;
      case "rating":
        apiUrl = this.RATING_URL;
        break;
      case "debit":
        apiUrl = this.DEBIT_URL;
        break;
      default:
        apiUrl = this.MDW_URL;
    }
    return apiUrl;
  }

  /**
   * Extract data.
   *
   * @private
   * @param {Response} res
   * @returns {*}
   * @memberof DataService
   */
  private extractData(res: Response): any {
    return res;
  }
}
