import { Injectable } from '@angular/core';
import * as _ from 'lodash';

/**
 * Labels used in components as a constants.
 *
 * @export
 * @class LabelsService
 */
@Injectable()
export class LabelsService {
    /**
     * the succes message 
     *
     * @type {string}
     * @memberof LabelsService
     */
    public success: string;
    /**
     * the congrats message 
     *
     * @type {string}
     * @memberof LabelsService
     */
    public congrats: string;
    /**
     * array to set descripton message 
     *
     * @type {string[]}
     * @memberof LabelsService
     */
    public description: string[];
    /**
     * the documents messages 
     *
     * @type {string[]}
     * @memberof LabelsService
     */
    public restrictions: string[];
    /**
     * the warning mesage
     *
     * @type {string}
     * @memberof LabelsService
     */
    public warning: string;
    /**
     * the card number 
     *
     * @type {string}
     * @memberof LabelsService
     */
    public cardNumber: string;
    /**
     * the old card message 
     *
     * @type {string}
     * @memberof LabelsService
     */
    public oldCardTitle: string;
    /**
     * the new card message 
     *
     * @type {string}
     * @memberof LabelsService
     */
    public newCardTitle: string;
    /**
     * the amount paid message 
     *
     * @type {string}
     * @memberof LabelsService
     */
    public amountPaid: string;
    /**
     * the amount of the acaration message 
     *
     * @type {string}
     * @memberof LabelsService
     */
    public amountAclaration: string;

    /**
     * Creates an instance of LabelsService.
     * @memberof LabelsService
     */
    constructor() {
        this.success = 'Su solicitud fue recibida';
        this.congrats = '¡Gracias por la espera,';
        this.description = [
            'Hemos dado de alta su aclaración',
            'Hemos realizado un abono temporal a su tarjeta',
            'Por seguridad hemos bloqueado la tarjeta',
        ];
        this.restrictions = [
            'Carta firmada que contenga el detalle de lo sucedido.',
            'Comprobante de compra con el importe correcto de la transacción realizada.',
            'Comprobante de compra con el importe del (los) cargo(s) que sí reconoce.',
            'Comprobante que demuestre el pago al comercio.',
            'Comprobante de devolución emitido por el comercio.',
            'Copia de su identificación oficial (por ambos lados).',
            'Copia de su CURP.',
            'Formato BCOM-488 que enviaremos al correo que tenemos registrado.',
            'Copia del comprobante con la fecha de entrega comprometida por el comercio.',
            'Comprobante o folio de cancelación.',
        ];
        this.warning = 'Esta información deberá enviarla en máximo 5 días naturales, colocando en el asunto del correo el número de folio que le proporcionamos anteriormente.';
        this.cardNumber = 'Número de tarjeta'
        this.oldCardTitle = 'Número de tarjeta bloqueada';
        this.newCardTitle = 'Nuevo número de tarjeta';
        this.amountPaid = 'Monto abonado';
        this.amountAclaration = 'Monto de la aclaración';
    }

    /**
     * Get the success string.
     *
     * @returns {string}
     * @memberof LabelsService
     */
    public getSuccess(): string {
        return this.success;
    }

    /**
     * Get the congrats concatenated with the name.
     *
     * @param {string} name
     * @returns {string}
     * @memberof LabelsService
     */
    public getCongrats(name: string): string {
        return `${this.congrats} ${name}!`;
    }

    /**
     * Get the description if exists.
     *
     * @param {number} index
     * @returns {string}
     * @memberof LabelsService
     */
    public getDescription(index: number): string {
        return (!_.isUndefined(this.description[index])) ? this.description[index] : '' ;
    }

    /**
     * Get the restriction if exists.
     *
     * @param {number} index
     * @returns {string}
     * @memberof LabelsService
     */
    public getRestrictions(index: number): string {
        return (!_.isUndefined(this.restrictions[index])) ? this.restrictions[index] : '' ;
    }

    /**
     * Get the warning string.
     *
     * @returns {string}
     * @memberof LabelsService
     */
    public getWarning(): string {
        return this.warning;
    }

    /**
     * Get card number string.
     *
     * @returns {string}
     * @memberof LabelsService
     */
    public getCardNumber(): string{
        return this.cardNumber;
    }



    /**
     * Get old card title string.
     *
     * @returns {string}
     * @memberof LabelsService
     */
    public getOldCardTitle(): string {
        return this.oldCardTitle;
    }

    /**
     * Get the new card title string.
     *
     * @returns {string}
     * @memberof LabelsService
     */
    public getNewCardTitle(): string {
        return this.newCardTitle;
    }

    /**
     * Get the amount paid string.
     *
     * @returns {string}
     * @memberof LabelsService
     */
    public getAmountPaid(): string {
        return this.amountPaid;
    }

    /**
     * Get the amount of the aclaration string.
     *
     * @returns {string}
     * @memberof LabelsService
     */
    public getAmountAclaration(): string {
        return this.amountAclaration;
    }
}
