
import { Injectable } from '@angular/core';
import {
    MoveModel, CreditCard, AnswersQuestionsModel, QuestionsModel,
    MultifolioModel
} from './../models';
import { DataProxyService } from './data-proxy.service';

import * as _ from 'lodash';

@Injectable()
export class DataServiceManagerService {
    private questions: QuestionsModel;
    constructor(public dataProxy: DataProxyService) {
        this.questions = dataProxy.getQuestions();
    }


    /**
     * Generate service manager object.
     *
     * @returns {Object}
     * @memberof DataServiceManagerService
     */
    getServiceManager(): Object {
        let obj: any = {};
        let fullccdata: CreditCard = this.dataProxy.getCreditCardFullData();
        let wvrinboxrn: Object = {
            "Categoria": "TARJETA DE CREDITO TARJETAHABIENTES",
            "Descripcion": [this.questions.additionalComments !== '' ? this.questions.additionalComments : this.getSubcategory()],
            "EntidadFed": this.dataProxy.getStateID(this.questions.state).toString(),
            "Subcategoria": this.getSubcategory(),
            "VisaCarta": this.applyVISARule(fullccdata),
            "cuestionario": this.getQuestionsAndAnswers(),
            "multifolio": this.getMultifolioModel(),
            "FechaRobada": this.questions.missingYY !== '' && this.questions.missingYY !== null ? `${this.questions.missingYY}-${this.questions.missingMM}-${this.questions.missingDD}` : ""
            /* "Descripcion":       [
            "NO BORRAR",
            "EJEMPLO PARA UNA TDC",
            "TARJETA DE CREDITO NO RECIBIDA",
            "cuando no existen los datos personales:",
            "EL REGISTRO NO EXISTE EN LA B.D. MPDT009"
          ], */
        };
        obj.wvrinboxrn = wvrinboxrn;
        return obj;
    }

    /**
   * Get subcategory
   *
   * @private
   * @returns {string}
   * @memberof LockedComponent
   */
    private getSubcategory(): string {
        let res = 'COMPRA NO RECONOCIDA';
        if (Number(this.questions.hasCard) === 1) {
            if (this.questions.motive.key === 'IC-205') {
                res = 'DEVOLUCION NO APLICADA';
            }
        } else {
            if (this.questions.whatHappens.getTitle() === 'La reporté como robada o extraviada.' ||
                this.questions.whatHappens.getTitle() === 'Me la robaron o la extravié y no la he reportado.') {
                res = 'TARJETA ROBADA O EXTRAVIADA';
            } else {
                res = 'TARJETA NO RECIBIDA';
            }
        }
        return res;
    }
    /**
   * Apply VISA Rule
   *
   * @private
   * @param {CreditCard} data
   * @returns {string}
   * @memberof LockedComponent
   */
    private applyVISARule(data: CreditCard): string {
        let r = 'false';
        if (data.cardBrand.toLowerCase() === 'visa') {
            _.each(this.dataProxy.getDataSelected(), (item: MoveModel) => {
                if (item.txrTipoFactura === '1003' && Number(item.txrMonto) >= 400) {
                    r = 'true';
                }
            });
        }
        return r;
    }
    /**
     * Get questions and answers.
     *
     * @private
     * @returns {*}
     * @memberof LockedComponent
     */
    private getQuestionsAndAnswers(): any {
        let question = [];
        if (Number(this.questions.hasCard) === 1) {
            if (this.questions.motive.key !== 'IC-205') {
                question = question.concat(this.getQuestionsHaveCard());
            }
        } else {
            let objquestion = new AnswersQuestionsModel();
            objquestion.Preguntas = '¿Reporto usted su tarjeta de crédito o débito como robada o extraviada a Banco Santander?';
            if (this.questions.whatHappens.getTitle() === 'La reporté como robada o extraviada.') {
                objquestion.Respuestas = 'SI';
                question.push(objquestion);
            } else if (this.questions.whatHappens.getTitle() === 'Me la robaron o la extravié y no la he reportado.') {
                objquestion.Respuestas = 'NO';
                question.push(objquestion);
            }
        }

        return question;
    }
    /**
     * Get the Multifolio
     *
     * @private
     * @returns
     * @memberof LockedComponent
     */
    private getMultifolioModel() {
        let multifolio = [];
        let ix = 0;
        _.each(this.dataProxy.getDataSelected(), (item: MoveModel) => {
            let objmultifolio = new MultifolioModel();
            objmultifolio.AcctTrnId = item.id;
            objmultifolio.AcctStmtId = item.txrNumExtracto;
            multifolio.push(objmultifolio);
            ix++;
        });
        return multifolio;
    }
    /**
    * Get questions and answers when the client have card.
    *
    * @returns {*}
    * @memberof LockedComponent
    */
    getQuestionsHaveCard(): any {
        let question = [
            { Preguntas: '¿Tiene la tarjeta en su poder?', Respuestas: 'SI' }
        ];
        let objquestion = new AnswersQuestionsModel();
        objquestion.Preguntas = '¿Interactuó con el comercio durante la compra?';
        if (this.questions.haveContact === '1') {
            objquestion.Respuestas = 'SI';
        } else {
            objquestion.Respuestas = 'NO';
        }
        question.push(objquestion);
        question = question.concat(this.getAdditionalQuestionary());
        return question;
    }

    /**
     * Get additional questions when.
     *
     * @returns {*}
     * @memberof LockedComponent
     */
    getAdditionalQuestionary(): any {
        let questionary = [
            { Preguntas: 'Cargo duplicado', Respuestas: 'NO' },
            { Preguntas: 'Monto alterado', Respuestas: 'NO' },
            { Preguntas: 'Cargos adicionales', Respuestas: 'NO' },
            { Preguntas: 'Servicios no proporcionados', Respuestas: 'NO' },
            { Preguntas: 'Mercancia defectuosa', Respuestas: 'NO' },
            { Preguntas: 'Pago por otro medio', Respuestas: 'NO' },
            { Preguntas: 'Cancelación de servicio', Respuestas: 'NO' },
            { Preguntas: 'Otro', Respuestas: 'NO' }
        ];
        switch (this.questions.motive.key) {
            case 'IC-201': {
                questionary[0].Respuestas = 'SI';
                break;
            }
            case 'IC-202': {
                questionary[1].Respuestas = 'SI';
                break;
            }
            case 'IC-203': {
                questionary[2].Respuestas = 'SI';
                break;
            }
            case 'IC-204': {
                questionary[3].Respuestas = 'SI';
                break;
            }
            case 'IC-206': {
                questionary[5].Respuestas = 'SI';
                break;
            }
            case 'IC-207': {
                questionary[6].Respuestas = 'SI';
                break;
            }
            default: {
                break;
            }
        }
        return questionary;
    }
}

