import { Component, OnInit,ViewChild,forwardRef, Input} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import moment from 'moment';
import {TooltipTddComponent} from '../';
import {UtilsTddService} from '../../../services/tdd/utils-tdd.service';
import {SessionStorageService} from '../../../services/tdd/session-storage.service';
import * as _ from 'lodash';
import { AlertsTddService } from '../../../services/tdd/alerts-tdd.service';
import { NavigationService } from '../../../services/navigation.service/index';
import { DataProxyService } from '../../../services/data-proxy.service';
//TODO: Remove when testing is finished
import { Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoaderComponent, AlertComponent } from '../../../partials/';
//Taggeo
import { TaggingService } from '../../../services/tagging.service';

/**
 *
 *
 * @export
 * @class QuestionnaireTddComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-questionnaire-tdd',
  template: require('./questionnaire-tdd.component.html'),
  providers: [
    NavigationService
  ]
})
export class QuestionnaireTddComponent implements OnInit {
  /** tooltip agregado como child */
  @ViewChild(forwardRef(() =>TooltipTddComponent), {static:true}) tooltip: TooltipTddComponent;
  /**tiene la tarjeta en su poder */
  private hasCard;
  /**interactuo con el comercio */
  private commerceInteraction;
  /**motivo seleccionado  */
  private motiveSelected;
  /**id del motivo seleccionado */
  private motiveSelectedId;
  /**descripcion de lo sucedidp */
  private motiveClientDescription;
  /**validacion de la longitud de la descripcion */
  private validMotiveClientDescription;
  /**fecha en l que perdio la tarjeta */
  private lostDate;
  /**dia en que perdio la tarjeta */
  private lostDateDay;
  /**mes en que perdio la tarjeta */
  private lostDateMonth;
  /**año en que reporto la tarjeta */
  private lostDateYear;
  /**ubicacion del cliente */
  private location;
  /**estado donde se encuentra el cliente */
  private state;
  //**días del mes */
  private days;
  /**meses del año */
  private months;
  /**años a seleccionar */
  private years;
  /**catalogo de estados */
  private states = [{ clave:0 , nombre:''}];

  /** SECTION NAME TB BUTTON */
  private section: string = 'questionnaire';

  /**motivos a mostrar en la vista */
  //8-La reporte como robada o extraviada
  //9-Nunca la tuve conmigo
  //10 = Me la robaron o la extravié y no la he reportado
  private motives=[
    {id:'1',description:'Cargo duplicado',label:'Describa lo que <b>sucedió</b>:',hover:'Ejem.: No reconozco el cargo porque me aparece en más de una ocasión.',tooltip:'Se presenta cuando en los movimientos aparecen 2 importes por la misma compra.'},
    {id:'2',description:'Monto alterado',label:'¿Cuál es el importe que <strong>autorizó y reconoce</strong>?',hover:'Ejem.: Es de $120,000.00 el monto que si reconozco.',tooltip:'Se presenta cuando se realizó  una compra y el movimiento tiene una cantidad diferente a la que se reconoce.'},
    {id:'3',description:'Cargos adicionales al autorizado',label:'Indique los datos del (los) <strong>consumo(s) que sí reconoce</strong>:',hover:'Ejem.: ID 2429384 - Compra en Liverpool',tooltip:'Se presenta cuando aparecen distintos o mayor número de importes.'},
    {id:'4',description:'Pago por otro medio',label:'Describa lo que <strong>sucedió</strong>:',hover:'Ejem.: No hice el pago a través de este medio.',tooltip:'Se presenta cuando el pago con la tarjeta falla, y aun así se realiza el cargo, aunque se haya pagado con otro medio (efectivo u otra tarjeta).'},
    {id:'5',description:'Devolución no aplicada',label:'<strong>Comentarios</strong> adicionales:',hover:'Ejem.: No me han hecho mi devolución.',tooltip:'Se presenta cuando se realizó una compra, se devolvió el objeto y en el estado de cuenta el cargo fue aplicado.'},
    {id:'6',description:'Mercancías o servicios no proporcionados',label:'<strong>Comentarios</strong> adicionales:',hover:'Ejem.: Nunca me llegó mi mercancía',tooltip:'Se presenta cuando se hizo una compra, nunca llegó el objeto y aun así el cargo fue aplicado.'},
    {id:'7',description:'Cancelación de servicio',label:'<strong>Comentarios</strong> adicionales:',hover:'Ejem.: No estoy de acuerdo con el cobro del servicio, por lo cual lo cancelo.',tooltip:'Se presenta cuando se estableció un pago de servicio, se canceló el servicio y aun así se sigue aplicando el cargo.'},
  ]
  /**
   * Show user data 
  */
  public userdata = this.storage.getFromLocal('userdata');
  /**
   * Get number of movements selected
   */
  public counter = this.dataProxy.getSelectedCount();
  /**
   * Get movements selected to show
   */
  public selectedItems = this.dataProxy.getDataSource();
  //TODO: Remove dependencies when testing is finished
  private modalSubscription: Subscription[] = [];
  private modalRef: BsModalRef;

  /**
   *Creates an instance of QuestionnaireTddComponent.
   * @param {UtilsTddService} utils
   * @param {SessionStorageService} storage
   * @param {Router} router
   * @param {NavigationService} navigationService
   * @param {TaggingService} taggingService
   * @param {DataProxyService} dataProxy
   * @memberof QuestionnaireTddComponent
   */
  constructor( 
    private utils:UtilsTddService,
    private storage: SessionStorageService,
    private router: Router,
    private navigationService: NavigationService,
    private taggingService: TaggingService,
    private dataProxy: DataProxyService,
    private alertsTddService : AlertsTddService,
    private modalService: BsModalService
  ) {
    this.hasCard='';
    this.resetQuestionary(1);
    this.generateDates();
    this.states = this.storage.getFromLocal('states') ;
    this.states.splice(-1,1);
   }

  /**
   *
   *
   * @memberof QuestionnaireTddComponent
   */
  ngOnInit() {
    //TB BUTTON SM O WALL
    this.navigationService.tapBack(this.section);
    //this.alertsMain.sendMessage(-1,false);
    //TALIUM TAG
    this.setDataLayer();
  }

  /**
   * Sets the dataLayer for Google Analytics
   *
   * @memberof QuestionnaireTddComponent
   * @returns {void}
   */
  public setDataLayer(): void {
    this.taggingService.pageSettings({
      subsection: 'questionnaire-tdd'
    });
    this.taggingService.sendTeliumDataView();
  }
  
  /**
  * Method to go botoom page
  *
   *
   *
   * @private
   * @memberof QuestionnaireTddComponent
   */
  private scrollBottom(){
    setTimeout(()=>{
      window.scrollTo(0,document.body.scrollHeight);
    },150)
    
  }

  /**
  * Method that generates the values of the date
   *
   * @memberof QuestionnaireTddComponent
   */
  generateDates(){
    this.days =['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];
    this.months=['ENE','FEB','MAR','ABR','MAY','JUN','JUL','AGO','SEP','OCT','NOV','DIC'];
    let date = moment(); 
    this.years=[date.format('YYYY')];
    for(let i=0;i<10;i++){
      this.years.push(date.subtract(1,'years').format('YYYY'));
    }  
  }


  /**
  * Input validator for the comments
  *
   *
   * @param {*} event
   * @memberof QuestionnaireTddComponent
   */
  inputValidator(event: any) {
    const pattern = /^[a-zA-Z0-9 _\\-\\.:,;áéíóúÁÉÍÓÚÜü¿?"¡!#$%&()=]*$/;   
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9 _\\-\\.:,;áéíóúÁÉÍÓÚÜü¿?"¡!#$%&()=]/g, "");
    }
  }
  /**
   *Validate the description lenght
   *
   * @memberof QuestionnaireTddComponent
   */
  validateMotiveDescription(){
      this.motiveClientDescription.length >=1 ? this.validMotiveClientDescription = true: this.validMotiveClientDescription = false;
  }

  /**
  * set the correct value to motiveSelected when changes the motive
   *
   * @param {*} id
   * @memberof QuestionnaireTddComponent
   */
  changeMotive(id){
    if(Number(id) === 8 || Number(id) === 9 ||Number(id) === 10 ){
      this.motiveSelected = {id:id };
    }else{
      this.motiveSelected = this.motives[Number(id)-1];
    }
  }

  /**
  * reset the value of the questionary depending the level of reset
  *
   *
   * @param {*} level
   * @memberof QuestionnaireTddComponent
   */
  resetQuestionary(level){
    switch(level){
      case 1:
        this.commerceInteraction='';
        this.motiveSelectedId='0';
        this.motiveSelected={id:'0'}
        this.motiveClientDescription='';
        this.validMotiveClientDescription=false;
        this.lostDate='';
        this.lostDateDay='';
        this.lostDateMonth='';
        this.lostDateYear='';
        this.state='';
        this.location='';
        break;
      case 2:
        this.motiveSelectedId='0';
        this.motiveSelected={id:'0'}
        this.motiveClientDescription='';
        this.validMotiveClientDescription=false;
        this.lostDate='';
        this.lostDateDay='';
        this.lostDateMonth='';
        this.lostDateYear='';
        this.state='';
        this.location='';
        break;
      case 3:
        this.motiveClientDescription='';
        this.validMotiveClientDescription=false;
        this.lostDate=''; 
        this.lostDateDay='';
        this.lostDateMonth='';
        this.lostDateYear='';
        this.state='';
        this.location='';
        break;
      case 4:
        this.location='';
        this.state='';
        break;
      case 5:
        this.state='';
        break;
      case 6:
        this.validMotiveClientDescription=false;
        break;
      default:
        break;
    }
  }

  /**
   * Check if the lost date is valid
   *
   *
   *
   * @memberof QuestionnaireTddComponent
   */
  public validateDate() {
    if(this.lostDateDay!=='' && this.lostDateMonth!=='' && this.lostDateYear!==''){
      if(moment(`${this.lostDateDay}/${this.lostDateMonth}/${this.lostDateYear}`,'DD/MMM/YYYY','es').isValid()){
        this.lostDate = moment(`${this.lostDateDay}/${this.lostDateMonth}/${this.lostDateYear}`,'DD/MMM/YYYY','es').format('DD/MMM/YYYY');
      }
    }
  }

  /**
   * generates the multifolio object 
   *
   *
   * @memberof QuestionnaireTddComponent
   */
  validateForm(){

    let questionnaire = [];
    let viewQuestions = [];
    let viewLocation = '';

    if(this.hasCard==='SI'){
      if(Number(this.motiveSelectedId) !== 5){
        questionnaire = questionnaire.concat(this.generateNonrecognized());
      }
    }else if(this.hasCard==='NO'){
      let question ={ Preguntas:'¿Reporto usted su tarjeta de crédito o débito como robada o extraviada a Banco Santander?',
                      Respuestas:''};
      if(Number(this.motiveSelectedId) !== 8 ){
        question.Respuestas = 'NO';
        questionnaire.push(question);
      }else if(Number(this.motiveSelectedId) !== 10){
        question.Respuestas = 'SI';
        questionnaire.push(question);
      }

    }


    if(this.state !== ''){
      viewLocation = _.find(this.states,(item)=>{
        if(Number(item.clave) === this.state){
          return item.nombre.toUpperCase();
        }
      }).nombre;
    }else{
      viewLocation = 'Fuera de la república'
    }
    this.motiveClientDescription = this.motiveClientDescription.length > 0 ? this.motiveClientDescription : this.getSubcategory();
    viewQuestions = this.utils.generateViewQuestions(
      this.motiveSelected,this.motiveClientDescription,
      this.lostDate,viewLocation,this.commerceInteraction,
      this.motiveSelectedId
    );
    

    
    this.storage.saveInLocal('viewQuestions',viewQuestions);
    this.storage.saveInLocal('questionnaire',questionnaire);
    let actualLocation;
    this.location==='Foreign'?actualLocation = 33:actualLocation = this.state;
    let adittionaldata={
      description:[this.motiveClientDescription],
      lostdate:this.lostDate,
      location: actualLocation
    }
    this.saveSubcategory();
    this.storage.saveInLocal('additionaldata',adittionaldata);
    this.storage.saveInLocal('questionId',this.motiveSelected);
    this.taggingService.sendTeliumDataLink(
      {
        'interaction_action':'cuestionario',
        'interaction_label':'continuar'
      },
      viewQuestions
    );
    this.router.navigate(['summary-tdd']);
  }
  
  /**
   * Get Subcategory.
   *
   * @private
   * @returns {string}
   * @memberof SummaryComponent
   */
  private getSubcategory(): string {
    let res = 'Compra no reconocida';
    if(this.hasCard === 'SI') {
      res = this.motiveSelectedId === 5 ? 'Devolución no aplicada' : 'Compra no reconocida';
    } else {
      switch (Number(this.motiveSelectedId)) {
        case 8:
          res = 'La reporté como robada o extraviada';
          break;
        case 9:
          res = 'Nunca la tuve conmigo';
          break;
        case 10:
          res = 'Me la robaron o la extravié y no la he reportado';
          break;
        default:
          break;
      }
    }
    return res;
  }

  /**
   *validates the subcategory and saves in the storage
   *
   * @memberof QuestionnaireTddComponent
   */
  saveSubcategory(){
    switch(Number(this.motiveSelectedId)){
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 6:
      case 7:
        this.storage.saveInLocal('subcategory','COMPRA NO RECONOCIDA');
        break;
      case 5:
        this.storage.saveInLocal('subcategory','DEVOLUCION NO APLICADA');
        break;
      case 8:
      case 10:
        this.storage.saveInLocal('subcategory','TARJETA ROBADA O EXTRAVIADA');
        break;
      case 9:
      this.storage.saveInLocal('subcategory','TARJETA NO RECIBIDA');
        break;
      default:
        this.storage.saveInLocal('subcategory','COMPRA NO RECONOCIDA');
        break;
    }
  }


  /**
   *generates the questions when he fow is not recognized
   *
   * @returns
   * @memberof QuestionnaireTddComponent
   */
  generateNonrecognized(){
    let questions = [
      {Preguntas:'¿Tiene la tarjeta en su poder?',Respuestas:'SI'}
    ];
    let tempQuestion = { Preguntas:'¿Interactuó con el comercio durante la compra?', Respuestas:''};

    if(this.commerceInteraction==='SI'){
      tempQuestion.Respuestas = 'SI';
    }else{
      tempQuestion.Respuestas = 'NO';
    }


    questions.push(tempQuestion);
    questions = questions.concat(this.getAdditionalQuestionary());
    return questions;
  }


  /**
   *Additional questions in non recognized
   *
   * @returns
   * @memberof QuestionnaireTddComponent
   */
  getAdditionalQuestionary(){
    let questionary = [
      {Preguntas:'Cargo duplicado',Respuestas:'NO'},
      {Preguntas:'Monto alterado',Respuestas:'NO'},
      {Preguntas:'Cargos adicionales',Respuestas:'NO'},
      {Preguntas:'Servicios no proporcionados',Respuestas:'NO'},
      {Preguntas:'Mercancia defectuosa',Respuestas:'NO'},
      {Preguntas:'Pago por otro medio',Respuestas:'NO'},
      {Preguntas:'Cancelación de servicio',Respuestas:'NO'},
      {Preguntas:'Otro',Respuestas:'NO'}
    ];

    switch(Number(this.motiveSelectedId)) { 
      case 1: { 
        questionary[0].Respuestas='SI'; 
        break; 
      } 
      case 2: { 
        questionary[1].Respuestas='SI'; 
        break; 
      } 
      case 3: { 
        questionary[2].Respuestas='SI';  
        break; 
      } 
      case 4: { 
        questionary[3].Respuestas='SI'; 
        break; 
      }  
      case 6: { 
        questionary[5].Respuestas='SI'; 
        break; 
      } 
      case 7: { 
        questionary[6].Respuestas='SI'; 
        break; 
      }
      default: { 
        break; 
      }
    }

    return questionary;
  }


  /**
   *method tho execute the child component
   *
   * @param {*} evt
   * @memberof QuestionnaireTddComponent
   */
  public showTooltip(evt:any){
    this.tooltip.showTooltip(evt,this.motiveSelected.tooltip);
  }

  /**
   * Opener Light Box.
   *
   * @param {*} event
   * @param {*} [idT]
   * @memberof QuestionnaireComponent
   */
  public tooltipOpener(event,idT?){
    let y = event.clientY;
    let x = event.clientX;
    let tooltip = document.getElementById('tooltip-box');
    let backdrop = document.getElementById('backdrop');
    let tooltipText  = document.getElementById('tooltip-text');
    let flagBorder = document.getElementById('flag-border');
    let flagColor = document.getElementById('flag-color');

    tooltipText.innerHTML = this.tooltipShow(idT);
    tooltip.style.top = (y-104)+'px';
    tooltip.style.position = 'fixed';
    tooltip.style.left = (x-156)+'px';
    flagColor.style.left = 50+'%';
    flagBorder.style.left = 50+'%';
    backdrop.classList.remove("tooltip-hide");
    tooltip.classList.remove("tooltip-hide");


    if(idT === 8){
      tooltip.style.left = (x-274)+'px';
      flagColor.style.left = 90+'%';
      flagBorder.style.left = 90+'%'  ;
    }
  }
  /**
   * Show tooltip.
   *
   * @param {*} idMotive
   * @memberof QuestionnaireComponent
   */
  public tooltipShow(idMotive):string{
    // Remove Inactive
    let element = document.getElementById('tooltipMotive');
    try{
      element.classList.remove("inactive");
    }catch(e){

    }
   let tooltipText:string = '';
    //Inner HTML to Div
    switch (parseInt(idMotive)) {
      case 1:
        tooltipText = 'Se presenta cuando en los movimientos aparecen 2 importes por la misma compra.';
      break;

      case 2:
        tooltipText = 'Se presenta cuando se realizó  una compra y el movimiento tiene una cantidad diferente a la que se reconoce.';
      break;
      
      case 3:
        tooltipText = 'Se presenta cuando aparecen distintos o mayor número de importes.';
      break;
      
      case 4:
        tooltipText = 'Se presenta cuando el pago con la tarjeta falla, y aun así se realiza el cargo, aunque se haya pagado con otro medio (efectivo u otra tarjeta).';
      break;
      
      case 5:
        tooltipText = 'Se presenta cuando se realizó una compra, se devolvió el objeto y en el estado de cuenta el cargo fue aplicado.';
      break;
      
      case 6:
        tooltipText = 'Se presenta cuando se hizo una compra, nunca llegó el objeto y aun así el cargo fue aplicado.';
      break;
      
      case 7:
        tooltipText = 'Se presenta cuando se estableció un pago de servicio, se canceló el servicio y aun así se sigue aplicando el cargo.';
      break;

      case 8:
        tooltipText='A continuación se muestran los movimientos que eligió para mandar a aclarar.';
      break;
      default:
    }
    return tooltipText;
  }
  
  /**
   * muestra el alert cuando se seleccione no en el cuestionario
   * 
   *
   * @memberof QuestionnaireTddComponent
   */
  /**
   * Open a modal instance.
   *
   * @public
   * @param {string} type
   * @memberof SummaryComponent
   */
  public openModal(type: string): void {
    this.hasCard='';
    this.navigationService.tapBack('');
    this.modalSubscription.push(this.modalService.onShown.subscribe((reason: string) => {
    }));
    let options: any = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true
    };
    if(type==='loader'){
      options.class = 'modal-loader';
      this.modalRef = this.modalService.show( LoaderComponent, options);
    }else{
      this.modalRef = this.modalService.show(AlertComponent,options);
      this.modalRef.content.type = type;
    }
    if (type === 'block-one' || type === 'block-two') {
      // Cancel navigation
      this.navigationService.tapBack('');
    }
  }

}
