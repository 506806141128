import { Component, OnInit } from '@angular/core';
import { DataProxyService } from './../../services/data-proxy.service';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MoveModel } from './../../models';
// Services
import { NavigationService } from './../../services/navigation/navigation.service';
import {SessionStorageService} from './../../services/tdd/session-storage.service';
import { TaggingService } from './../../services/tagging.service';
/**
 * Modal windows.
 *
 * @export
 * @class AlertComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-blocker',
  template: require('./alert.component.html'),
  providers: [
    NavigationService
  ]
})
export class AlertComponent implements OnInit {

  /**
   * the type alet
   *
   * @memberof AlertComponent
   */
  public type = '';
  public productType : string = '';
  modalRef ?: BsModalRef;

  public action: Function;
  /**
   * Creates an instance of AlertComponent.
   * @param {DataProxyService} dataProxy
   * @param {NavigationService} navigationService
   * @param {Router} router
   * @param {BsModalService} modalService
   * @param {SessionStorageService} storage
   * @param {TaggingService} taggingService
   * @memberof AlertComponent
   */
  constructor(
    private dataProxy: DataProxyService,
    private navigationService: NavigationService,
    private router: Router,
    private modalService: BsModalService,
    private storage: SessionStorageService,
    private taggingService: TaggingService
  ) {}

  /**
   * Angular Lifecycle hook: When the component it is initialized.
   *
   * @memberof AlertComponent
   */
  public ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    const getProductType : string = this.storage.getFromLocal('category');
    this.productType = getProductType !== null && getProductType === 'TDD PRINCIPAL' ? 'débito' : 'crédito';
  }

  /**
   * Close the modal window.
   *
   * @memberof AlertComponent
   */
  public cancelExecuteBlock(): void {
    this.accept();
    this.navigationService.goToRoot();
  }

  /**
   * When the user clicks on accept button.
   *
   * @memberof AlertComponent
   */
  public accept(): void {
    this.taggingService.sendTeliumDataLink(
      {
        'interaction_action':'resume_model',
        'interaction_label':'finalizar'
      },
      this.storage.getFromLocal('viewQuestions')
    );
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    };

    this.dataProxy.dataSource =Array<MoveModel>();

    document.getElementById('body').style.removeProperty('overflow');
    document.getElementById('body').classList.remove("modal-open");

    if (this.action) {
      this.action();
    }
  }

  /**
   * Redirect to the questions screen.
   *
   * @memberof AlertComponent
   */
  public goToQuestions(){
    this.taggingService.sendTeliumDataLink(
      {
        'interaction_action':'resume_model',
        'interaction_label':'ir_a_cuestionario'
      },
      this.storage.getFromLocal('viewQuestions')
    );
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    };
    document.getElementById('body').style.removeProperty('overflow');
    document.getElementById('body').classList.remove("modal-open");
    this.productType === 'débito' ? this.router.navigate(['questionnaire-tdd']) : this.router.navigate(['questionnaire']);
  }

  /**
   * Close the modal window.
   *
   * @memberof AlertComponent
   */
  public cancelBlocker(): void {
    this.taggingService.sendTeliumDataLink(
      {
        'interaction_action':'bloqueo_total',
        'interaction_label':'cancelar'
      },
      this.storage.getFromLocal('viewQuestions')
    );
    this.dataProxy.getQuestionsStatusService().emit('cancelBlocker');

  }

  /**
   * Close the modal window.
   *
   * @memberof AlertComponent
   */
  public executeBlocker(): void {
    this.taggingService.sendTeliumDataLink(
      {
        'interaction_action':'bloqueo_total',
        'interaction_label':'confirmar'
      },
      this.storage.getFromLocal('viewQuestions')
    );
    this.dataProxy.getQuestionsStatusService().emit('executeCardBlock');
  }
}
