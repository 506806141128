import moment from 'moment';

/**
 * Move object used by the welcome component
 *
 * @export
 * @class MoveModel
 */
export class MoveModel {
  /**
   * identificador de movimiento
   *
   * @memberof MoveModel
   */
  public id = '';
  /**
   * descripcion del movimiento 
   *
   * @memberof MoveModel
   */
  public desc = '';
  /**
   * monto del movimiento 
   *
   * @memberof MoveModel
   */
  public amount = 0;
  /**
   * moneda del movimiento 
   *
   * @memberof MoveModel
   */
  public currency = '';
  /**
   * fecha del movimiento 
   *
   * @memberof MoveModel
   */
  public date = '';
  /**
   * theparse date 
   *
   * @memberof MoveModel
   */
  public parsedDate = '';
  /**
   * periodo del movimiento 
   *
   * @memberof MoveModel
   */
  public period = '';

  // Multifolio movimientos
  /**
   * codigo del comercio 
   *
   * @memberof MoveModel
   */
  public txrCodigoCom = '';
  /**
   * nombre del comercio 
   *
   * @memberof MoveModel
   */
  public txrComercio = '';
  /**
   * divisa del movimiento 
   *
   * @memberof MoveModel
   */
  public txrDivisa = '';
  /**
   * fecha de la transaccion 
   *
   * @memberof MoveModel
   */
  public txrFecha = '';
  /**
   * hora de transaccion 
   *
   * @memberof MoveModel
   */
  public txrHrTxr = '';
  /**
   * el modo de entrada 
   *
   * @memberof MoveModel
   */
  public txrModoEntrada = '';
  /**
   * monto 
   *
   * @memberof MoveModel
   */
  public txrMonto = '';
  /**
   * extracto del movimiento 
   *
   * @memberof MoveModel
   */
  public txrMovExtracto = '';
  /**
   * numero del extracto 
   *
   * @memberof MoveModel
   */
  public txrNumExtracto = '';
  /**
   * referencia 
   *
   * @memberof MoveModel
   */
  public txrReferencia = '';
  /**
   * sucursal de apertura 
   *
   * @memberof MoveModel
   */
  public txrSucursalAp = '';
  /**
   * tipo de factura 
   *
   * @memberof MoveModel
   */
  public txrTipoFactura = '';
  /**
   * the pan 
   *
   * @memberof MoveModel
   */
  public txrPAN = '';
   /**
   * type date for movement
   *
   * @memberof MoveModel
   */
  public typeDate = new Date();
  /**
   * Type of status Atm
   */
  public statusReverso = '';

  /**
   * Creates an instance of MoveModel.
   * @param {string} _id
   * @param {string} _desc
   * @param {string} _amount
   * @param {string} _date
   * @param {string} _period
   * @param {string} _txrCodigoCom
   * @param {string} _txrComercio
   * @param {string} _txrDivisa
   * @param {string} _txrFecha
   * @param {string} _txrHrTxr
   * @param {string} _txrModoEntrada
   * @param {string} _txrMonto
   * @param {string} _txrSucursalAp
   * @param {string} _txrMovExtracto
   * @param {string} _txrNumExtracto
   * @param {string} _txrReferencia
   * @param {string} _txrTipoFactura
   * @param {string} _txrPAN
   * @param {Date} _typeDate
   * @memberof MoveModel
   */
  constructor(
    _id: string,
    _desc: string,
    _amount: string,
    _date: string,
    _period:string,
    _txrCodigoCom:string,
    _txrComercio: string,
    _txrDivisa: string,
    _txrFecha: string,
    _txrHrTxr: string,
    _txrModoEntrada: string,
    _txrMonto: string,
    _txrSucursalAp: string,
    _txrMovExtracto: string,
    _txrNumExtracto: string,
    _txrReferencia: string,
    _txrTipoFactura: string,
    _txrPAN: string,
    _typeDate?:Date,
    _statusReverso?:string) {

    this.id             = _id;
    this.desc           = _desc;
    this.amount         = parseFloat(_amount.replace(',', ''));
    this.currency       = this.getCurrencyAmount(this.amount);
    this.date           = _date;
    this.parsedDate     = moment(_date, 'MM-DD-YYYY', 'es').toString();
    this.period         = _period;
    this.txrCodigoCom   = _txrCodigoCom;
    this.txrComercio    = _txrComercio;
    this.txrDivisa      = _txrDivisa;
    this.txrFecha       = _txrFecha;
    this.txrHrTxr       = _txrHrTxr;
    this.txrModoEntrada = _txrModoEntrada;
    this.txrMonto       = _txrMonto;
    this.txrSucursalAp  = _txrSucursalAp;
    this.txrMovExtracto = _txrMovExtracto;
    this.txrNumExtracto = _txrNumExtracto;
    this.txrReferencia  = _txrReferencia;
    this.txrTipoFactura = _txrTipoFactura;
    this.txrPAN = _txrPAN;
    this.typeDate = _typeDate;
    this.statusReverso = _statusReverso;
  }

  /**
   * Get the currency formatted amount
   *
   * @param {number} n
   * @returns {string}
   * @memberof MoveModel
   */
  public getCurrencyAmount(n: number): string {
    let res: string = n.toFixed(2).replace(/./g, (c, i, a) => {
        return i && c !== '.' && ((a.length - i) % 3 === 0) ? ',' + c : c;
    });
    return res;
  }
}
