import { Component, OnInit, OnDestroy, TemplateRef, EventEmitter,
  Output, HostListener } from '@angular/core';
import { Router , NavigationEnd } from '@angular/router';
import { MoveModel, QuestionsModel, StateModel, BlockModel, CreditCard, ResponseModel,
  MotiveModel, LoaderModel, UserModel } from './../../models';
import { DataObjectService } from './../../services/data-object.service';
import { DataProxyService } from './../../services/data-proxy.service';
import { DataService } from './../../services/data.service';
import { NavigationService } from './../../services/navigation/navigation.service';
import { TaggingService } from '../../services/tagging.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
// import { SimpleTimer } from 'ng2-simple-timer';
import * as _ from 'lodash';


/**
 * componente que va a mostrar las preguntas para dar una ata de aclaracion 
 *
 * @export
 * @class QuestionnaireComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-questionnaire',
  template: require('./questionnaire.component.html'),
  providers: [
    DataObjectService,
    DataService,
    NavigationService,
    TaggingService
  ]
})
export class QuestionnaireComponent implements OnInit, OnDestroy {

  /**
   * contenido del tooltip
   *
   * @type {string}
   * @memberof QuestionnaireComponent
   */
  public tooltipText : string = '';
  /**
   * bandera que indica si se esta seleccionando un motivo de para la aclaracion 
   *
   * @memberof QuestionnaireComponent
   */
  public isHideNav = false;
  /**
   * contenedor de movimientos seleccionados 
   *
   * @type {*}
   * @memberof QuestionnaireComponent
   */
  public selectedItems: any = [];
  /**
   * User data information 
   *
   * @protected
   * @type {UserModel}
   * @memberof QuestionnaireComponent
   */
  protected userData: UserModel;
  /**
   * seccion pata agregar a tap back
   *
   * @private
   * @type {string}
   * @memberof QuestionnaireComponent
   */
  private section: string = 'questionnaire';
  /**
   * datos por extracto
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private dataHandler = Array<MoveModel>();
  /**
   * estados de la republica 
   *
   * @private
   * @type {Array<StateModel>}
   * @memberof QuestionnaireComponent
   */
  private states: Array<StateModel> = Array<StateModel>();
  /**
   * estados que se muestran 
   *
   * @private
   * @type {Array<StateModel>}
   * @memberof QuestionnaireComponent
   */
  private shownStates: Array<StateModel> = Array<StateModel>();
  /**
   * preguntas al cliente 
   *
   * @private
   * @type {QuestionsModel}
   * @memberof QuestionnaireComponent
   */
  private questions:  QuestionsModel;
  /**
   * response model 
   *
   * @private
   * @type {ResponseModel}
   * @memberof QuestionnaireComponent
   */
  private responseDAO: ResponseModel;
  /**
   * overley component
   *
   * @private
   * @type {BsModalRef}
   * @memberof QuestionnaireComponent
   */
  private modalRef: BsModalRef;
  /**
   * the rawData
   *
   * @private
   * @type {*}
   * @memberof QuestionnaireComponent
   */
  private rawData: any;
  /**
   * contenedor de los mensajes de error enviados por back
   *
   * @private
   * @type {string}
   * @memberof QuestionnaireComponent
   */
  private errorMessage: string;
  /**
   * estado del boton enviar 
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private isEnabled = false;
  /**
   * estado de el select de estados 
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private enableStateSelector = false;
  /**
   * tiempo de espera a servicio 
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private timeoutOccured = false;
  /**
   * expresion regular
   *
   * @private
   * @type {*}
   * @memberof QuestionnaireComponent
   */
  private mask:any = [/[0-9]/, /[0-9]/,' ','/' ,' ', /[0-9]/,/[0-9]/];
  /**
   * expresion regular a dos numeros 
   *
   * @private
   * @type {*}
   * @memberof QuestionnaireComponent
   */
  private maskTwoNumbers: any = [/[0-9]/, /[0-9]/];
  /**
   * expresion regular a cuatro numeros 
   *
   * @private
   * @type {*}
   * @memberof QuestionnaireComponent
   */
  private maskFourNumbers: any = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  /**
   * expresion regular a seis numeros 
   *
   * @private
   * @type {*}
   * @memberof QuestionnaireComponent
   */
  private sixNumberMask: any = [/[0-9]/, /[0-9]/, /[0-9]/,/[0-9]/, /[0-9]/ ,/[0-9]/];
  /**
   * mensaje default de spiner 
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private defaultMessage = 'Esta pantalla se cerrará de forma automática cuando el proceso termine.';
  /**
   * contenedor del motivo seleccionado 
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private selectedMotive = '';
  /**
   * mensaje de cargando proceso 
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private loadingMessage = '';
  /**
   * salir del proceso
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private timerId = '';
  /**
   * contador de estados 
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private counter = 0;
  /**
   * contador de estados 
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private statesCounter = 0;
  /**
   * si falla algun servicio se pone en true 
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private isBusy = false;
  /**
   * pantala de espera 
   *
   * @private
   * @type {LoaderModel}
   * @memberof QuestionnaireComponent
   */
  private loader: LoaderModel;
  /**
   * modelo de motivos por aclaracion 
   *
   * @private
   * @type {Array<MotiveModel>}
   * @memberof QuestionnaireComponent
   */
  private motives: Array<MotiveModel> = [
    new MotiveModel(
      'IC-201',
      'Cargo duplicado',
      'Describa lo que <strong>sucedió</strong>:',
      'Ejem.: No reconozco el cargo porque me aparece en más de una ocasión.',
      'El cargo que hice está duplicado'
    ),
    new MotiveModel(
      'IC-202',
      'Monto alterado',
      '¿Cuál es el importe que <strong>autorizó y reconoce</strong>?',
      'Ejem.: Es de $120,000.00 el monto que si reconozco.',
      'El importe está alterado (solicitar pagaré firmado por cliente)'
    ),
    new MotiveModel(
      'IC-203',
      'Cargos adicionales al autorizado',
      'Indique los datos del (los) <strong>consumo(s) que sí reconoce</strong>:',
      'Ejem.: ID 2429384 - Compra en Liverpool',
      'Procesaron transacciones adicionales a la que autoricé(solicitar pagaré firmado por cliente)'
    ),
    new MotiveModel(
      'IC-206',
      'Pago por otro medio',
      'Describa lo que <strong>sucedió</strong>:',
      'Ejem.: No hice el pago a través de este medio.',
      'Pagué por otro medio(solicitar comprobante de pago)'
    ),
    new MotiveModel(
      'IC-205',
      'Devolución no aplicada',
      '<strong>Comentarios</strong> adicionales:',
      'Ejem.: No me han hecho mi devolución.',
      'La mercancía o servicios estaban defectuosos o difieren de lo acordado'
    ),
    new MotiveModel(
      'IC-204',
      'Mercancías o servicios no proporcionados',
      '<strong>Comentarios</strong> adicionales:',
      'Ejem.: Nunca me llegó mi mercancía',
      'Mercancía o servicios no recibidos'
    ),
    new MotiveModel(
      'IC-207',
      'Cancelación de servicio',
      '<strong>Comentarios</strong> adicionales:',
      'Ejem.: No estoy de acuerdo con el cobro del servicio, por lo cual lo cancelo.',
      'Cancelé el servicio(solicitar información de cancelación: fecha, clave, comprobante)'
    )
  ];

  /**
   * Creates an instance of QuestionnaireComponent.
   * @param {Router} router
   * @param {DataProxyService} dataProxy
   * @param {DataObjectService} dataObject
   * @param {DataService} dataService
   * @param {NavigationService} navigationService
   * @param {TaggingService} taggingService
   * @param {SimpleTimer} st
   * @param {BsModalService} modalService
   * @memberof QuestionnaireComponent
   */
  constructor(
      private router: Router,
      public dataProxy: DataProxyService,
      private dataObject: DataObjectService,
      private dataService: DataService,
      private navigationService: NavigationService,
      private taggingService: TaggingService,
      // private st: SimpleTimer,
      private modalService: BsModalService) {
    if (!this.dataProxy.getQuestions()) {
      this.dataProxy.setQuestions(new QuestionsModel());
    }
    this.questions = this.dataProxy.getQuestions();
    this.questions.resetHard();
    this.questions.lostDateValid = false;
    this.loadingMessage = this.defaultMessage;
    this.selectedMotive = `${this.questions.defaultMotive.title} <span class="angle-down"></span>`;
    if (!this.questions.motive) {
      this.questions.motive = this.questions.defaultMotive;
    } else {
      if (this.questions.defaultMotive.key !== this.questions.motive.key) {
        this.selectedMotive = this.swapMotive(this.questions.motive);
      }
    }
    // this.st.newTimer('10sec', 10);
  }

  /**
   * Angular Lifecycle hook: When the component it is initialized.
   *
   * @memberof QuestionnaireComponent
   */
  public ngOnInit() {
    this.setDataLayer();
    // Navigation rules
    // this.navigationService.setTitle('Cuestionario');
    this.navigationService.tapBack(this.section);
    this.questions.resetValues();
    this.questions.lostDateValid = false;
    this.dataHandler = this.dataObject.getSelectedMoves();
    this.userData = this.dataProxy.getUserData();
    this.states = this.dataProxy.getStates();
    if (this.states) {
      this.statesCounter = this.states.length;
    }

    this.assignShownStates(this.states);
    this.responseDAO = this.dataProxy.getResponseDAO();
    this.loader = this.dataProxy.getLoader();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
    this.dataHandler = this.dataProxy.getDataSelected();
    this.createMovements();
  }

  /**
   * Sets the dataLayer for Google Analytics
   *
   * @memberof WelcomeComponent
   * @returns {void}
   */
   public setDataLayer(): void {
    this.dataProxy.getCreditCardFullData();
    this.taggingService.pageSettings({
      subsection: 'questionnaire'
    },'credit');
    this.taggingService.sendTeliumDataView();
  }

  /**
   * Angular Lifecycle hook: When the component it is destroyed.
   *
   * @memberof QuestionnaireComponent
   */
  public ngOnDestroy() {
    // TODO: destroy implementation
  }
  /**
   * Scroll bottom.
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private scrollBottom(){
    setTimeout(()=>{
      window.scrollTo(0,document.body.scrollHeight);
    },150)
    
  }

  /**
   * Order movements by date.
   *
   * @memberof QuestionnaireComponent
   */
  createMovements(): void {
    
    this.selectedItems=[];
    try {
      this.counter= this.dataProxy.getDataSelected().length;
      for (let x = 0; x < this.dataProxy.getDataSelected().length; x++) {
        this.selectedItemsPush(this.dataProxy.getDataSelected()[x]);
      }
      this.selectedItems.forEach((element, index) => {
        this.selectedItems[index].data = _.orderBy(element.data, 'txrHrTxr', 'desc');
      });
      this.selectedItems = _.orderBy(this.selectedItems, 'dateKey');
    } catch (error) {
    }
  }

  /**
   * Add the selected items to the array.
   *
   * @private
   * @param {*} item
   * @memberof QuestionnaireComponent
   */
  private selectedItemsPush(item: any) {
    let splited = item.date.split('-');
    let dateKey = `${splited[2]}${splited[1]}${splited[0]}`
    if(this.getIndexAt(item.date)===-1){
      this.selectedItems.push({key: item.date, dateKey, data: []});
    }
    this.selectedItems[this.getIndexAt(item.date)].data.push(item);
  }

  /**
   * Selected items split.
   *
   * @param {*} item
   * @memberof QuestionnaireComponent
   */
  selectedItemsSplit(item){
    const idx: any = _.find(this.dataHandler, {id: item.id, txrNumExtracto: item.txrNumExtracto}, 0);
    const cindex: number = this.dataHandler.indexOf(idx);
    this.dataHandler.splice(cindex, 1);
    this.dataProxy.dataSourceSelected(this.dataHandler);
    let idx2 = _.find(this.selectedItems[this.getIndexAt(item.date)].data,item);
    this.selectedItems[this.getIndexAt(item.date)].data.splice(idx2,1);
    this.counter = this.dataHandler.length;
    this.createMovements();
  }

  /**
   * Get index at.
   *
   * @param {string} key
   * @returns
   * @memberof QuestionnaireComponent
   */
  getIndexAt(key:string){
    let idx = -1;
    for(let x = 0; x< this.selectedItems.length; x++){
      if(this.selectedItems[x].key===key){
        return x;
      }
    }
    return idx;
  }


  

  /**
   * Listen the interaction of the user and validate the native session.
   *
   * @private
   * @param {Event} $event
   * @memberof QuestionnaireComponent
   */
  @HostListener('window:scroll', ['$event'])
  private onWindowScroll($event: Event): void {
    this.navigationService.validateSession();
  }

  /**
   * Evaluate lost date.
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private evaluateLostDate() {
    this.navigationService.validateSession();
    this.questions.lostDateIsValid();
    if (this.questions.lostDateValid) {
      this.tryOut();
      this.scrollBottom();
    } else {
      this.isEnabled = false;
    }
  }

  /**
   * Toggle Card Status.
   *
   * @private
   * @param {*} e
   * @memberof QuestionnaireComponent
   */
  private toggleCardStatus(e) {
    this.navigationService.validateSession();
    this.questions.resetNOPath();
    this.questions.resetYESPath();
    this.questions.lostDateValid = false;
    this.questions.isValid();
    this.scrollBottom();
  }

  /**
   * Apply have contact.
   *
   * @private
   * @param {*} e
   * @memberof QuestionnaireComponent
   */
  private applyHaveContact(e) {
    this.navigationService.validateSession();
    this.isEnabled = false;
    this.questions.additionalComments = '';
    this.questions.motive = this.questions.defaultMotive;
    this.questions.location = 0;
    this.questions.state = this.questions.defaultState;
    /*if (this.questions.haveContact === '2') {
      this.questions.resetNOPath();
    } else {
      this.questions.resetYESPath();
    }*/
    if (e !== 1) { // If a contact was not made
      this.tryOut();
    }
    this.scrollBottom();
  }

  /**
   * Evaluate situation.
   *
   * @private
   * @param {*} e
   * @memberof QuestionnaireComponent
   */
  private evaluateSituation(e) {
    this.navigationService.validateSession();
    this.questions.softResetNoPath();
    this.isEnabled = false;
    if (e === this.questions.situations[1]) {
      this.isEnabled = true;
    }
    this.scrollBottom();
  }

  /**
   * Reset TextArea.
   *
   * @private
   * @param {*} evt
   * @memberof QuestionnaireComponent
   */
  private resetTextArea(evt: any){
    this.questions.location = 0;
    this.tryOut();
  }


  /**
   * Input validator for the comments.
   *
   * @param {*} event
   * @memberof QuestionnaireComponent
   */
  public inputValidator(event: any) {
    const pattern = /^[a-zA-Z0-9 _\\-\\.:,;áéíóúÁÉÍÓÚÜü¿?"¡!#$%&()=]*$/;   
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z0-9 _\\-\\.:,;áéíóúÁÉÍÓÚÜü¿?"¡!#$%&()=]/g, "");
    }
  }


  /**
   * Try out.
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private tryOut() {
    this.isEnabled = this.questions.isValid();
  }

  /**
   * Skip.
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private skip() {
    // this.st.unsubscribe(this.timerId);
    this.modalRef.hide();
    document.getElementById('body').style.removeProperty('overflow');
    this.router.navigate(['result']);
  }

  /**
   * Has card change.
   *
   * @private
   * @param {*} e
   * @memberof QuestionnaireComponent
   */
  private hasCardChange(e: any) {
    this.questions.resetHard();
    this.isEnabled = false;
  }

  /**
   * Check if the selected motive is the default.
   *
   * @private
   * @returns
   * @memberof QuestionnaireComponent
   */
  private checkSelectedMotive() {
    return this.questions.defaultMotive.title === this.questions.motive.title;
  }

  /**
   * Swap Motive and checks if the string is greater than 28 characters.
   *
   * @private
   * @param {MotiveModel} e
   * @returns {string}
   * @memberof QuestionnaireComponent
   */
  private swapMotive(e: MotiveModel): string {
    this.navigationService.validateSession();
    this.questions.motive = e;
    let short: string;
    // Check if the width of the ViewPort is lower than
    if (this.questions.motive.title.length >= 28) {
      let maxLength = 0;
      switch(true) {
        case (window.innerWidth <= 320):
          maxLength = 20;
          break;
        case (window.innerWidth <= 330):
          maxLength = 22;
          break;
        case (window.innerWidth <= 340):
          maxLength = 23;
          break;
        case (window.innerWidth <= 350):
          maxLength = 24;
          break;
        case (window.innerWidth <= 360):
          maxLength = 25;
          break;
        case (window.innerWidth <= 370):
          maxLength = 26;
          break;
        case (window.innerWidth <= 380):
          maxLength = 30;
          break;
        case (window.innerWidth <= 390):
          maxLength = 29;
          break;
        default:
          maxLength = 29;
      }
      short = this.questions.motive.title
                  .replace(/<(.|\n)*?>/g, ' ')
                  .substring(0, maxLength) + '...';
    } else {
      short = this.questions.motive.title;
    }
    this.selectedMotive = `${short} <span class="angle-down"></span>`;
    return this.selectedMotive;
  }

  /**
   * Tagging the state when changes in select.
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private tagState() {
    this.navigationService.validateSession();
  }



  /**
   * Assign shown states.
   *
   * @private
   * @param {Array<StateModel>} a
   * @memberof QuestionnaireComponent
   */
  private assignShownStates(a: Array<StateModel>) {
    if (a) {
      this.shownStates = _.slice(a, 0, a.length - 1 );
    }
  }

  /**
   * Validate States Selector.
   *
   * @private
   * @returns
   * @memberof QuestionnaireComponent
   */
  private validateStatesSelector() {
    let res = false;
    if (this.questions.additionalComments.length > 0 ||
       this.questions.whatHappens === this.questions.situations[1] ||
       this.questions.whatHappens === this.questions.situations[2] ||
       this.questions.haveContact === '2' ||
        this.questions.lostDateValid) {
          res = true;
        }
    return res;
  }

  /**
   * Swap foreign location.
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private swapForeignLocation() {
    this.navigationService.validateSession();
    let states: Array<StateModel> = this.dataProxy.getStates();
    
    if (!_.isUndefined(states[states.length - 1])) {
      this.questions.state = states[states.length - 1].nombre;
    }
    this.scrollBottom();
  }

  /**
   * Swap locale.
   *
   * @private
   * @memberof QuestionnaireComponent
   */
  private swapLocale() {
    this.navigationService.validateSession();
    this.questions.state = this.questions.defaultState;
    this.scrollBottom();
  }


  /**
   * Show tooltip.
   *
   * @param {*} idMotive
   * @memberof QuestionnaireComponent
   */
  public tooltipShow(idMotive){
    // Remove Inactive
    let element = document.getElementById('tooltipMotive');
    try{
      element.classList.remove("inactive");
    }catch(e){

    }
   

    //Inner HTML to Div
    switch (parseInt(idMotive)) {

        case 1:
        this.tooltipText = 'Se presenta cuando en los movimientos aparecen 2 importes por la misma compra.';
        break;

        case 2:
        this.tooltipText = 'Se presenta cuando se realizó  una compra y el movimiento tiene una cantidad diferente a la que se reconoce.';
        break;
        
        case 3:
        this.tooltipText = 'Se presenta cuando aparecen distintos o mayor número de importes.';
        break;
        
        case 4:
        this.tooltipText = 'Se presenta cuando el pago con la tarjeta falla, y aun así se realiza el cargo, aunque se haya pagado con otro medio (efectivo u otra tarjeta).';
        break;
        
        case 5:
        this.tooltipText = 'Se presenta cuando se realizó una compra, se devolvió el objeto y en el estado de cuenta el cargo fue aplicado.';
        break;
        
        case 6:
        this.tooltipText = 'Se presenta cuando se hizo una compra, nunca llegó el objeto y aun así el cargo fue aplicado.';
        break;
        
        case 7:
        this.tooltipText = 'Se presenta cuando se estableció un pago de servicio, se canceló el servicio y aun así se sigue aplicando el cargo.';
        break;

        case 8:
        this.tooltipText='A continuación se muestran los movimientos que eligió para mandar a aclarar.';
        break;

      default:
    }

  }

  /**
   * Opener Light Box.
   *
   * @param {*} event
   * @param {*} [idT]
   * @memberof QuestionnaireComponent
   */
  public tooltipOpener(event,idT?){
    this.tooltipShow(idT);
    let y = event.clientY;
    let x = event.clientX;
    let tooltip = document.getElementById('tooltip-box');
    let backdrop = document.getElementById('backdrop');
    let tooltipText  = document.getElementById('tooltip-text');
    let flagBorder = document.getElementById('flag-border');
    let flagColor = document.getElementById('flag-color');

    tooltipText.innerHTML = this.tooltipText;
    tooltip.style.top = (y-104)+'px';
    tooltip.style.position = 'fixed';
    tooltip.style.left = (x-156)+'px';
    flagColor.style.left = 50+'%';
    flagBorder.style.left = 50+'%';
    backdrop.classList.remove("tooltip-hide");
    tooltip.classList.remove("tooltip-hide");


    if(idT === 8){
      tooltip.style.left = (x-274)+'px';
      flagColor.style.left = 90+'%';
      flagBorder.style.left = 90+'%'  ;
    }

  }
  
  /**
   * Clean data selected and internal session.
   * Show history view.
   *
   * @memberof WelcomeComponent
   */
  showClarification() : void {
    this.dataProxy.setDataSource([]);
    this.dataProxy.internalSession = '';
    this.router.navigate(['history']);
  }

}
