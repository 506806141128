import 'reflect-metadata';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, ApplicationRef, LOCALE_ID } from '@angular/core';
import { removeNgStyles, createNewHosts, createInputTransfer } from '@angularclass/hmr';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BarRatingModule } from 'ngx-bar-rating';
import { HttpClientModule , HTTP_INTERCEPTORS} from '@angular/common/http';
import { TealiumUtagService } from './services/tealium/utag.service';

import moment from 'moment';

//Import storage service
import { AngularWebStorageModule } from 'angular-web-storage';

/* Platform and Environment providers/directives/pipes */
import { ENV_PROVIDERS } from './environment';
import { ROUTES } from './app.routes';
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppStateService } from './services/app-state.service';
import { InternalStateType } from '../app/models/internal-state.model.state';
import  localeEs from '@angular/common/locales/es';
import { NgxCurrencyModule } from "ngx-currency";

import {
  PreloaderComponent,
  WelcomeComponent,
  QuestionnaireComponent,
  HistoryComponent,
  SummaryComponent,
  ResultComponent,
  NoContentComponent,
  NoConnectionComponent,
  LockedComponent,
  DefinitivePaymentComponent
} from './pages';
//TDD imports components
import {
  WelcomeTddComponent,
  QuestionnaireTddComponent,
  AlertsTddComponent,
  CardTddComponent,
  FooterTddComponent,
  SteperTddComponent,
  TooltipTddComponent,
  LockedTddComponent,
  ResultTddComponent,
  SpinnerTddComponent,
  SummaryTddComponent,
  PreloaderTddComponent,
  DefinitivePaymentTddComponent
} from './pages/tdd/';
//ATN imports component
import{
QuestionnaireAtmComponent,
SummaryAtmComponent
} from './pages/atm';

import { FooterComponent, LoaderComponent,
  AlertComponent, QualityRatingComponent } from './partials';
import { KeysPipe, CustomCurrencyPipe, CustomCurrencyPlainPipe, CustomMovesDatePipe, MaskingPanPipe, 
  CurrencySmallCentsPipe, ReverseArrayPipe } from './pipes';

import {TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { SimpleTimer } from 'ng2-simple-timer';

import { UiSwitchModule } from 'angular2-ui-switch';
import { DefaultRequestOptions } from './services/default-request-options.service';
import { ConstantsService } from  './services/constants.service';
import { LabelsService } from  './services/labels.service';
import { DataService } from './services/data.service';
import { DataProxyService } from './services/data-proxy.service';
import { TaggingService } from './services/tagging.service';
import { SessionStorageService } from './services/tdd/session-storage.service';
import {UtilsTddService} from './services/tdd/utils-tdd.service';
import {UtilsService} from './services/utils.service';
import { AlertsTddService } from './services/tdd/alerts-tdd.service'; 

import { PersistenceModule } from 'angular-persistence';
import { AppInterceptor } from './services/app.interceptor';

/* Declarations */
// import { TextMaskModule } from 'angular2-text-mask';
/* Directives */
import { DebounceClickDirective } from './directives/debounce-click/debounce-click.directive';
import { ReloadSessionDirective } from './directives/reload-session';

import './styles/styles.scss';
import { NavigationService } from './services/navigation.service';

// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  AppStateService
];

type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 *
 *
 * @export
 * @class AppModule
 */
@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
    PreloaderComponent,
    WelcomeComponent,
    FooterComponent,
    LoaderComponent,
    AlertComponent,
    QualityRatingComponent,
    QuestionnaireComponent,
    SummaryComponent,
    HistoryComponent,
    ResultComponent,
    NoContentComponent,
    NoConnectionComponent,
    LockedComponent,
    DefinitivePaymentComponent,
    PreloaderTddComponent,
    DefinitivePaymentTddComponent,
    QuestionnaireTddComponent,
    ResultTddComponent,
    SummaryTddComponent,
    WelcomeTddComponent,
    CustomCurrencyPipe,
    CustomCurrencyPlainPipe,
    CustomMovesDatePipe,
    CurrencySmallCentsPipe,
    MaskingPanPipe,
    KeysPipe,
    ReverseArrayPipe,
    DebounceClickDirective,
    ReloadSessionDirective,
    AlertsTddComponent,
    CardTddComponent,
    FooterTddComponent,
    SteperTddComponent,
    TooltipTddComponent,
    LockedTddComponent,
    SpinnerTddComponent,
    QuestionnaireAtmComponent,
    SummaryAtmComponent
  ],
  /**
   * Import Angular's modules.
   */
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    BrowserAnimationsModule,
    // PersistenceModule,
    // TextMaskModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    RouterModule.forRoot(ROUTES, { useHash: true, preloadingStrategy: PreloadAllModules }),
    BarRatingModule,
    HttpClientModule,
    AngularWebStorageModule,
    NgxCurrencyModule
  ],
  /**
   * Expose our Services and Providers into Angular's dependency injection.
   */
  providers: [
    ENV_PROVIDERS,
    APP_PROVIDERS,
    // SimpleTimer,
    ConstantsService,
    LabelsService,
    DataProxyService,
    DataService,
    { provide: 'moment', useValue: moment },
    TaggingService,
    TealiumUtagService,
    SessionStorageService,
    UtilsTddService,
    UtilsService,
    AlertsTddService,
    { provide: LOCALE_ID, useValue:"en-MX"},
    {
			provide: HTTP_INTERCEPTORS,
			useClass: DefaultRequestOptions,
			multi: true
		},
    {
			provide: NavigationService,
			multi: true
		},
    {
			provide: HTTP_INTERCEPTORS,
			useClass: AppInterceptor,
			multi: true
		}
  ],
  entryComponents: [
    AlertComponent,
    LoaderComponent,
    QualityRatingComponent
  ]
})
export class AppModule {

  constructor(
    public appRef: ApplicationRef,
    public appState: AppStateService
  ) {}

  public hmrOnInit(store: StoreType) {
    if (!store || !store.state) {
      return;
    }
    /**
     * Set state
     */
    this.appState._state = store.state;
    /**
     * Set input values
     */
    if ('restoreInputValues' in store) {
      let restoreInputValues = store.restoreInputValues;
      setTimeout(restoreInputValues);
    }

    this.appRef.tick();
    delete store.state;
    delete store.restoreInputValues;
  }

  public hmrOnDestroy(store: StoreType) {
    const cmpLocation = this.appRef.components.map((cmp) => cmp.location.nativeElement);
    /**
     * Save state
     */
    const state = this.appState._state;
    store.state = state;
    /**
     * Recreate root elements
     */
    store.disposeOldHosts = createNewHosts(cmpLocation);
    /**
     * Save input values
     */
    store.restoreInputValues  = createInputTransfer();
    /**
     * Remove styles
     */
    removeNgStyles();
  }
/**
 *Display new elements
 *
 * @param {StoreType} store
 * @memberof AppModule
 */
public hmrAfterDestroy(store: StoreType) {
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
