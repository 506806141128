import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { AppStateService } from './../.././services/app-state.service';
import { LoaderModel } from './../../models/loader.model';
import { DataProxyService } from '../../services/data-proxy.service';

/**
 *
 *
 * @export
 * @class LoaderComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-loader',
  template: require('./loader.component.html')
})
export class LoaderComponent implements OnInit, OnDestroy {
  /**
   * the estatus of services 
   *
   * @type {LoaderModel}
   * @memberof LoaderComponent
   */
  public status: LoaderModel;
  /**
   * the message contend
   *
   * @type {string}
   * @memberof LoaderComponent
   */
  public message: string;

  /**
   * Creates an instance of LoaderComponent.
   * @param {DataProxyService} dataProxy
   * @memberof LoaderComponent
   */
  constructor(
    private dataProxy: DataProxyService
  ) {}

  /**
   * Angular Lifecycle hook: When the component it is initialized.
   *
   * @memberof LoaderComponent
   */
  public ngOnInit() {
    this.status = this.dataProxy.getLoader();
    if (this.status) {
      this.message = this.status.getMessage();
    } else {
      this.status = new LoaderModel();
      this.dataProxy.setLoader(this.status);
    }
  }

  /**
   * Angular Lifecycle hook: When the component it is destroyed.
   *
   * @memberof LoaderComponent
   */
  public ngOnDestroy() {
  }
}
