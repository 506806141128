import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CurrencyPipe } from '@angular/common';
import { Subscription, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { DataService } from './../../services/data.service';
import { MoveModel, UserModel, ExtractModel } from './../../models';
import { DataObjectService } from './../../services/data-object.service';
import { DataProxyService } from './../../services/data-proxy.service';
import { TaggingService } from '../../services/tagging.service';
import { NavigationService } from './../../services/navigation/navigation.service';
import { STORAGE_CONST } from '../../constants/storage.const';
import { SessionStorageService } from './../../services/tdd/session-storage.service';


import * as _ from 'lodash';
import moment from 'moment';
import * as md5 from 'blueimp-md5';

/**
 *
 *
 * @export
 * @class WelcomeComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-welcome',
  template: require('./welcome.component.html'),
  providers: [
    DataService,
    DataObjectService,
    TaggingService,
    NavigationService,
    CurrencyPipe
  ]
})
export class WelcomeComponent implements OnInit, OnDestroy {

  filterApply = false;
  filterName = '';
  sfApply = false;


  public isScrolled = false;
  public isHideNav = false;
  public counter = 0;
  public selectedItems: any = [];
  protected subscription: Subscription;
  protected movementsSubscription: Array<Subscription> = [];
  protected dataHandler = Array<MoveModel>();
  protected hiddenMovements = Array<MoveModel>();
  protected selectionHandler = Array<MoveModel>();
  protected MovementsInProccess = Array<MoveModel>();
  protected rawData: any;
  protected ccData: any;
  protected userData: UserModel;
  protected headerDate: string;
  protected errorMessage: string;
  protected navToggle = false;
  protected movToggle = false;
  protected reversoToggle = false;
  protected isLoading = false;
  protected filterDesc = '';
  protected currentFilter: string;
  protected currentExtract = '';
  protected dateArraysQuantity = 0;
  protected sortedDates: Array<string> = [];
  protected isFirstLoad = true;
  protected bottomReached: boolean;
  protected scrollDisabled = false;
  protected countDown;
  protected count = 3;
  protected isMovementsVisible = false;
  protected selectedFilterValue = -9999;
  protected isFiltered = false;
  private extract = 0;
  private consumerMovements: boolean = true;
  private dateArraysQuantityAtm: number = 0;

  /**
   * Creates an instance of WelcomeComponent.
   * @param {DataService} dataService
   * @param {DataProxyService} dataProxy
   * @param {TaggingService} taggingService
   * @param {NavigationService} navigationService
   * @param {Router} router
   * @param {DataObjectService} dataObject
   * @param {CurrencyPipe} currencyPipe
   * @memberof WelcomeComponent
   */
  constructor(private dataService: DataService,
    public dataProxy: DataProxyService,
    private taggingService: TaggingService,
    private navigationService: NavigationService,
    private router: Router,
    private dataObject: DataObjectService,
    private currencyPipe: CurrencyPipe,
    private storage: SessionStorageService) {
    // Bind methods for the navigation rules
    this.toggleNav = this.toggleNav.bind(this);
    this.toggleMov = this.toggleMov.bind(this);
  }

  /**
   * Loads initial content :: User data and previously selected items.
   *
   * @memberof WelcomeComponent
   */
  public ngOnInit() {
    this.resetStorage();
    // Navigation rules
    // this.navigationService.setTitle('Alta de aclaraciones');
    this.isMovementsVisible = false;
    this.navigationService.tapBack('welcome', this.navigationService.goToRoot);

    this.dataService.setUris(this.dataProxy.getEnviroment());
    if (this.dataProxy.getDataSelected()) {
      this.counter = this.dataProxy.getDataSelected().length;
    }
    if (this.dataProxy.getDataSelected()) {
      this.dataHandler = this.dataProxy.getDataSelected();
      this.selectionHandler = this.dataProxy.getDataSelected();
    }
    this.userData = this.dataProxy.getUserData();
    this.ccData = this.dataProxy.getCCData();
    this.bottomReached = false;
    if (this.userData.extracts.length > 0) {
      if (this.dataProxy.getDummyMode()) {
        this.filterExtractsById(this.getLastExtractId(), false, true);
      } else {
        // Get the movements of the first extract
        this.getInitMovements(0);
      }
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.createMovements();
    this.setDataLayer();
  }
  /**
   * Clean storage when flow init
   *
   * @private
   * @memberof WelcomeComponent
   */
  private resetStorage() {
    const storageKeys = Object.keys(STORAGE_CONST);
    for (let storageIndex of storageKeys) {
      ;
      this.storage.removeStorage(STORAGE_CONST[storageIndex]);
    }
  }
  /**
   * Sets the dataLayer for Google Analytics
   *
   * @memberof WelcomeComponent
   * @returns {void}
   */
  public setDataLayer(): void {
    this.dataProxy.getCreditCardFullData();
    this.taggingService.pageSettings({
      subsection: 'welcome'
    }, 'credit');
    this.taggingService.sendTeliumDataView();
  }

  /**
   * Angular Lifecycle hook: When the component it is destroyed.
   *
   * @memberof WelcomeComponent
   */
  public ngOnDestroy() {
    // window.removeEventListener('scroll', this.scroll, true);
  }

  /**
   * Submit filter form.
   *
   * @param {Event} $event
   * @memberof WelcomeComponent
   */
  public submitFilterForm($event: Event): void {
    document.getElementById('inlineFormInputGroup').blur();
  }

  /**
   * Get the first 20 movements of the credit card ordered by list.
   *
   * @private
   * @param {number} extractIndex
   * @memberof WelcomeComponent
   */
  private getInitMovements(extractIndex: number): void {
    this.extract += 1;
    const ID = this.getExtractId(extractIndex);
    this.currentFilter = ID;
    const movements = this.dataService.getMovements(ID);
    movements.then((response) => {
      if (!_.isUndefined(response.acctTrnRecComercios)) {
        // Check if the response has movements
        if (response.acctTrnRecComercios.length > 0 || response.acctTrnRecCajeros.length > 0) {
          this.isMovementsVisible = true;
          this.addMovements(extractIndex, response);
          let combinated = [];
          let combinatedATM = [];
          _.each(this.userData.extracts, (element) => {
            if (element.moves) {
              _.each(element.moves, (move, index) => {
                if (combinated.length < 20 && index <= 19) {
                  combinated.push(move);
                } else {
                  this.hiddenMovements.push(move);
                }
              });
            }
            if (element.movesATM) {
              _.each(element.movesATM, (move, index) => {
                if (combinatedATM.length < 20 && index <= 19) {
                  combinatedATM.push(move);
                }
              });
            }
          });
          const groupedMovements = _.groupBy(combinated, 'date');
          this.dataObject.filteredData = groupedMovements;
          this.dataObject.filteredData = this.sortDateKeys();
          const groupedMovementsATM = _.groupBy(combinatedATM, 'date');
          this.dataObject.filteredDataAtm = groupedMovementsATM;
          this.dataObject.filteredDataAtm = this.sortDateKeysAtm();
          this.dateArraysQuantity = Object.keys(this.dataObject.filteredData).length;
          this.dateArraysQuantityAtm = Object.keys(this.dataObject.filteredDataAtm).length;
          if (combinated.length < 20 || this.extract <= this.dataProxy.getUserExtracts().length) {
            this.getMoreMovements(extractIndex);
          }
        } else {
          // If the response does not have movements, get the next extract
          this.getMoreMovements(extractIndex);
        }
      }
      // Order the movements by date
      this.dataObject.filteredData = this.sortDateKeys();
      this.dataObject.filteredDataAtm = this.sortDateKeysAtm();
    });
  }
  /**
   * Sort the array of movements by date.
   *
   * @private
   * @returns {*}
   * @memberof WelcomeComponent
   */
  private sortDateKeysAtm(): any {
    const dates = Object.keys(this.dataObject.filteredDataAtm);
    // Format the dates
    let formattedDates: Array<string> = [];
    let sortedDates: Array<string> = [];
    let sortedMovements: Object = {};
    _.each(dates, (date) => {
      formattedDates.push(moment(date, 'DD-MM-YYYY').format('YYYYMMDD'));
    });
    // Sort the formatted dates in descending order
    const sorting = _.sortBy(formattedDates, (o) => {
      return moment(o);
    }).reverse();
    // Format again the sorted dates
    _.each(sorting, (date) => {
      sortedDates.push(moment(date, 'YYYYMMDD').format('DD-MM-YYYY'));
    });
    // Add all movements in the sorted order
    _.each(sortedDates, (date) => {
      sortedMovements[date] = this.dataObject.filteredDataAtm[date];
    });
    return sortedMovements;
  }
  /**
   * Get more movements from the next extract.
   *
   * @private
   * @param {number} extractIndex
   * @memberof WelcomeComponent
   */
  private getMoreMovements(extractIndex: number): void {
    if (!_.isUndefined(this.userData.extracts[extractIndex + 1])) {
      this.getInitMovements(extractIndex + 1);
    }
  }

  /**
   * Add the movements from the response to the extract.
   *
   * @private
   * @param {*} extractIndex
   * @param {*} response
   * @memberof WelcomeComponent
   */
  private addMovements(extractIndex: any, response: any): void {
    let moves: Array<MoveModel> = [];
    let movesAtm: Array<MoveModel> = [];
    _.each(response.acctTrnRecComercios, (v: any) => {
      let dateStr: string = moment(v.acctTrnInfo.stmtDt, 'YYYY-MM-DD').format('DD-MM-YYYY');
      let periodStr: string = moment(dateStr, 'DD-MM-YYYY').format('MMMM YYYY');
      let fecha = this.formatDate(v.acctTrnInfo.stmtDt);
      let nmove: MoveModel = new MoveModel(
        v.acctTrnId,
        v.acctTrnInfo.trnType.desc,
        v.acctTrnInfo.totalCurAmt.amt.toString(),
        dateStr,
        periodStr,
        v.acctTrnInfo.networkTrnData.merchNum,
        v.acctTrnInfo.networkTrnData.merchName,
        v.acctTrnInfo.origCurAmt.curCode.curCodeValue,
        fecha,
        v.acctTrnInfo.trnTime,
        v.acctTrnInfo.networkTrnData.posEntryCapability,
        v.acctTrnInfo.totalCurAmt.amt,
        this.ccData.cardRec.cardInfo.acctRef.acctInfo.fiData.branchIdent,
        v.acctTrnId,
        this.userData.extracts[extractIndex].id,
        v.acctTrnInfo.salesSlipRefNum,
        v.acctTrnInfo.trnType.trnTypeCode,
        v.acctTrnInfo.cardRef.cardInfo.cardNum
      );
      moves.push(nmove);
    });
    _.each(response.acctTrnRecCajeros, (v: any) => {
      if (v.acctTrnInfo.statusReverso != "REV01") {
        let dateStr: string = moment(v.acctTrnInfo.stmtDt, 'YYYY-MM-DD').format('DD-MM-YYYY');
        let periodStr: string = moment(dateStr, 'DD-MM-YYYY').format('MMMM YYYY');
        let fecha = this.formatDate(v.acctTrnInfo.stmtDt);
        let parsedStrDate = dateStr.split('-');
        let typedDate = new Date(Number(parsedStrDate[2]), Number(parsedStrDate[1]) - 1, Number(parsedStrDate[0]));
        let nmove: MoveModel = new MoveModel(
          v.acctTrnId,
          v.acctTrnInfo.trnType.desc,
          v.acctTrnInfo.totalCurAmt.amt.toString(),
          dateStr,
          periodStr,
          v.acctTrnInfo.networkTrnData.merchNum,
          v.acctTrnInfo.networkTrnData.merchName,
          v.acctTrnInfo.origCurAmt.curCode.curCodeValue,
          fecha,
          v.acctTrnInfo.trnTime,
          v.acctTrnInfo.networkTrnData.posEntryCapability,
          v.acctTrnInfo.totalCurAmt.amt,
          this.ccData.cardRec.cardInfo.acctRef.acctInfo.fiData.branchIdent,
          v.acctTrnId,
          this.userData.extracts[extractIndex].id,
          v.acctTrnInfo.salesSlipRefNum,
          v.acctTrnInfo.trnType.trnTypeCode,
          v.acctTrnInfo.cardRef.cardInfo.cardNum,
          typedDate,
          v.acctTrnInfo.statusReverso
        );
        movesAtm.push(nmove);
      }
    });
    movesAtm = _.sortBy(movesAtm, 'typeDate').reverse();
    this.userData.extracts[extractIndex].moves = moves;
    this.userData.extracts[extractIndex].movesATM = movesAtm;

  }

  /**
   * Listen the interaction of the user and validate the native session.
   *
   * @private
   * @param {Event} $event
   * @memberof WelcomeComponent
   */
  @HostListener('window:scroll', ['$event'])
  private onWindowScroll($event: Event): void {
    this.navigationService.validateSession();
    if (window.pageYOffset > 0) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
    //const that = this;
    if (!this.scrollDisabled) {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.bottomReached = true;
        if (!this.isFirstLoad) {
          this.showNextItems();
        }
        // this.scrollDisabled = true;
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
          this.countDown = timer(0, 1000).pipe(
            take(this.count),
            map(() => --this.count)
          )
          this.countDown.subscribe(
            (x) => { },
            (err) => { },
            () => {
              this.scrollDisabled = false;
            }
          );
        }
      }
    }
  }

  /**
   * Sort the array of movements by date.
   *
   * @private
   * @returns {*}
   * @memberof WelcomeComponent
   */
  private sortDateKeys(): any {
    const dates = Object.keys(this.dataObject.filteredData);
    // Format the dates
    let formattedDates: Array<string> = [];
    let sortedDates: Array<string> = [];
    let sortedMovements: Object = {};
    _.each(dates, (date) => {
      formattedDates.push(moment(date, 'DD-MM-YYYY').format('YYYYMMDD'));
    });
    // Sort the formatted dates in descending order
    const sorting = _.sortBy(formattedDates, (o) => {
      return moment(o);
    }).reverse();
    // Format again the sorted dates
    _.each(sorting, (date) => {
      sortedDates.push(moment(date, 'YYYYMMDD').format('DD-MM-YYYY'));
    });
    // Add all movements in the sorted order
    _.each(sortedDates, (date) => {
      sortedMovements[date] = this.dataObject.filteredData[date];
    });
    return sortedMovements;
  }

  /**
   * Add the hidden movements to the list and empty the array after that.
   *
   * @private
   * @memberof WelcomeComponent
   */
  private addHiddenMovements(): void {
    let movementsDates = Object.keys(this.dataObject.filteredData);
    let temporalHidden = [];
    if (this.hiddenMovements.length > 19) {
      temporalHidden = this.hiddenMovements.slice(20, this.hiddenMovements.length);
      this.hiddenMovements = this.hiddenMovements.slice(0, 19);
    }
    _.each(this.hiddenMovements, (movement) => {
      // Check if the date of the movement are on list
      const dateFound = _.find(movementsDates, (o) => o === movement.date);
      if (_.isUndefined(dateFound)) {
        this.dataObject.filteredData[movement.date] = [];
        this.dataObject.filteredData[movement.date].push(movement);
      } else {
        this.dataObject.filteredData[movement.date].push(movement);
      }
      movementsDates = Object.keys(this.dataObject.filteredData);
    });
    this.dataObject.filteredData = this.sortDateKeys();
    // Clear the hidden movements
    if (temporalHidden.length > 0) {
      this.hiddenMovements = temporalHidden;
    } else {
      this.hiddenMovements = Array<MoveModel>();
    }

  }
  /**
   * Show the hidden movements and the movements of the next extract.
   *
   * @private
   * @memberof WelcomeComponent
   */
  private showNextItems(): void {
    this.scrollDisabled = false;
    this.bottomReached = false;
    // Show the hidden movements
    if (this.hiddenMovements.length > 0) {
      this.addHiddenMovements();
    }
    const decrement: number = Number(this.currentFilter) - 1;
    // Check if the next extract exists
    const decrementIndex: number = this.getExtractIndex(String(decrement));
    if (decrement >= 0 && decrementIndex !== -1) {
      this.filterExtractsById(String(decrement), true, true);
      this.currentFilter = String(decrement);
    }
    this.dateArraysQuantity = Object.keys(this.dataObject.filteredData).length;
    this.dateArraysQuantityAtm = Object.keys(this.dataObject.filteredDataAtm).length;
  }
  /**
   * Keep Hidden prevents from open in the initial filter.
   *
   * @private
   * @param {string} ID
   * @param {boolean} [keepHidden=false]
   * @param {boolean} [append=false]
   * @param {boolean} [showOnlyExtract=false]
   * @returns {void}
   * @memberof WelcomeComponent
   */
  private filterExtractsById(
    ID: string,
    keepHidden: boolean = false,
    append: boolean = false,
    showOnlyExtract: boolean = false
  ): void {
    if (this.isLoading || typeof ID === 'undefined') { return; }
    const extracts: Array<ExtractModel> = this.userData.extracts;
    const currentSearchIndex: number = this.getExtractIndex(ID);
    this.currentFilter = ID;
    if (!_.isUndefined(extracts[currentSearchIndex]) && currentSearchIndex !== -1) {
      if (!extracts[currentSearchIndex].moves && !extracts[currentSearchIndex].movesATM) {
        this.loadExtract(ID);
      } else {
        this.evaluateIndex(extracts, currentSearchIndex, ID, append);
      }
    }
    this.bottomReached = false;
  }

  /**
   * Evaluate index.
   *
   * @private
   * @param {Array<ExtractModel>} a
   * @param {*} b
   * @param {*} c
   * @param {*} d
   * @memberof WelcomeComponent
   */
  private evaluateIndex(a: Array<ExtractModel>, b: any, c: any, d: any) {
    const extracts = a;
    const currentSearchIndex = b;
    const ID = c;
    const append = d;
    if (extracts[currentSearchIndex].moves.length > 0) {
      this.currentFilter = ID;
    }
    let idx: number = _.findIndex(extracts, (o: ExtractModel) => { return String(o.id) === String(ID); });
    if (idx > -1) {
      if (this.isFirstLoad) {
        this.loadExtract(ID);
      } else {
        if (append) {
          let combinated = [];
          let combinatedAtm = [];
          _.each(extracts, (element) => {
            if (element.moves) {
              combinated = _.unionBy(combinated, element.moves, 'id');
            }
            if (element.movesATM) {
              combinatedAtm = _.unionBy(combinatedAtm, element.movesATM, 'id');
            }
          });
          this.dataObject.filteredData = _.groupBy(combinated, 'date');
          this.dataObject.filteredData = this.sortDateKeys();
          this.dataObject.filteredDataAtm = _.groupBy(combinatedAtm, 'date');
          this.dataObject.filteredDataAtm = this.sortDateKeysAtm();
        } else {
          this.dataObject.filteredData = _.groupBy(extracts[idx].moves, 'date');
          this.dataObject.filteredData = this.sortDateKeys();
          this.dataObject.filteredDataAtm = _.groupBy(extracts[idx].movesATM, 'date');
          this.dataObject.filteredDataAtm = this.sortDateKeysAtm();
        }
      }
      this.dateArraysQuantity = Object.keys(this.dataObject.filteredData).length;
      this.dateArraysQuantityAtm = Object.keys(this.dataObject.filteredDataAtm).length;
    } else {
      this.loadExtract(ID);
    }
  }

  /**
   * Show filtered movements.
   *
   * @public
   * @param {string} ID
   * @memberof WelcomeComponent
   */
  public showFilteredMovements(ID: string): void {
    const extracts: Array<ExtractModel> = this.userData.extracts;
    const idx: number = this.getExtractIndex(ID);
    this.isFiltered = false;
    if (!_.isUndefined(extracts[idx]) && idx !== -1) {
      if (extracts[idx].moves) {
        this.dataObject.filteredData = _.groupBy(extracts[idx].moves, 'date');
        this.dataObject.filteredData = this.sortDateKeys();
      } else {
        this.dataObject.filteredData = {};
      }
      this.dateArraysQuantity = Object.keys(this.dataObject.filteredData).length;

      if (extracts[idx].movesATM) {
        this.dataObject.filteredDataAtm = _.groupBy(extracts[idx].movesATM, 'date');
        this.dataObject.filteredDataAtm = this.sortDateKeysAtm();
      } else {
        this.dataObject.filteredDataAtm = {};
      }
      this.dateArraysQuantityAtm = Object.keys(this.dataObject.filteredDataAtm).length;
    }

    //
    this.filterApply = true;
    this.filterName = document.getElementById('extractLabel-' + ID).textContent;

  }

  /**
   * Check if is the first load.
   *
   * @private
   * @param {*} ID
   * @param {*} idx
   * @memberof WelcomeComponent
   */
  private checkFirstLoad(ID: any, idx: any): void {
    const extracts: Array<ExtractModel> = this.userData.extracts;
    // Get the first 20 movements
    this.dataObject.filteredData = this.getInitialMovements(ID, idx);
    this.dataObject.filteredDataAtm = this.getInitialMovementsAtm(ID, idx);
    this.dataObject.filteredData = this.sortDateKeys();
    if (Object.keys(this.dataObject.filteredData).length < 20) {
      const nextExtractIndex = Number(idx) + 1;
      if (!_.isUndefined(extracts[nextExtractIndex])) {
        this.checkFirstLoad(extracts[nextExtractIndex].id, nextExtractIndex);
      }
    } else {
      this.isFirstLoad = false;
    }
  }

  /**
   * Get initial movements.
   *
   * @private
   * @param {string} ID
   * @param {number} idx
   * @returns {Object}
   * @memberof WelcomeComponent
   */
  private getInitialMovements(ID: string, idx: number): Object {
    const extracts: Array<ExtractModel> = this.userData.extracts;
    // Get the first 20 movements of the extract
    let aux = _.take(extracts[idx].moves, 20);
    // Check if there are 20 movements
    let difference: number = Math.abs(aux.length - 20);
    let increment: number = idx;
    while (difference > 0) {
      if (!_.isUndefined(extracts[increment])) {
        // If the movements are lower than 20, get the difference from the next extract
        if (!extracts[increment].moves) {
          this.loadExtract(extracts[increment].id);
        }
        difference = Math.abs(aux.length - 20);
        const restOfMovements = _.take(extracts[increment].moves, difference);
        aux = _.unionBy(aux, restOfMovements, 'id');
        increment++;
      } else {
        difference = 0;
      }
    }
    return _.groupBy(aux, 'date');
  }
  /**
  * Get initial movements.
  *
  * @private
  * @param {string} ID
  * @param {number} idx
  * @returns {Object}
  * @memberof WelcomeComponent
  */
  private getInitialMovementsAtm(ID: string, idx: number): Object {
    const extracts: Array<ExtractModel> = this.userData.extracts;
    // Get the first 20 movements of the extract
    let aux = _.take(extracts[idx].movesATM, 20);
    // Check if there are 20 movements
    let difference: number = Math.abs(aux.length - 20);
    let increment: number = idx;
    while (difference > 0) {
      if (!_.isUndefined(extracts[increment])) {
        // If the movements are lower than 20, get the difference from the next extract
        if (!extracts[increment].movesATM) {
          this.loadExtract(extracts[increment].id);
        }
        difference = Math.abs(aux.length - 20);
        const restOfMovements = _.take(extracts[increment].movesATM, difference);
        aux = _.unionBy(aux, restOfMovements, 'id');
        increment++;
      } else {
        difference = 0;
      }
    }
    return _.groupBy(aux, 'date');
  }
  /**
   * Load Extract.
   *
   * @private
   * @param {string} ID
   * @memberof WelcomeComponent
   */
  private loadExtract(ID: string) {
    this.isLoading = true;
    this.currentExtract = ID;
    const extractIndex = this.getExtractIndex(ID);
    if (extractIndex !== -1 && !_.isUndefined(this.userData.extracts[extractIndex])) {
      if (!this.userData.extracts[extractIndex].moves) {
        if (this.dataProxy.getDummyMode()) {
          let dataURI = `assets/data/extract_${ID}.json`;
          this.movementsSubscription[ID] = this.dataService.dummyRequest(dataURI)
            .subscribe((response) => this.processExtractQuery(response, ID));
        } else {
          this.movementsSubscription[ID] = this.dataService
            .restRequest(
              '/moves/',
              'POST',
              {
                extract: ID
              },
              'user',
              this.dataProxy.getAccessToken()
            )
            .subscribe(
              (response) => this.processExtractQuery(response, ID),
              (error) => this.handleError(error)
            );
        }
      } else {
        this.filterExtractsById(ID, true);
      }
    }
  }

  /**
   * Process Extract Query.
   *
   * @private
   * @param {*} item
   * @param {string} ID
   * @memberof WelcomeComponent
   */
  private processExtractQuery(item: any, ID: string) {
    this.movementsSubscription[ID].unsubscribe();
    moment.locale('es');
    const extractQuery = this.getExtractIndex(this.currentExtract);
    let currentExtract: ExtractModel = this.userData.extracts[extractQuery];
    if (!_.isUndefined(currentExtract)) {
      if (!currentExtract.moves) {
        let moves: Array<MoveModel> = [];
        let movesAtm: Array<MoveModel> = [];
        _.each(item.acctTrnRecComercios, (v: any) => {
          let dateStr: string = moment(v.acctTrnInfo.stmtDt, 'YYYY-MM-DD').format('DD-MM-YYYY');
          let periodStr: string = moment(dateStr, 'DD-MM-YYYY').format('MMMM YYYY');
          let fecha = this.formatDate(v.acctTrnInfo.stmtDt);
          let nmove: MoveModel = new MoveModel(
            v.acctTrnId,
            v.acctTrnInfo.trnType.desc,
            v.acctTrnInfo.totalCurAmt.amt.toString(),
            dateStr,
            periodStr,
            v.acctTrnInfo.networkTrnData.merchNum,
            v.acctTrnInfo.networkTrnData.merchName,
            v.acctTrnInfo.origCurAmt.curCode.curCodeValue,

            fecha,
            v.acctTrnInfo.trnTime,
            v.acctTrnInfo.networkTrnData.posEntryCapability,
            v.acctTrnInfo.totalCurAmt.amt,
            this.ccData.cardRec.cardInfo.acctRef.acctInfo.fiData.branchIdent,
            v.acctTrnId,
            ID,
            v.acctTrnInfo.salesSlipRefNum,
            v.acctTrnInfo.trnType.trnTypeCode,
            v.acctTrnInfo.cardRef.cardInfo.cardNum
          );
          moves.push(nmove);
        });
        _.each(item.acctTrnRecCajeros, (v: any) => {
          if (v.acctTrnInfo.statusReverso != "REV01") {
            let dateStr: string = moment(v.acctTrnInfo.stmtDt, 'YYYY-MM-DD').format('DD-MM-YYYY');
            let periodStr: string = moment(dateStr, 'DD-MM-YYYY').format('MMMM YYYY');
            let fecha = this.formatDate(v.acctTrnInfo.stmtDt);
            let parsedStrDate = dateStr.split('-');
            let typedDate = new Date(Number(parsedStrDate[2]), Number(parsedStrDate[1]) - 1, Number(parsedStrDate[0]));
            let nmove: MoveModel = new MoveModel(
              v.acctTrnId,
              v.acctTrnInfo.trnType.desc,
              v.acctTrnInfo.totalCurAmt.amt.toString(),
              dateStr,
              periodStr,
              v.acctTrnInfo.networkTrnData.merchNum,
              v.acctTrnInfo.networkTrnData.merchName,
              v.acctTrnInfo.origCurAmt.curCode.curCodeValue,
              fecha,
              v.acctTrnInfo.trnTime,
              v.acctTrnInfo.networkTrnData.posEntryCapability,
              v.acctTrnInfo.totalCurAmt.amt,
              this.ccData.cardRec.cardInfo.acctRef.acctInfo.fiData.branchIdent,
              v.acctTrnId,
              ID,
              v.acctTrnInfo.salesSlipRefNum,
              v.acctTrnInfo.trnType.trnTypeCode,
              v.acctTrnInfo.cardRef.cardInfo.cardNum,
              typedDate,
              v.acctTrnInfo.statusReverso
            );
            movesAtm.push(nmove);
          }
        });
        this.userData.extracts[extractQuery].moves = moves;
        this.userData.extracts[extractQuery].movesATM = movesAtm;
        this.checkFirstLoad(ID, extractQuery);
      }
    }
    this.isLoading = false;
    if (this.isFirstLoad) {
      this.getInitialMovements(ID, extractQuery);
      this.getInitialMovementsAtm(ID, extractQuery);
      this.filterExtractsById(this.currentFilter, false, true);
    } else {
      this.filterExtractsById(this.currentFilter, true, true);
    }
  }

  /**
   * Get extract index.
   *
   * @private
   * @param {string} ID
   * @returns {number}
   * @memberof WelcomeComponent
   */
  private getExtractIndex(ID: string): number {
    let cix: number = _.findIndex(this.userData.extracts, (o: ExtractModel) => {
      return String(o.id) === String(ID);
    });
    return cix;
  }

  /**
   * Get last extract Id.
   *
   * @private
   * @returns {string}
   * @memberof WelcomeComponent
   */
  private getLastExtractId(): string {
    return this.userData.extracts[0].id;
  }

  /**
   * Get extract Id from an index.
   *
   * @private
   * @param {number} index
   * @returns
   * @memberof WelcomeComponent
   */
  private getExtractId(index: number) {
    return this.userData.extracts[index].id;
  }

  /**
   * Get next extract id.
   *
   * @private
   * @param {*} currentIndex
   * @returns {(any | undefined)}
   * @memberof WelcomeComponent
   */
  private getNextExtractId(currentIndex): any | undefined {
    const index = currentIndex + 1;
    if (!_.isUndefined(this.userData.extracts[index])) {
      return this.userData.extracts[index].id;
    }
    return;
  }

  /**
   * Get filter date name.
   *
   * @private
   * @param {string} ID
   * @returns {string}
   * @memberof WelcomeComponent
   */
  private getFilterDateName(ID: string): string {
    if (ID === undefined) {
      return '';
    }
    if (ID === this.getLastExtractId()) {
      return 'Corte actual';
    } else {
      let idx: number = _.findIndex(this.userData.extracts, { id: ID });
      if (idx > -1) {
        let dateNumber = moment(this.userData.cutoff, 'YYYY-MM-DD', 'es')
          .subtract(idx, 'months');
        let dateNameTo = dateNumber.format('DD [de] MMMM ');
        dateNumber = dateNumber.add(1, 'days');
        dateNumber = dateNumber.subtract(1, 'months');
        let dateNameFrom = dateNumber.format('[Del] DD [de] MMMM [al] ');
        return `${dateNameFrom}${dateNameTo}`;
      }
      return '';
    }
  }

  /**
   * Filter By Desc.
   *
   * @public
   * @param {string} evt
   * @memberof WelcomeComponent
   */
  public filterByDesc(evt: string): void {
    this.filterDesc = evt;
    this.dateArraysQuantity = 0;
    this.dateArraysQuantityAtm = 0;
    // Get the extracts
    const extracts: Array<ExtractModel> = this.userData.extracts;
    const extrIdx: number = this.getExtractIndex(this.currentFilter);
    if (evt === '') {
      if (extrIdx !== -1) {
        if (!_.isUndefined(extracts[extrIdx])) {
          this.dataObject.filteredData = this.filterAllElements(extracts);
          this.dataObject.filteredDataAtm = this.filterAllElementsAtm(extracts);
          this.dataObject.filteredData = this.sortDateKeys();
          this.dateArraysQuantity = Object.keys(this.dataObject.filteredData).length;
          this.dateArraysQuantityAtm = Object.keys(this.dataObject.filteredDataAtm).length;
        } else {
          this.filterExtractsById(this.currentFilter, true);
        }
      } else {
        const filter = Number(this.currentFilter) + 1;
        this.filterExtractsById(String(filter), true);
      }
    } else {
      this.dataObject.filteredData = this.filterAllElements(extracts);
      this.dataObject.filteredData = this.sortDateKeys();
      this.dataObject.filteredDataAtm = this.filterAllElementsAtm(extracts);
      this.dataObject.filteredDataAtm = this.sortDateKeysAtm();
      this.dateArraysQuantity = Object.keys(this.dataObject.filteredData).length;
      this.dateArraysQuantityAtm = Object.keys(this.dataObject.filteredDataAtm).length;
    }


    if (evt === '') {
      this.filterApply = false;
      this.filterName = '';
      this.sfApply = false;
    } else {
      this.filterApply = true;
      this.filterName = evt;
    }
  }


  /**
   * Filter show.
   *
   * @memberof WelcomeComponent
   */
  showFilterApply() {
    if (this.sfApply) {
      this.sfApply = false;
    } else {
      this.sfApply = true;
    }
  }

  /**
   * Filter all elements.
   *
   * @private
   * @param {Array<ExtractModel>} extracts
   * @returns {*}
   * @memberof WelcomeComponent
   */
  private filterAllElements(extracts: Array<ExtractModel>): any {
    let content: any = {};
    _.each(extracts, (element) => {
      if (element.moves && element.moves.length > 0) {
        const tmp = _.filter(element.moves, (o: MoveModel) => {
          return o.txrComercio
            .toString()
            .toLowerCase()
            .indexOf(this.filterDesc.toLowerCase()) > -1;
        });
        _.merge(content, tmp);
      }
    });
    return _.groupBy(content, 'date');
  }

  /**
 * Filter all elements.
 *
 * @private
 * @param {Array<ExtractModel>} extracts
 * @returns {*}
 * @memberof WelcomeComponent
 */
  private filterAllElementsAtm(extracts: Array<ExtractModel>): any {
    let content: any = [];
    let fullObject: any = [];
    _.each(extracts, (element) => {
      if (element.movesATM && element.movesATM.length > 0) {
        const tmp = _.filter(element.movesATM, (o: MoveModel) => {
          if (this.filterDesc) {
            return o.txrComercio
              .toString()
              .toLowerCase()
              .indexOf(this.filterDesc.toLowerCase()) > -1;
          } else {
            return o;
          }
        });
        content.push(tmp);
      }
    });
    _.each(content, (movesArray) => {
      _.each(movesArray, (moves) => {
        fullObject.push(moves);
      });
    });
    return _.groupBy(fullObject, 'date');
  }

  /**
   * Highlight the title according to the filter.
   *
   * @private
   * @param {string} title
   * @returns {string}
   * @memberof WelcomeComponent
   */
  private highlightTitle(title: string): string {
    if (!this.filterDesc || this.filterDesc.length === 0) {
      return title;
    }
    return title.replace(new RegExp(this.filterDesc, 'gi'), (match) => {
      return `<span class="highlightText">${match}</span>`;
    });
  }

  /**
   * Selection
   *
   * @private
   * @param {*} $evt
   * @param {*} item
   * @param {*} period
   * @memberof WelcomeComponent
   */
  private selection($evt, item, period) {
    if ($evt) {
      const aux: MoveModel = _.find(this.dataHandler, ['id', item.id]);
      if (!aux) {
        item.period = period;
        this.dataHandler.push(item);
      }
    } else {
      _.remove(this.dataHandler, (n) => {
        return n.id === item.id;
      });
    }
    this.dataObject.setSelectedMoves(this.dataHandler);

  }

  /**
   * Bypass Array.
   *
   * @private
   * @param {*} val
   * @returns
   * @memberof WelcomeComponent
   */
  private bypassArray(val) {
    return Array.from(val);
  }

  /**
   * Check If Selected.
   *
   * @private
   * @param {string} id
   * @param {string} extract
   * @returns
   * @memberof WelcomeComponent
   */
  private checkIfSelected(id: string, extract: string) {
    const idx = _.findIndex(this.dataProxy.getDataSelected(), (o: MoveModel) => {
      return o.id === id && o.txrNumExtracto === extract;
    });
    if (idx > -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Navigate to the next step.
   *
   * @public
   * @memberof WelcomeComponent
   */
  public nextStep() {
    const movements = this.dataProxy.getDataSelected();
    let movementsIds: number[] = [];
    let amounts: string[] = [];
    movements.forEach((element) => {
      movementsIds.push(element.id);
      amounts.push(element.txrMonto);
    });
    this.navigationService.validateSession();
    if (this.consumerMovements) {
      this.taggingService.sendTeliumDataLink({
        'interaction_category': 'seleccion_movimiento',
        'interaction_action': 'click_boton',
        'interaction_label': 'continuar'
      });
      this.router.navigate(['questionnaire']);
    } else {
      this.taggingService.sendTeliumDataLink({
        'interaction_category': 'movimientos_atm',
        'interaction_action': 'click_boton',
        'interaction_label': 'continuar'
      });
      this.validateMovementAtm();

    }

  }

  /**
   * Process data.
   *
   * @private
   * @param {Response} r
   * @memberof WelcomeComponent
   */
  private processData(r: Response) {
    this.subscription.unsubscribe();
    this.rawData = r;
    this.dataObject.filteredData = _.groupBy(this.rawData.transactions[0].moves, 'date');
    this.dataObject.filteredData = this.sortDateKeys();
    this.dataProxy.setRawData(r);
    this.dateArraysQuantity = Object.keys(this.dataObject.filteredData).length;
    this.dateArraysQuantityAtm = Object.keys(this.dataObject.filteredDataAtm).length;
  }

  /**
   * Create range.
   *
   * @private
   * @param {number} limit
   * @returns
   * @memberof WelcomeComponent
   */
  private createRange(limit: number) {
    let items: number[] = [];
    for (let i = 1; i <= limit; i++) {
      items.push(i);
    }
    return items;
  }

  /**
   * Retrieve parsed date.
   *
   * @private
   * @param {*} dateToBeParsed
   * @returns
   * @memberof WelcomeComponent
   */
  private retrieveParsedDate(dateToBeParsed: any) {
    moment.locale('es');
    return moment(dateToBeParsed, 'DD-MM-YYYY')
      .format('DD [<br />] MMMM');

  }

  /**
   * Retrieve date from position at.
   *
   * @public
   * @param {number} n
   * @returns {string}
   * @memberof WelcomeComponent
   */
  public retrieveDateFromPositionAt(n: number): string {
    let obj = Object.keys(this.dataObject.filteredData)[n];
    if (obj === undefined) {
      return obj;
    } else {
      return obj.toString();
    }
  }
  /**
   * Retrieve date from position at.
   *
   * @public
   * @param {number} n
   * @returns {string}
   * @memberof WelcomeComponent
   */
  public retrieveDateFromPositionAtAtm(n: number): string {
    let obj = Object.keys(this.dataObject.filteredDataAtm)[n];
    if (obj === undefined) {
      return obj;
    } else {
      return obj.toString();
    }
  }
  /**
   * Retrieve parsed date from position at.
   *
   * @public
   * @param {number} n
   * @returns
   * @memberof WelcomeComponent
   */
  public retrieveParsedDateFromPositionAt(n: number) {
    let obj = Object.keys(this.dataObject.filteredData)[n];
    if (obj === undefined) {
      return obj;
    } else {
      return this.retrieveParsedDate(obj.toString());
    }

  }
  /**
   * Retrieve parsed date from position at.
   *
   * @public
   * @param {number} index
   * @returns
   * @memberof WelcomeComponent
   */
  public retrieveParsedDateFromPositionAtATM(index: number) {
    let obj = Object.keys(this.dataObject.filteredDataAtm)[index];
    if (obj === undefined) {
      return obj;
    } else {
      return this.retrieveParsedDate(obj.toString());
    }

  }

  /**
   * Get Movements.
   *
   * @private
   * @param {number} n
   * @returns
   * @memberof WelcomeComponent
   */
  private getMovements(n: number) {
    let obj = Object.keys(this.dataObject.filteredData)[n];
    return this.dataObject.filteredData[obj];
  }
  /**
   * Get Movements.
   *
   * @public
   * @param {number} n
   * @returns
   * @memberof WelcomeComponent
   */
  public getMovementsATM(n: number) {
    let obj = Object.keys(this.dataObject.filteredDataAtm)[n];
    return this.dataObject.filteredDataAtm[obj];

  }
  /**
   * Toggle nav.
   *
   * @private
   * @memberof WelcomeComponent
   */
  private toggleNav(): void {
    // Check if the div is open
    if (!this.navToggle) {
      this.navigationService.tapBack('filters', this.toggleNav);
    } else {
      this.navigationService.tapBack('filters', this.navigationService.goToRoot);
    }
    this.navigationService.validateSession();
    this.navToggle = !this.navToggle;
    this.isHideNav = !this.isHideNav;
  }

  /**
   * Toggle mov.
   *
   * @private
   * @memberof WelcomeComponent
   */
  private toggleMov(): void {
    // Check if the div is open
    if (!this.movToggle) {
      this.navigationService.tapBack('filters', this.toggleMov);
    } else {
      this.navigationService.tapBack('filters', this.navigationService.goToRoot);
    }
    this.navigationService.validateSession();
    this.movToggle = !this.movToggle;
    this.isHideNav = !this.isHideNav;
    if (this.isHideNav) {
      document.body.style.overflow = 'hidden';
      this.createMovements();
    } else {
      document.body.style.overflow = 'auto';
      this.selectedItems = [];
    }
  }
  /**
   * Clean data selected and internal session.
   * Show history view.
   *
   * @memberof WelcomeComponent
   */
  showClarification(): void {
    this.dataProxy.setDataSource([]);
    this.dataProxy.internalSession = '';
    this.router.navigate(['history']);
  }
  /**
   * Order movements by date.
   *
   * @memberof WelcomeComponent
   */
  createMovements(): void {
    this.selectedItems = [];
    try {
      for (let x = 0; x < this.dataProxy.getDataSelected().length; x++) {
        this.selectedItemsPush(this.dataHandler[x]);
      }
      this.selectedItems.forEach((element, index) => {
        this.selectedItems[index].data = _.orderBy(element.data, 'txrHrTxr', 'desc');
      });
      this.selectedItems = _.orderBy(this.selectedItems, 'dateKey');
    } catch (error) {
    }
  }

  /**
   * Handle item.
   *
   * @public
   * @param {MoveModel} val
   * @param {string} period
   * @param {string} [type='']
   * @memberof WelcomeComponent
   */
  public handleItem(val: MoveModel, period: string, atmFlag: boolean, type: string = '') {
    const idx: any = _.find(this.dataHandler, { id: val.id, txrNumExtracto: val.txrNumExtracto }, 0);
    if (idx) {
      const cindex: number = this.dataHandler.indexOf(idx);
      this.dataHandler.splice(cindex, 1);
    } else {
      if (atmFlag) {
        this.dataHandler = [];
      }
      const mv: MoveModel = new MoveModel(
        val.id,
        val.desc,
        val.amount.toString(),
        val.date,
        period,
        val.txrCodigoCom,
        val.txrComercio,
        val.txrDivisa,
        val.txrFecha,
        val.txrHrTxr,
        val.txrModoEntrada,
        val.txrMonto,
        this.ccData.cardRec.cardInfo.acctRef.acctInfo.fiData.branchIdent,
        val.txrMovExtracto,
        val.txrNumExtracto,
        val.txrReferencia,
        val.txrTipoFactura,
        val.txrPAN,
        moment(val.date, 'YYYY-MM-DD').toDate(),
        val.statusReverso);
      this.dataHandler.push(mv);
    }
    this.dataProxy.setDataSource(this.dataHandler);
    this.storage.saveInLocal('isConsumer', this.consumerMovements);
    this.counter = this.dataHandler.length;
    this.navigationService.validateSession();
  }

  /**
   * Add the selected items to the array.
   *
   * @private
   * @param {*} item
   * @memberof WelcomeComponent
   */
  private selectedItemsPush(item: any) {
    let splited = item.date.split('-');
    let dateKey = `${splited[2]}${splited[1]}${splited[0]}`
    if (this.getIndexAt(item.date) === -1) {
      this.selectedItems.push({ key: item.date, dateKey, data: [] });
    }
    this.selectedItems[this.getIndexAt(item.date)].data.push(item);
  }

  /**
   * Selected items split
   *
   * @param {*} item
   * @memberof WelcomeComponent
   */
  selectedItemsSplit(item) {
    const idx: any = _.find(this.dataHandler, { id: item.id, txrNumExtracto: item.txrNumExtracto }, 0);
    const cindex: number = this.dataHandler.indexOf(idx);
    this.dataHandler.splice(cindex, 1);
    this.dataProxy.dataSourceSelected(this.dataHandler);
    let idx2 = _.find(this.selectedItems[this.getIndexAt(item.date)].data, item);
    this.selectedItems[this.getIndexAt(item.date)].data.splice(idx2, 1);
    this.counter = this.dataHandler.length;
    this.createMovements();

  }

  /**
   * Get index at.
   *
   * @param {string} key
   * @returns
   * @memberof WelcomeComponent
   */
  getIndexAt(key: string) {
    let idx = -1;
    for (let x = 0; x < this.selectedItems.length; x++) {
      if (this.selectedItems[x].key === key) {
        return x;
      }
    }
    return idx;
  }
  /**
   * Handle error.
   *
   * @private
   * @param {*} error
   * @memberof WelcomeComponent
   */
  private handleError(error: any) {
    //this.router.navigate(['no-connection']);
  }

  /**
   * Format Date.
   *
   * @private
   * @param {string} v
   * @returns {string}
   * @memberof WelcomeComponent
   */
  private formatDate(v: string): string {
    return moment(v, 'YYYY-MM-DD')
      .format('YYYY-MM-DD[T06:00:00+00:00]').toString();
  }


  /**
   * Opener Light Box.
   *
   * @param {*} event
   * @param {*} id
   * @memberof WelcomeComponent
   */
  public tooltipOpener(event, id) {

    //Define tooltipeText
    let ttText = '';
    switch (id) {
      case 2:
        ttText = 'Por favor toque “x” para eliminar un movimiento que ya no desee mandar a aclarar.';
        break;
      case 3:
        ttText = 'Compras en locales o centros comerciales';
        break;
      case 4:
        ttText = 'Retiros en cajero automático';
        break;
      default:
        ttText = 'Es importante señalar que se pueden aclarar movimientos de hasta 3 meses anteriores a la fecha actual.';
        break;
    }


    let y = event.clientY;
    let x = event.clientX;
    let tooltip = document.getElementById('tooltip-box');
    let backdrop = document.getElementById('backdrop');
    let tooltipText = document.getElementById('tooltip-text');
    let flagBorder = document.getElementById('flag-border');
    let flagColor = document.getElementById('flag-color');

    tooltipText.innerHTML = ttText;
    tooltip.style.top = (y - 104) + 'px';
    tooltip.style.position = 'fixed';
    tooltip.style.left = (x - 156) + 'px';
    flagColor.style.left = 50 + '%';
    flagBorder.style.left = 50 + '%';
    backdrop.classList.remove("tooltip-hide");
    tooltip.classList.remove("tooltip-hide");

    if (id === 1) {
      tooltip.style.left = (x - 274) + 'px';
      flagColor.style.left = 90 + '%';
      flagBorder.style.left = 90 + '%';
    }

  }

  /**
   * Show moves.
   *
   * @param {*} id
   * @memberof WelcomeComponent
   */
  showMoves(id) {

    let button = document.getElementById('button' + id);
    let element = document.getElementById('listMoves' + id);
    let txtB = document.getElementById('txtButton' + id);

    if (button.classList.contains('active')) {
      element.classList.remove('show');
      button.classList.remove('active');
      txtB.innerHTML = "Ver más"
    } else {
      element.classList.add('show');
      button.classList.add('active');
      txtB.innerHTML = "Ver menos"
    }
  }

  /**
   * change of movements to display
   * @param value  boolean value
   */
  public changeMoves(value: boolean) {
    this.consumerMovements = value;
    this.dataHandler = [];
    //this.dataProxyService.setDataSource(this.dataHandler);
    this.counter = this.dataHandler.length;
  }

  public prevStep(): boolean {
    if (this.consumerMovements) {
      this.taggingService.sendTeliumDataLink({
        'interaction_category': 'seleccion_movimiento',
        'interaction_action': 'click_boton',
        'interaction_label': 'atras'
      });
    } else {
      this.taggingService.sendTeliumDataLink({
        'interaction_category': 'movimientos_atm',
        'interaction_action': 'click_boton',
        'interaction_label': 'atras'
      });
    }
    history.back();
    return false;
  }
  validateMovementAtm() {
    // statusReverso ==='REV02'Operación en Proceso de ser Reversada
    let status: string = this.dataProxy.getDataSelected()[0].statusReverso
    if (status.includes("REV02")) {
      console.log(this.selectedItems);
      this.createMovements();
      this.toggleReverso();
      this.hideMovReverso();

    } else {
            this.router.navigate(['questionnaire-atm']);
    }

  }
  hideMovReverso() {
    this.MovementsInProccess.push(this.dataHandler[0])
    this.dataHandler.splice(0, 1);
    this.dataProxy.setDataSource(this.dataHandler);
  }

  validateMoveReversoselected(move) {
    let value = false;
    const index = _.findIndex(this.MovementsInProccess, (o) => {
      return o.id === move;
    })
    if (index >= 0) {
      value = true;
    }
    return value;
  }
  /**
* Toggle nav.
*
* @private
* @memberof WelcomeComponent
*/
  private toggleReverso(): void {
    // Check if the div is open
    if (!this.reversoToggle) {
      this.navigationService.tapBack('filters', this.toggleNav);
    } else {
      this.navigationService.tapBack('filters', this.navigationService.goToRoot);
      this.selectedItems = [];
    }
    this.navigationService.validateSession();
    this.reversoToggle = !this.reversoToggle;
    this.isHideNav = !this.isHideNav;

  }

}
