import { LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

// Services
import { NavigationService } from './../../services/navigation/navigation.service';
import { DataProxyService } from '../../services/data-proxy.service';
import { AppStateService } from '../.././services/app-state.service';
import { MoveModel, QuestionsModel } from '../../models';
import { SessionStorageService } from './../../services/tdd/session-storage.service';

import moment from 'moment';
import * as _ from 'lodash';
/**
 * Continue button used in the main pages.
 *
 * @export
 * @class FooterComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-footer',
  template: require('./footer.component.html'),
  providers: [
    NavigationService,
  ]
})
export class FooterComponent implements OnInit {
  public localState = { value: '' };
  private selectedItems: Array<MoveModel> = [];
  private toggleSelected = false;
  private hideSelected = false;
  private moves: any;
  private totalItems = 0;
  private active = true;
  private buttonText = 'Continuar';

  activateButton = true;




  /**
   *Creates an instance of FooterComponent.
   * @param {AppStateService} appState
   * @param {Router} router
   * @param {NavigationService} navigationService
   * @param {DataProxyService} dataProxy
   * @param {LocationStrategy} uri
   * @memberof FooterComponent
   */
  constructor(
    public appState: AppStateService,
    private router: Router,
    private navigationService: NavigationService,
    public dataProxy: DataProxyService,
    private uri: LocationStrategy,
    private storage: SessionStorageService
  ) { }

  /**
   * Angular Lifecycle hook: When the component it is initialized.
   *
   * @memberof FooterComponent
   */
  public ngOnInit() {
    this.router.events.subscribe((event) => {
      this.handleStatus();
    });
    if (this.dataProxy.getDataSelected()) {
      this.totalItems = this.dataProxy.getDataSelected().length;
    }

    
  }

  /**
   * Toggle selected list.
   *
   * @memberof FooterComponent
   */
  public toggleSelectedList(): void {
    this.toggleSelected = !this.toggleSelected;
    if (this.toggleSelected) {
      document.body.style.overflow = 'hidden';
    }
  }

  /**
   * Close the selected list.
   *
   * @memberof FooterComponent
   */
  public closeSelectedList(): void {
    this.dataProxy.setDataSource(this.selectedItems);
    this.toggleSelected = false;
    document.body.style.overflow = 'auto';
  }

  /**
   * Retrieve parsed date.
   *
   * @param {string} dateToBeParsed
   * @returns {string}
   * @memberof FooterComponent
   */
  public retrieveParsedDate(dateToBeParsed: string): string {
    moment.locale('es');
    return moment(dateToBeParsed, 'DD-MM-YYYY').format('dddd DD [de] MMMM, YYYY');
  }

  /**
   * Retrieve parsed date from position at.
   *
   * @param {number} n
   * @returns {string}
   * @memberof FooterComponent
   */
  public retrieveParsedDateFromPositionAt(n: number): string {
    return this.retrieveParsedDate(this.moves[n].toString());
  }

  /**
   * Check if selected.
   *
   * @param {string} id
   * @returns {boolean}
   * @memberof FooterComponent
   */
  public checkIfSelected(id: string): boolean {
    let itm = _.find(this.selectedItems, (o: MoveModel) => { return o.id === id; });
    return (itm) ? true : false ;
  }

  /**
   * Handle Selection.
   *
   * @param {MoveModel} val
   * @memberof FooterComponent
   */
  public handleSelection(val: MoveModel): void {
    const idx: any = _.find(this.selectedItems, { id: val.id }, 0);
    if (idx) {
      const cindex: number = this.selectedItems.indexOf(idx);
      this.selectedItems.splice(cindex, 1);
    } else {
      const mv: MoveModel = new MoveModel(
        val.id,
        val.desc,
        val.amount.toString(),
        val.date,
        val.period,
        val.txrCodigoCom,
        val.txrComercio,
        val.txrDivisa,
        val.txrFecha,
        val.txrHrTxr,
        val.txrModoEntrada,
        val.txrMonto,
        val.txrSucursalAp,
        val.txrMovExtracto,
        val.txrNumExtracto,
        val.txrReferencia,
        val.txrTipoFactura,
        val.txrPAN
      );
      this.selectedItems.push(mv);
    }
    if (this.selectedItems.length < 1) {
      this.closeSelectedList();
      this.router.navigate(['']);
    }
  }

  /**
   * Gets the current URL.
   *
   * @returns {string}
   * @memberof FooterComponent
   */
  public getCurrentURL(): string {
    let currentUrl: string = this.router.url.toString();
    let len = 0;
    if (currentUrl.lastIndexOf('?') > -1) {
      currentUrl = currentUrl.substr(0, currentUrl.lastIndexOf('?'));
    }
    currentUrl = currentUrl.substr(currentUrl.lastIndexOf('/'), currentUrl.length);
    return currentUrl;
  }

  /**
   * Get the current page.
   *
   * @returns {string}
   * @memberof FooterComponent
   */
  public getCurrentPage(): string {
    let url = this.getCurrentURL();
    return url.replace(/\//, '');
  }

  /**
   * Validate continue button.
   *
   * @returns {boolean}
   * @memberof FooterComponent
   */
  public validateContinueButton(): boolean {
    let res = false;
    let currentUrl: string = this.getCurrentURL();
    if (currentUrl === '/welcome') {
      if (this.dataProxy.getSelectedCount() > 0) {
        res = true;
      }
    } else if ( currentUrl === '/questionnaire'  || currentUrl === '/summary') {
      if (!this.dataProxy.getQuestions()) {
        return res;
      }
      return this.dataProxy.getQuestions().isValid();
    }
    else if (currentUrl === '/locked') {
      const respositionType = this.storage.getFromLocal('respositionType');
      if(respositionType !== null){
        res = true;
      }
    }
    return res;
  }

  /**
   * Handle status.
   *
   * @memberof FooterComponent
   */
  public handleStatus(): void {
    let currentUrl: string = this.getCurrentURL();
    this.active = false;
    switch(currentUrl){
      case '/welcome':
        this.active = false;
        break;
      case '/questionnaire':
        this.buttonText = 'Continuar';
        this.active = true; this.hideSelected = true;
        this.buttonText = 'Continuar';
        break;
      case '/summary':
        this.buttonText = 'Confirmar';
        this.hideSelected = false;
         break;
      case '/locked':
        if (this.dataProxy.getQuestions().blocker.operationCancellation) {
          this.buttonText = 'Continuar';
        } else {
          this.buttonText = 'Finalizar';
        }
        this.active = true;
         break;
      case '/history':
        this.hideSelected = true;
         break;


    }



    /*if (currentUrl === '/welcome') {
      this.active = false;
    }
    if (currentUrl === '/questionnaire') {
      this.buttonText = 'Continuar';
      this.active = true; this.hideSelected = true;
      this.buttonText = 'Continuar';
    }
    if (currentUrl === '/summary') {
      this.buttonText = 'Confirmar';
      this.hideSelected = false;
    }
    if (currentUrl === '/locked') {
      if (this.dataProxy.getQuestions().blocker.operationCancellation) {
        this.buttonText = 'Continuar';
      } else {
        this.buttonText = 'Finalizar';
      }
      this.active = true;
    }
    if (currentUrl === '/history') {
      this.hideSelected = true;
    }*/
  }

  /**
   * Handle navigation.
   *
   * @param {*} event
   * @memberof FooterComponent
   */
  public handleNavigation(event: any): void {

    this.activateButton=true;
    event.stopPropagation();
    let currentUrl: string = this.getCurrentURL();
    switch (currentUrl) {
      case '/welcome':
        this.welcomeConfig('questionnaire');
        break;
      case '/questionnaire':
        this.questionnaireConfig('summary');
        break;
      case '/summary':
        this.summaryConfig();
        break;
      case '/locked':
        this.navigationService.validateSession();
        if (this.dataProxy.getQuestions().blocker.operationCancellation) {
          this.active = false;
          this.dataProxy.getQuestionsStatusService().emit('validatedQuestionnaire');
        } else {
          this.navigationService.goToRoot();
        }
        break;
      default:
        break;
    }
  }
  /**
   * Welcome navigation config
   *
   * @private
   * @param {string} navigateTo
   * @memberof FooterComponent
   */
  private welcomeConfig(navigateTo : string): void {
    // Send the movements to the GA
    const movements = this.dataProxy.getDataSelected();
    let movementsIds: Number[] = [];
    let amounts: String[] = [];
      movements.forEach((element) => {
      movementsIds.push(element.id);
      amounts.push(element.txrMonto);
    });
    this.navigationService.validateSession();
    this.router.navigate([navigateTo]);
  }
  /**
   * Questionnaire navigation config
   *
   * @private
   * @param {string} navigateTo
   * @memberof FooterComponent
   */
  private questionnaireConfig(navigateTo : string): void {
    this.navigationService.validateSession();
    this.router.navigate([navigateTo]);
  }
  /**
   * Summary config to send info
   *
   * @private
   * @memberof FooterComponent
   */
  private summaryConfig():void{
    this.navigationService.validateSession();
    this.navigationService.hideBackButton();
    this.active = false;
    this.dataProxy.getQuestionsStatusService().emit('validatedQuestionnaire');
  }
  /**
   * Returns to the previous page.
   *
   * @memberof FooterComponent
   */
  public back(): void {
    let currentUrl: string = this.getCurrentURL();
    if (currentUrl === '/summary') {
      this.router.navigate(['/questionnaire']);
    }
    if (currentUrl === '/questionnaire') {
      this.router.navigate(['/welcome']);
    }
  }

  /**
   * Block button.
   *
   * @memberof FooterComponent
   */
  blockButton(){

    let currentUrl: string = this.getCurrentURL();
    if(currentUrl === '/questionnaire'){
      //this.activateButton=false;
    }

  }





}


