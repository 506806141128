/**
 *Modelo estructural para obtener el multifolio 
 *obteniendo toda la informacion de cada movimiento
 *
 * @export
 * @class MultifolioModelComplete
 */
export class MultifolioModelComplete {
    /**
     * codigo del comercio
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrCodigoCom: string;
    /**
     * nombre del comercio 
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrComercio: string;
    /**
     * divisa del movimiento 
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrDivisa: string;
    /**
     * fecha del movimiento 
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrFecha: string;
    /**
     * hora del movimiento 
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrHrTxr: string;
    /**
     * modo de entrada 
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrModoEntrada: string;
    /**
     * monto del movimiento 
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrMonto: string;
    /**
     * extracto del movimiento 
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrMovExtracto: string;
    /**
     * numero del extracto 
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrNumExtracto: string;
    /**
     * referencia 
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrReferencia: string;
    /**
     * sucursal del aplicativo 
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrSucursalAp: string;
    /**
     * tipo de factura 
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrTipoFactura: string;
    /**
     * the PAN 
     *
     * @type {string}
     * @memberof MultifolioModelComplete
     */
    public TxrPAN: string;
 }
 