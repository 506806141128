import { Routes } from '@angular/router';
import {
  PreloaderComponent,
  WelcomeComponent,
  QuestionnaireComponent,
  SummaryComponent,
  ResultComponent,
  HistoryComponent,
  NoContentComponent,
  NoConnectionComponent,
  LockedComponent,
  DefinitivePaymentComponent
} from './pages';
import {
  WelcomeTddComponent,
  QuestionnaireTddComponent,
  CardTddComponent,
  FooterTddComponent,
  SteperTddComponent,
  LockedTddComponent,
  ResultTddComponent,
  SpinnerTddComponent,
  SummaryTddComponent,
  PreloaderTddComponent,
  DefinitivePaymentTddComponent
} from './pages/tdd/';
//ATN imports component
import{
  QuestionnaireAtmComponent,
  SummaryAtmComponent
  } from './pages/atm';

export const ROUTES: Routes = [
  { path: '', component: PreloaderComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'questionnaire', component: QuestionnaireComponent },
  { path: 'summary', component: SummaryComponent },
  { path: 'result', component: ResultComponent },
  { path: 'history', component: HistoryComponent },
  { path: 'no-content', component: NoContentComponent },
  { path: 'no-connection', component: NoConnectionComponent },
  { path: 'locked', component: LockedComponent },
  { path: 'welcome-tdd', component: WelcomeTddComponent },
  { path: 'summary-tdd', component: SummaryTddComponent },
  { path: 'locked-tdd', component: LockedTddComponent },
  { path: 'result-tdd', component: ResultTddComponent },
  { path: 'questionnaire-tdd', component: QuestionnaireTddComponent },
  { path: 'definitivePayment-tdd', component: DefinitivePaymentTddComponent },
  { path: 'tdd', component: PreloaderTddComponent },
  { path: 'definitivePayment', component: DefinitivePaymentComponent },
  { path: 'questionnaire-atm', component: QuestionnaireAtmComponent},
  { path: 'summary-atm', component: SummaryAtmComponent},
  { path: '**', component: NoContentComponent }
];
