import { Component, Input, HostListener } from '@angular/core';

/**
 *
 *
 * @export
 * @class TooltipTddComponent
 */
@Component({
  selector: 'app-tooltip-tdd',
  template: require('./tooltip-tdd.component.html')
})
export class TooltipTddComponent {
  /**
   *Creates an instance of TooltipTddComponent.
  * @memberof TooltipTddComponent
  */
  constructor() { }
  /**
   *
   *
   * @param {*} $event
   * @memberof TooltipTddComponent
   */
    @HostListener('window:scroll', ['$event'])
      onWindowScroll($event) {
        this.hideTooltip()
    }

    /**
     *method that changes the dom and show the tooltip
     *
     * @param {*} event
     * @param {string} text
     * @memberof TooltipTddComponent
     */
    showTooltip(event:any,text:string){
      let y = event.clientY;
      let x = event.clientX;
      let tooltip = document.getElementById('tooltip-tdd-box');
      let backdrop = document.getElementById('tooltip-tdd-backdrop');
      let tooltipText  = document.getElementById('tooltip-tdd-text');
      let flagBorder = document.getElementById('tooltip-tdd-flag-border');
      let flagColor = document.getElementById('tooltip-tdd-flag-color');
  
      tooltipText.innerHTML = text;
      tooltip.style.top = (y+20+ window.scrollY)+'px';
      tooltip.style.position = 'absolute';
      flagColor.style.left = (x-14)+'px';
      flagBorder.style.left = (x-14)+'px'  ;
      backdrop?.classList.remove("tooltip-hide");
      tooltip?.classList.remove("tooltip-hide");
    }
    /**
     *method that changes the dom and hides the tooltip
     *
     * @memberof TooltipTddComponent
     */
    hideTooltip(){
      let tooltip = document.getElementById('tooltip-tdd-box');
      let backdrop = document.getElementById('tooltip-tdd-backdrop');
      backdrop?.classList.add("tooltip-hide");
      tooltip?.classList.add("tooltip-hide");
      backdrop?.classList.add("tooltip-hide");
    }
}
