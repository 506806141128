import { Pipe, PipeTransform } from '@angular/core';
/**
 *
 *
 * @export
 * @class CustomCurrencyPlainPipe
 * @implements {PipeTransform}
 */
@Pipe({name: 'customCurrencyPlain'})
export class CustomCurrencyPlainPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {number} value
   * @returns {string}
   * @memberof CustomCurrencyPlainPipe
   */
  public transform(value: number): string {
    let p = value.toFixed(2).split('.');
    let chars = p[0].split('').reverse();
    let newstr = '';
    let count = 0;
    for (let x of chars) {
        count++;
        if(count%3 === 1 && count !== 1) {
            newstr = `${x},${newstr}`;
        } else {
            newstr = x + newstr;
        }
    }
    let cents: string = p[1].toString();

    return newstr.concat(`.${ cents } MXN`);
  }
}

