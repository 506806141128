import { Component, OnInit } from '@angular/core';
import { Router , NavigationEnd } from '@angular/router';

/**
 *
 *
 * @export
 * @class SteperTddComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-steper-tdd',
  template: require('./steper-tdd.component.html')
})
export class SteperTddComponent implements OnInit {
/**
 *Creates an instance of SteperTddComponent.
 * @param {Router} router
 * @memberof SteperTddComponent
 */
constructor(private router: Router) { }
/**
 *
 *
 * @memberof SteperTddComponent
 */
ngOnInit() {
  }

}
