import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
//Services
import { SessionStorageService } from './../../services/tdd/session-storage.service';
import { DataProxyService } from './../../services/data-proxy.service';
import { ResponseModel, QuestionsModel } from './../../models/';
import { NavigationService } from './../../services/navigation/navigation.service';

/**
 *  resolucion de una alta de aclaracion dentro con un pago definitivo 
 *
 * @export
 * @class DefinitivePaymentComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-definitive-payment',
  template: require('./definitive-payment.component.html'),
  providers: [
    NavigationService
  ]
})
export class DefinitivePaymentComponent implements OnInit {
  /**
   * bloqueo de la tarjeta 
   *
   * @type {*}
   * @memberof DefinitivePaymentComponent
   */
  public blocker: any;
  /**
   * continene toda la respuesta del servicio 
   *
   * @private
   * @type {ResponseModel}
   * @memberof DefinitivePaymentComponent
   */
  private responseModel: ResponseModel;
  /**
   * contenedor de preguntas contestadas por el usuario
   *
   * @private
   * @type {QuestionsModel}
   * @memberof DefinitivePaymentComponent
   */
  private questions: QuestionsModel;
  /**
   * bandera para mostrar nueva pantalla 
   *
   * @private
   * @type {boolean}
   * @memberof DefinitivePaymentComponent
   */
  private validateScreen : boolean = false;
  /**
   * respuesta de SM
   *
   * @private
   * @type {*}
   * @memberof DefinitivePaymentComponent
   */
  private smResponse: any;
  /**
   * abonos definitivos 
   *
   * @private
   * @type {*}
   * @memberof DefinitivePaymentComponent
   */
  private smResponseCleaned: any;
  /**
   * contenedor de folios I
   *
   * @type {Array<string>}
   * @memberof DefinitivePaymentComponent
   */
  public foliosI: Array<string> = [];
  /**
   * contenedor de folios H
   *
   * @type {Array<string>}
   * @memberof DefinitivePaymentComponent
   */
  public foliosH: Array<string> = [];

  
  /**
   * Obtiene le tipo de reposicion
   *
   * @private
   * @memberof DefinitivePaymentComponent
   */
  private repositionType = this.storage.getFromLocal('respositionType');
  /**
   *
   * - Obtiene el valor de respuesta para la reposicion
   * @private
   * @memberof DefinitivePaymentComponent
   */
  private cardRepositionResponse = this.storage.getFromLocal('cardRepositionResponse');

/**
 *Creates an instance of DefinitivePaymentComponent.
 * @param {SessionStorageService} storage
 * @param {DataProxyService} dataProxy
 * @memberof DefinitivePaymentComponent
 * @param {NavigationService} navigationService
 * @param {Router} router
 */
constructor(
    private storage: SessionStorageService,
    public dataProxy: DataProxyService,
    private navigationService: NavigationService,
    public router: Router
  ) {
    this.questions = this.dataProxy.getQuestions();
    this.blocker = this.questions.blocker;
  }

  /**
   * Loads initial content.
   *
   * @memberof DefinitivePaymentComponent
   */
  ngOnInit() {
    this.responseModel = this.dataProxy.getResponseDAO();
    this.smResponse = this.storage.getFromLocal('tdcSmResponse');
    this.smResponseCleaned = this.storage.getFromLocal('tdcSmResponseCleaned');
    //Validate Screen
    //let split=this.smResponse.Messages[0][6];
    let split='';

    for(let item of this.smResponse.Messages[0]){
      if(item.includes("Abono:")){
        split= item;
      }
    }
    
    let splited= split.split(" ");
    if(this.smResponse.wvrinboxrn.AptoDefinitivo && splited[1] === 'false'){
      this.validateScreen = true;
    }
    this.foliosNal();
    this.foliosInt();
  }

  /**
   *Sirve para separar los folios Nacionales y poderlos
   *mostrar 1 por 1 en la vista
   *
   * @memberof DefinitivePaymentComponent
   */

  public foliosNal(){
    
    //Folios Nacionales
    let nacional;

    for(let item of this.smResponse.Messages[0]){
      if(item.includes("Nacional:")){
        nacional= item;
        break;
      }else{
        nacional="NULL";
      }
    }

    if(nacional != "NULL"){

      nacional = nacional.split(': ');
      nacional = nacional[1].split(' ');



      // FOLIOS NACIONALES
      for(let nal of nacional){
        if(nal.match('H') && nal != 'undefined'){
          this.foliosH.push(nal);
        }else if(nal != 'undefined'){
          this.foliosI.push(nal);
        }

      }
    }

  }

  /**
   *Sirve para separar los folios Internacionales y poderlos
   *mostrar 1 por 1 en la vista
   *O
   * @memberof DefinitivePaymentComponent
   */

  public foliosInt(){


     //Folios Internacionales
    //let intern= this.smResponse.Messages[0][1];
    let intern;
    for(let item of this.smResponse.Messages[0]){
      if(item.includes("Internacional:")){
        intern= item;
        break;
      }else{
        intern="NULL";
      }
    }


    if(intern != "NULL"){
      intern = intern.split(': ');
      intern = intern[1].split(' ');

      //FOLIOS INTERNACIONALES
      for(let int of intern){
        if(int.match('H') && int != 'undefined'){
          this.foliosH.push(int);
        }else if(int != 'undefined'){
          this.foliosI.push(int);
        }
      }
    }

  }
  /**
   * Method for the finish button.
   *
   * @memberof ResultComponent
   */
  public finishApp(): void {
    this.navigationService.goToRoot();
  }
}
