/**
 * Modelo estructural para obtener el multifolio
 * modelo utilizado para separar los extractos y movimientos 
 * @export 
 * @class MultifolioModel 
 */
export class MultifolioModel {
    /**
     * id del movimeinto contenido en un extracto
     *
     * @type {string}
     * @memberof MultifolioModel
     */
    public AcctTrnId: string;
    /**
     *
     * id del  extracto o corte de los movimientos 
     * @type {string}
     * @memberof MultifolioModel
     */
    public AcctStmtId: string;
 }
 

