import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { DataService } from './../../services/data.service';
import { MoveModel, UserModel, ExtractModel, LoaderModel } from './../../models';
import { DataProxyService } from './../../services/data-proxy.service';
import { StatesService } from './../../services/states.service'
import { SessionStorageService } from './../../services/tdd/session-storage.service';
// Constants
import { ConstantsService } from './../../services/constants.service';

import { AlertComponent } from './../../partials/';

import { LocalStorageService, SessionStorageService as SessionService } from 'angular-web-storage';

import * as _ from 'lodash';
import moment from 'moment';

/**
 * Preloader page component
 *
 * @export
 * @class PreloaderComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-preloader',
  template: require('./preloader.component.html'),
  providers: [
    DataService,
    StatesService
  ]
})
export class PreloaderComponent implements OnInit {
  /**
   * the enviroment 
   *
   * @private
   * @type {*}
   * @memberof PreloaderComponent
   */
  private enviroment: any;
  /**
   * the subscription for events 
   *
   * @private
   * @type {Subscription}
   * @memberof PreloaderComponent
   */
  private subscription: Subscription;
  /**
   * the user data model 
   *
   * @private
   * @type {UserModel}
   * @memberof PreloaderComponent
   */
  private userData: UserModel;
  /**
   * the data handle 
   *
   * @private
   * @memberof PreloaderComponent
   */
  private dataHandler = Array<MoveModel>();
  /**
   * the handle section 
   *
   * @private
   * @memberof PreloaderComponent
   */
  private selectionHandler = Array<MoveModel>();
  /**
   * the raw data 
   *
   * @private
   * @type {*}
   * @memberof PreloaderComponent
   */
  private rawData: any;
  /**
   * the ccdata model 
   *
   * @private
   * @type {*}
   * @memberof PreloaderComponent
   */
  private ccData: any;
  /**
   * the filter desciption 
   *
   * @private
   * @memberof PreloaderComponent
   */
  private filterDesc = '';
  /**
   * the current filter 
   *
   * @private
   * @type {string}
   * @memberof PreloaderComponent
   */
  private currentFilter: string;
  /**
   * the dates array quantity 
   * 
   * @private
   * @memberof PreloaderComponent
   */
  private dateArraysQuantity = 0;
  /**
   * flag to entry  dommy mode 
   *
   * @private
   * @memberof PreloaderComponent
   */
  private dummyMode = false;
  /**
   * flag chager page 
   *
   * @private
   * @memberof PreloaderComponent
   */
  private isLoading = false;
  /**
   * the card number 
   *
   * @private
   * @memberof PreloaderComponent
   */
  private cardNumber = '';
  /**
   * the loader model 
   *
   * @private
   * @type {LoaderModel}
   * @memberof PreloaderComponent
   */
  private loader: LoaderModel;
  /**
   * the token acces 
   *
   * @private
   * @memberof PreloaderComponent
   */
  private token = '';
  /**
   * the modal ref 
   *
   * @private
   * @type {BsModalRef}
   * @memberof PreloaderComponent
   */
  private modalRef: BsModalRef;

  /**
   * check environment 
   *
   * @private
   * @type {BsModalRef}
   * @memberof PreloaderComponent
   */
  private methodType: string = '';

  /**
   * Creates an instance of PreloaderComponent.
   * @param {DataService} dataService
   * @param {DataProxyService} dataProxy
   * @param {ActivatedRoute} route
   * @param {Router} router
   * @param {ConstantsService} constantsService
   * @param {BsModalService} modalService
   * @param {SessionStorageService} storage
   * @memberof PreloaderComponent
   */
  constructor(
    private dataService: DataService,
    public dataProxy: DataProxyService,
    public statesService: StatesService,
    private route: ActivatedRoute,
    private router: Router,
    private constantsService: ConstantsService,
    private modalService: BsModalService,
    private storage: SessionStorageService,
    private session: SessionService
  ) {
    this.storage.clear();
    this.session.clear();
    localStorage.clear();
    sessionStorage.clear();
    this.dataProxy.clearData();
  }

  /**
   * Angular Lifecycle hook: When the component it is initialized.
   *
   * @memberof PreloaderComponent
   */
  public ngOnInit(): void {
    this.loader = new LoaderModel();
    if (this.dataProxy.internalSession === undefined) {
      this.dataProxy.clearData();
    }
    this.dataProxy.sessionparam = { idCC: "ACLA" };
    this.dataProxy.internalSession = undefined;
    // Get and Set the enviroment
    this.subscription = this.dataService
      .restRequest(
        'config.json',
        'GET',
        {},
        'config'
      )
      .subscribe(
        (response) => this.parseConfiguration(response),
        (error) => this.dataProxy.setEnviroment('dev')
      );
  }

  /**
   * Parse the alias of the configuration.
   *
   * @private
   * @param {*} config
   * @memberof PreloaderComponent
   */
  private parseConfiguration(config: any): void {
    // Search the enviroment
    this.enviroment = _.find(this.constantsService.ENVIROMENT, (item) => {
      return item.env === config.ENV_VAR;
    });
    if (!_.isUndefined(this.enviroment)) {
      this.dataProxy.setEnviroment(this.enviroment.env);
      this.dataService.setUris(this.enviroment.env);
      this.getQueryParams();
    } else {
      this.handleError('No enviroment');
    }
  }

  /**
   * Get the params in the URL.
   *
   * @private
   * @memberof PreloaderComponent
   */
  private getQueryParams(): void {
    let paramDummy: any = '';
    let paramNoLock: any = '';
    let comeFromHisory = this.dataProxy.getDummyMode();
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      paramDummy = params['dummy'];
      paramNoLock = params['nolock'];
      // paramDummy = 'true';
      this.dataProxy.setNoLock(false);
      this.dataProxy.setDummyMode(false);
      if (paramNoLock === 'true') {
        this.dataProxy.setNoLock(true);
      }
      if (paramDummy === 'true') {
        this.dummyMode = paramDummy;
        this.dataProxy.setPan(params['pan']);
        this.dataProxy.setOldCard(params['pan']);
        // this.dataProxy.setPan('5471460091895768');
        this.dataProxy.setDummyMode(true);
      }
      if (comeFromHisory === true) {
        this.dummyMode = true;
        this.dataProxy.setPan('0000000000000000');
        this.dataProxy.setOldCard('0000000000000000');
        this.dataProxy.setDummyMode(true);
      }
      this.postOAuthToken();
    });
  }

  /**
   * Post OAuth Token.
   *
   * @private
   * @memberof PreloaderComponent
   */
  private postOAuthToken(): void {
    if (this.dummyMode) {
      this.subscription = this.dataService.dummyRequest('assets/data/token.json')
        .subscribe((response) => this.processTokenOAuth()); // DUMMY MODE
    } else if (this.dataProxy.getIdToken() !== '' && this.dataProxy.getIdToken() !== undefined && this.dataProxy.getIdToken() !== null) {
      this.aquireClientData(this.dataProxy.getPan()); }
      else {
      this.processTokenOAuth();
    }
  }

  /**
   * Process token OAuth.
   *
   * @private
   * @param {*} r
   * @memberof PreloaderComponent
   */
  private processTokenOAuth(): void {
    if (!this.ccData) {
      if (this.dummyMode) {
        this.storage.saveInLocal('dummy', true);
        this.storage.saveInLocal('category', 'TDC PRINCIPAL');
        this.aquireClientData(this.dataProxy.getPan());
      } else {
        this.getID();
      }
    } else {
      this.dataProxy.setCreditCardFullData(this.ccData);
      this.ccData = this.dataProxy.getCCData();
      this.getStatesCatalog();
    }
  }

  /**
   * Get the ID from the token validator.
   *
   * @private
   * @memberof PreloaderComponent
   */
  private getID(): void {
    this.dataService
      .restRequest('/token_validator', 'POST', null, 'sso_token', this.token)
      .subscribe(
        (response) => {
          if (response.id && response.pan) {
            this.storage.saveInLocal('category', response.menu);
            this.cardNumber = response.pan;
            this.dataProxy.setIdToken(response.id);
            this.dataProxy.setPan(response.pan);
            this.dataProxy.setOldCard(response.pan);
            this.dataProxy.setBuc(response.buc);
            this.aquireClientData(this.dataProxy.getPan());
          }
        },
        (error) => this.handleError(error)
      );
  }

  /**
   * Aquire client data.
   *
   * Cambio de metodo GET -> POST 13/07/2020
   * @private
   * @param {string} cardID
   * @returns {(boolean | undefined)}
   * @memberof PreloaderComponent
   */
  private aquireClientData(cardID: string): boolean | undefined {
    if (!cardID) {
      return false;
    }
    this.loader.setMessage('Obteniendo información de usuario');
    if (this.dummyMode) {
      this.subscription = this.dataService.dummyRequest('assets/data/ccdata.json')
        .subscribe((response) => this.processCCData(response)); // DUMMY MODE
    } else {
      this.subscription = this.dataService
        .restRequest(
          '/detail/',
          'POST',
          {},
          'cards',
          this.dataProxy.getAccessToken()
        )
        .subscribe(
          (response) => this.processCCData(response),
          (error) => this.handleError(error)
        );
    }
  }

  /**
   * Process CCData.
   *
   * @private
   * @param {*} r
   * @memberof PreloaderComponent
   */
  private processCCData(r: any): void {
    if (!_.isUndefined(r.codigoMensaje)) {
      this.showAlert();
    }
    this.dataProxy.setCCData(r);
    this.ccData = r;
    this.getStatesCatalog();
  }



  /**
   * call states catalog service, 
   * if the size if the response is zero,
   *  get from the dummy file.
   *
   * @private
   * @memberof PreloaderComponent
   */
  private getStatesCatalog(): void {
    this.loader.setMessage('Obteniendo Entidades Federativas');
    this.subscription = this.statesService.getStatesCatalog(this.dummyMode)
      .subscribe((res) => {
        this.dataProxy.setStates(this.statesService.processStates(res))
      });
    this.aquireExtracts();
  }


  /**
   * Aquire extracts.
   *
   * @private
   * @memberof PreloaderComponent
   */
  private aquireExtracts(): void {
    if (this.dataProxy.getDataSelected()) {
      this.dataHandler = this.dataProxy.getDataSelected();
      this.selectionHandler = this.dataProxy.getDataSelected();
    }
    this.userData = this.dataProxy.getUserData();
    if (!this.userData) {
      if (this.dummyMode) {
        this.subscription = this.dataService.dummyRequest('assets/data/extracts.json')
          .subscribe((response) => this.processUserData(response)); // DUMMY MODE
      } else {
        // TODO: Throw an error if the cards has no info
        const cn = this.ccData.cardRec.cardInfo.cardNum;
        this.subscription = this.dataService
          .restRequest(
            '/extracts/',
            'GET',
            {},
            'user',
            this.dataProxy.getAccessToken()
          )
          .subscribe(
            (response) => this.processUserData(response),
            (error) => this.handleError(error)
          );
      }
    } else {
      this.preloaderComplete();
    }
  }

  /**
   * Process user data and save them in the local storage.
   *
   * @private
   * @param {Response} response
   * @memberof PreloaderComponent
   */
   private processUserData(response: Response): void {
    this.subscription.unsubscribe();
    const p: any = response;
    let usr: UserModel = new UserModel(
      this.dataProxy.getBuc(),
      this.ccData.cardRec.cardInfo.cardEmbossName,
      this.ccData.cardRec.cardInfo.cardNum,
      this.ccData.cardRec.cardInfo.cardTrnLimit[0].curAmt.amt,
      this.ccData.cardRec.cardInfo.acctRef.acctInfo.acctBal[1].curAmt.amt,
      this.ccData.cardRec.cardInfo.acctRef.acctInfo.acctBal[3].curAmt.amt,
      this.ccData.cardRec.cardInfo.acctRef.acctInfo.desc,
      this.ccData.cardRec.cardInfo.closeStmtDt,
      p.acctStmtRec
    );
    this.dataProxy.setUserData(usr);
    this.preloaderComplete();
  }

  /**
   * Show the error alert.
   *
   * @private
   * @memberof PreloaderComponent
   */
  private showAlert(): void {
    const options: any = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true
    };
    this.modalRef = this.modalService.show(AlertComponent, options);
    this.modalRef.content.type = 'servicesError';
  }

  /**
   * Handle error.
   *
   * @private
   * @param {*} error
   * @memberof PreloaderComponent
   */
  private handleError(error: any): void {
  }

  /**
   * Preloader complete.
   *
   * @private
   * @memberof PreloaderComponent
   */
  private preloaderComplete(): void {    
    this.router.navigateByUrl('welcome');
  }
}
