import {
    Component, OnInit, OnDestroy, TemplateRef, EventEmitter,
    Output, HostListener
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {
    MoveModel, QuestionsModel, StateModel, BlockModel, CreditCard, ResponseModel,
    MotiveModel, LoaderModel, UserModel
} from './../../../models';
import { SessionStorageService } from '../../../services/tdd/session-storage.service';
import { DataObjectService } from './../../../services/data-object.service';
import { DataProxyService } from './../../../services/data-proxy.service';
import { DataService } from './../../../services/data.service';
import { TaggingService } from './../../../services/tagging.service';
import { NavigationService } from './../../../services/navigation/navigation.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
// import { SimpleTimer } from 'ng2-simple-timer';
import { FormGroup, Validators, FormControl, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import * as _ from 'lodash';


/**
* amount validator 
* @param amount 
*/
function amountValidator(amount: AbstractControl): { [key: string]: boolean } | null {
    let amountAux = amount.value;
    if (amountAux === 0) {
        return null;
    } else {
        if (amountAux % 100 === 0 || amountAux % 50 === 0) {
            return null;
        }
    }
    return { 'amount': true }
}

  /**
 * description validator 
 * @param amount 
 */
   function ValidateDescription(describe: AbstractControl): {[key:string ]:boolean} |null { 
	let describeAux = describe.value; 
	let difSpace = false;
		if(describeAux.trim()!= ""){
			return null;
		}
		else{
			return { 'description': true }
		}
	
  }


/**
 * componente que va a mostrar las preguntas para dar una ata de aclaracion 
 *
 * @export
 * @class QuestionnaireAtmComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'app-questionnaire-atm',
    template: require('./questionnaire-atm.component.html'),
    providers: [
        DataObjectService,
        DataService,
        TaggingService,
        NavigationService
    ]
})
export class QuestionnaireAtmComponent implements OnInit, OnDestroy {

    /**
     *Motivos de alta de aclaracion 
     *
     * @memberof QuestionnaireATMComponent
     */
    public motives = [
        { key: 1, value: "El ATM no me dio el dinero que solicité" }, /*NO ENTREGO EFECTIVO  */
        { key: 2, value: "Tengo un retiro en ATM que yo no hice" }, /*NO RECONOCE RETIRO*/
        { key: 3, value: "Tengo un retiro en ATM que yo no hice" }, /*ORDENES DE PAGO CASO PARA MOSTRAR PANTALLA DE CONTACTE A SUPERLINEA*/
    ]

    /**
     *  form of the questionary 
     *
     * @type {FormGroup}
     * @memberof EntityViewComponent
     */
    public questionnaire: FormGroup;

    /**
     * total de un movimiento seleccionado 
     *
     * @type {*}
     * @memberof QuestionnaireATMComponent
     */
    public trxAmoutn: any;

    /**
     * factura del movimiento 
     *
     * @type {*}
     * @memberof QuestionnaireATMComponent
     */
    public trnTypeCode: any;
    /**
     *bandera que indica si esa operacion es permitida o no 
     *
     * @type {boolean}
     * @memberof QuestionnaireATMComponent
     */
    public opNoPermitida: boolean = false;
    /**
     * bandera que indica si es una operacion tipo orden de pago o retiro sin tarjeta 
     *
     * @type {boolean}
     * @memberof QuestionnaireATMComponent
     */
    public ordenPago: boolean = false;

    /**
     * contenido del tooltip
     *
     * @type {string}
     * @memberof QuestionnaireAtmComponent
     */
    public tooltipText: string = '';
    /**
     * bandera que indica si se esta seleccionando un motivo de para la aclaracion 
     *
     * @memberof QuestionnaireAtmComponent
     */
    public isHideNav = false;
    /**
     * contenedor de movimientos seleccionados 
     *
     * @type {*}
     * @memberof QuestionnaireAtmComponent
     */
    public selectedItems: any = [];
    /**
     * User data information 
     *
     * @protected
     * @type {UserModel}
     * @memberof QuestionnaireAtmComponent
     */
    protected userData: UserModel;
    /**
     * seccion pata agregar a tap back
     *
     * @private
     * @type {string}
     * @memberof QuestionnaireAtmComponent
     */
    private section: string = 'questionnaire';
    /**
     * datos por extracto
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private dataHandler = Array<MoveModel>();
    /**
     * estados de la republica 
     *
     * @private
     * @type {Array<StateModel>}
     * @memberof QuestionnaireAtmComponent
     */
    private states: Array<StateModel> = Array<StateModel>();
    /**
     * estados que se muestran 
     *
     * @private
     * @type {Array<StateModel>}
     * @memberof QuestionnaireAtmComponent
     */
    private shownStates: Array<StateModel> = Array<StateModel>();
    /**
     * preguntas al cliente 
     *
     * @private
     * @type {QuestionsModel}
     * @memberof QuestionnaireAtmComponent
     */
    private questions: QuestionsModel;
    /**
     * response model 
     *
     * @private
     * @type {ResponseModel}
     * @memberof QuestionnaireAtmComponent
     */
    private responseDAO: ResponseModel;
    /**
     * overley component
     *
     * @private
     * @type {BsModalRef}
     * @memberof QuestionnaireAtmComponent
     */
    private modalRef: BsModalRef;
    /**
     * the rawData
     *
     * @private
     * @type {*}
     * @memberof QuestionnaireAtmComponent
     */
    private rawData: any;
    /**
     * contenedor de los mensajes de error enviados por back
     *
     * @private
     * @type {string}
     * @memberof QuestionnaireAtmComponent
     */
    private errorMessage: string;
    /**
     * estado del boton enviar 
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private isEnabled = false;
    /**
     * estado de el select de estados 
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private enableStateSelector = false;
    /**
     * tiempo de espera a servicio 
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private timeoutOccured = false;
    /**
     * expresion regular
     *
     * @private
     * @type {*}
     * @memberof QuestionnaireAtmComponent
     */
    private mask: any = [/[0-9]/, /[0-9]/, ' ', '/', ' ', /[0-9]/, /[0-9]/];
    /**
     * expresion regular a dos numeros 
     *
     * @private
     * @type {*}
     * @memberof QuestionnaireAtmComponent
     */
    private maskTwoNumbers: any = [/[0-9]/, /[0-9]/];
    /**
     * expresion regular a cuatro numeros 
     *
     * @private
     * @type {*}
     * @memberof QuestionnaireAtmComponent
     */
    private maskFourNumbers: any = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    /**
     * expresion regular a seis numeros 
     *
     * @private
     * @type {*}
     * @memberof QuestionnaireAtmComponent
     */
    private sixNumberMask: any = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    /**
     * mensaje default de spiner 
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private defaultMessage = 'Esta pantalla se cerrará de forma automática cuando el proceso termine.';
    /**
     * contenedor del motivo seleccionado 
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private selectedMotive = '';
    /**
     * mensaje de cargando proceso 
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private loadingMessage = '';
    /**
     * salir del proceso
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private timerId = '';
    /**
     * contador de estados 
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private counter = 0;
    /**
     * contador de estados 
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private statesCounter = 0;
    /**
     * si falla algun servicio se pone en true 
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private isBusy = false;
    /**
     * pantala de espera 
     *
     * @private
     * @type {LoaderModel}
     * @memberof QuestionnaireAtmComponent
     */
    private loader: LoaderModel;
    /**
     * Save questions
     *
     * @private
     * @type {*}
     * @memberof QuestionnaireAtmComponent
     */
     private viewQuestions : any;

    public userdata: any ;

    /**
     * Datos del usuario a mostrar 
     *
     * @type {*}
     * @memberof SummaryATMComponent
     */
    public userInfo : any;

    /**
* metodo abstracto para tener el metodo get del componente hasCard
*
* @readonly
* @memberof EntityViewComponent
*/
    get hasCard() { return this.questionnaire.get('hasCard'); }
    /**
 * metodo abstracto para tener el metodo get del componente amount
 *
 * @readonly
 * @memberof EntityViewComponent
 */
    get amount() { return this.questionnaire.get('amount'); }
    /**
 * metodo abstracto para tener el metodo get del componente motive
 *
 * @readonly
 * @memberof EntityViewComponent
 */
    get motive() { return this.questionnaire.get('motive'); }

    /**
* metodo abstracto para tener el metodo get del componente entity
*
* @readonly
* @memberof EntityViewComponent
*/
    get entity() { return this.questionnaire.get('entity'); }

    /**
     * metodo abstracto para tener el metodo get del componente contactATM
     *
     * @readonly
     * @memberof EntityViewComponent
     */
    get contactATM() { return this.questionnaire.get('contactATM'); }


    /**
     * metodo abstracto para tener el metodo get del componente description
     *
     * @readonly
     * @memberof EntityViewComponent
     */
    get description() { return this.questionnaire.get('description'); }

    /**
     * metodo abstracto para tener el metodo get del componente entitySelected
     *
     * @readonly
     * @memberof EntityViewComponent
     */
    get entitySelected() { return this.questionnaire.get('entitySelected') || ''; }

    

    /**
     * Creates an instance of QuestionnaireAtmComponent.
     * @param {Router} router
     * @param {DataProxyService} dataProxy
     * @param {DataObjectService} dataObject
     * @param {DataService} dataService
     * @param {TaggingService} taggingService
     * @param {NavigationService} navigationService
     * @param {BsModalService} modalService
     * @memberof QuestionnaireAtmComponent
     */
    constructor(
        private router: Router,
        public dataProxy: DataProxyService,
        private dataObject: DataObjectService,
        private dataService: DataService,
        private taggingService: TaggingService,
        private navigationService: NavigationService,
        // private st: SimpleTimer,
        private modalService: BsModalService,
        private storage: SessionStorageService,) {
        if (!this.dataProxy.getQuestions()) {
            this.dataProxy.setQuestions(new QuestionsModel());
        }
        this.questions = this.dataProxy.getQuestions();
        this.questions.resetHard();
        this.questions.lostDateValid = false;
        this.loadingMessage = this.defaultMessage;
        this.selectedMotive = `${this.questions.defaultMotive.title} <span class="angle-down"></span>`;
        if (!this.questions.motive) {
            this.questions.motive = this.questions.defaultMotive;
        } else {
            if (this.questions.defaultMotive.key !== this.questions.motive.key) {
                this.selectedMotive = this.swapMotive(this.questions.motive);
            }
        }
        this.states =this.storage.getFromLocal('states');
        // this.st.newTimer('10sec', 10);
    }

    /**
 * caso 1 retiro con tarjeta 
 * 			tiene la tarjeta en su poder -> SI
 * 			que fue lo que paso / el atm no me dio el dinero que solicite 
 * 			cuanto dinero solicito
 * 			cuanto le dio el ATM
 * 			donde se encuentra
 * 			entidad federativa
 * caso 2 retiro con tarjeta 
 * 			tiene la tarjeta en su poder -> Si
 * 			que fue lo que paso / Tengo un retiro que no hice 
 * 			interactuo con el cajero -> NO
 * 			Describa lo que sucedio 
 * 			donde se encuentra 
 * 			Entidad federativa 
 * caso 3 Retiro sin tarjeta 
 * 		  	que fue lo que paso / el ATM no me dio el dinero que solicité
 * 			cuanto solicito
 * 			cuanto le dio el cajero 
 * 			En donde se encuentra 
 * 			Entidad federativa
 *
 * @memberof QuestionnaireATMComponent
 */
    public createForm() {

        this.questionnaire = new FormGroup({
            hasCard: new FormControl(''),
            motive: new FormControl('',),
            contactATM: new FormControl('NO'),
            description: new FormControl('',[ValidateDescription]),
            amount: new FormControl(0.0, [
                Validators.max(this.trxAmoutn),
                amountValidator
            ]),
            amoutnOrigin: new FormControl(this.trxAmoutn),
            entity: new FormControl(''),
            entitySelected: new FormControl(null, [Validators.required])
        });
        //es ordenes de pago//
        if (this.trnTypeCode !== "2561") {
            this.motives.splice(2, 1);
        } else {
            this.motives.splice(1, 1);
            this.questionnaire.controls['hasCard'].setValue('si');
            this.ordenPago = true;
        }
    }

    /**
     * Tomar los valores que se tienen del cliente 
     */
    private getUserInfo(){
    const fromTddFlow : any = this.storage.getFromLocal('userdata');
        if(this.dataProxy.getCreditCardFullData() !== null) {
            this.userInfo = {
                cardName : this.dataProxy.creditCardFullData.cardDesc,
                balance: this.dataProxy.creditCardFullData.balance,
                cardNumber: this.dataProxy.creditCardFullData.cardNumber
            }
                    this.storage.saveInLocal('states',this.dataProxy.getStates());
          this.storage.saveInLocal('multifolio',this.dataProxy.getDataSelected());
          this.storage.saveInLocal('enviroment',this.dataProxy.getEnviroment());
          this.storage.saveInLocal('userdata',this.dataProxy.getUserData());
          this.storage.saveInLocal('categoria','TARJETA DE CREDITO TARJETAHABIENTES');
          this.storage.saveInLocal('states',this.dataProxy.getStates());
          this.storage.saveInLocal('userdata',this.dataProxy.getUserData());
        } else {
            this.userInfo = {
                cardName : fromTddFlow.cardName,
                balance: fromTddFlow.saldo,
                cardNumber: fromTddFlow.cardNumber
            }
        }

    }

    /**
     * Angular Lifecycle hook: When the component it is initialized.
     *
     * @memberof QuestionnaireAtmComponent
     */
    public ngOnInit() {
        // Navigation rules
        // this.navigationService.setTitle('Cuestionario');
        this.getUserInfo();
        // GA - Tealium
        this.setDataLayer();
        this.userdata = this.storage.getFromLocal('userdata')
        this.navigationService.tapBack(this.section);
        this.questions.resetValues();
        this.questions.lostDateValid = false;
        this.dataHandler = this.dataObject.getSelectedMoves();
        this.userData = this.dataProxy.getUserData();
        this.states = this.storage.getFromLocal('states')

        if (this.states) {
            this.statesCounter = this.states.length;
        }

        this.assignShownStates(this.states);
        this.responseDAO = this.dataProxy.getResponseDAO();
        this.loader = this.dataProxy.getLoader();
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
        this.dataHandler = this.dataProxy.getDataSelected();
        this.createMovements();
        this.trxAmoutn = this.selectedItems[0].data[0].txrMonto * Math.sign(this.selectedItems[0].data[0].txrMonto );
        this.createForm();
    }
    /**
     * Sets the dataLayer for Google Analytics
     *
     * @memberof WelcomeComponent
     * @returns {void}
     */
     public setDataLayer(): void {
        this.dataProxy.getCreditCardFullData();
        this.taggingService.pageSettings({
        subsection: 'questionnaire-atm'
        },'credit');
        this.taggingService.sendTeliumDataView();
    }
    /**
     * Angular Lifecycle hook: When the component it is destroyed.
     *
     * @memberof QuestionnaireAtmComponent
     */
    public ngOnDestroy() {
        // TODO: destroy implementation
    }
    /**
     * Scroll bottom.
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private scrollBottom() {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 150)

    }

    /**
     * Order movements by date.
     *
     * @memberof QuestionnaireAtmComponent
     */
    createMovements(): void {

        this.selectedItems = [];
        try {
            this.counter = this.dataProxy.getDataSelected().length;
            for (let x = 0; x < this.dataProxy.getDataSelected().length; x++) {
                this.selectedItemsPush(this.dataProxy.getDataSelected()[x]);
            }
            this.selectedItems.forEach((element, index) => {
                this.selectedItems[index].data = _.orderBy(element.data, 'txrHrTxr', 'desc');
            });
            this.selectedItems = _.orderBy(this.selectedItems, 'dateKey');
        } catch (error) {
        }
    }

    /**
     * Add the selected items to the array.
     *
     * @private
     * @param {*} item
     * @memberof QuestionnaireAtmComponent
     */
    private selectedItemsPush(item: any) {
        let splited = item.date.split('-');
        let dateKey = `${splited[2]}${splited[1]}${splited[0]}`
        if (this.getIndexAt(item.date) === -1) {
            this.selectedItems.push({ key: item.date, dateKey, data: [] });
        }
        this.selectedItems[this.getIndexAt(item.date)].data.push(item);
    }


    /**
     * Get index at.
     *
     * @param {string} key
     * @returns
     * @memberof QuestionnaireAtmComponent
     */
    getIndexAt(key: string) {
        let idx = -1;
        for (let x = 0; x < this.selectedItems.length; x++) {
            if (this.selectedItems[x].key === key) {
                return x;
            }
        }
        return idx;
    }




    /**
     * Listen the interaction of the user and validate the native session.
     *
     * @private
     * @param {Event} $event
     * @memberof QuestionnaireAtmComponent
     */
    @HostListener('window:scroll', ['$event'])
    private onWindowScroll($event: Event): void {
        this.navigationService.validateSession();
    }


    /**
     * Input validator for the comments.
     *
     * @param {*} event
     * @memberof QuestionnaireAtmComponent
     */
    public inputValidator(event: any) {
        const pattern = /^[a-zA-Z0-9 _\\-\\.:,;áéíóúÁÉÍÓÚÜü¿?"¡!#$%&()=]*$/;
        if (!pattern.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^a-zA-Z0-9 _\\-\\.:,;áéíóúÁÉÍÓÚÜü¿?"¡!#$%&()=]/g, "");
        }
    }


    /**
     * Try out.
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private tryOut() {
        this.isEnabled = this.questions.isValid();
    }

    /**
     * Skip.
     *
     * @private
     * @memberof QuestionnaireAtmComponent
     */
    private skip() {
        // this.st.unsubscribe(this.timerId);
        this.modalRef.hide();
        document.getElementById('body').style.removeProperty('overflow');
        this.router.navigate(['result']);
    }


    /**
     * Swap Motive and checks if the string is greater than 28 characters.
     *
     * @private
     * @param {MotiveModel} e
     * @returns {string}
     * @memberof QuestionnaireAtmComponent
     */
    private swapMotive(e: MotiveModel): string {
        this.navigationService.validateSession();
        this.questions.motive = e;
        let short: string;
        // Check if the width of the ViewPort is lower than
        if (this.questions.motive.title.length >= 28) {
            let maxLength = 0;
            switch (true) {
                case (window.innerWidth <= 320):
                    maxLength = 20;
                    break;
                case (window.innerWidth <= 330):
                    maxLength = 22;
                    break;
                case (window.innerWidth <= 340):
                    maxLength = 23;
                    break;
                case (window.innerWidth <= 350):
                    maxLength = 24;
                    break;
                case (window.innerWidth <= 360):
                    maxLength = 25;
                    break;
                case (window.innerWidth <= 370):
                    maxLength = 26;
                    break;
                case (window.innerWidth <= 380):
                    maxLength = 30;
                    break;
                case (window.innerWidth <= 390):
                    maxLength = 29;
                    break;
                default:
                    maxLength = 29;
            }
            short = this.questions.motive.title
                .replace(/<(.|\n)*?>/g, ' ')
                .substring(0, maxLength) + '...';
        } else {
            short = this.questions.motive.title;
        }
        this.selectedMotive = `${short} <span class="angle-down"></span>`;
        return this.selectedMotive;
    }

    /**
     * Assign shown states.
     *
     * @private
     * @param {Array<StateModel>} a
     * @memberof QuestionnaireAtmComponent
     */
    private assignShownStates(a: Array<StateModel>) {
        if (a) {
            this.shownStates = _.slice(a, 0, a.length - 1);
        }
    }



    /**
     * Metodo para enviar valor de hascard y desiciones de que pregunta mostrar
     * @param value valor para hascard
     */
    public setValueCard(value: any) {

        if (value != this.questionnaire.get('hasCard').value) {
            this.questionnaire.controls['hasCard'].setValue(value);
            if (value === 'NO') {
                this.questionnaire.controls['motive'].setValue(this.motives[1]);
                this.questionnaire.controls['motive'].disable();
                this.validateMotive(1);
            } else {
                this.questionnaire.controls['motive'].setValue('');
                this.questionnaire.controls['motive'].enable();
            }
        }

    }

    /**
     * validar motivo con base al tipo de movimiento 
     * @param motive motivo 
     */
    public validateMotive(motive: any) {
        if (this.motives[motive] != this.questionnaire.get('motive').value) {
            this.questionnaire.controls['motive'].setValue(this.motives[motive]);
        }
        const description = this.questionnaire.controls['description'];

        description.setValue('');
        if (motive === 0) {
            description.setErrors(null);
        } else {
            description.setErrors(ValidateDescription); 
        }
        this.questionnaire.controls['amount'].setValue('0.0');
        this.questionnaire.controls['entity'].setValue('');
        this.questionnaire.controls['entitySelected'].setValue(null);


    }
    /**
     * Revisar si el cliente esta en la republica o fuera de ella 
     * @param value states clave
     */
    public setValueEntity(value: any) {
        if (value === 1) {
            this.questionnaire.controls['entity'].setValue(true);
            this.questionnaire.controls['entitySelected'].reset();
        } else {
            this.questionnaire.controls['entity'].setValue(false);
            this.questionnaire.controls['entitySelected'].setValue(this.states[32].clave);
        }
    }
    /**
     * Enviar a la pantalla de resumen 
     */
    public executeContinue(){
        this.getInteractionView();
        let interactionCategory : string = this.viewQuestions[1].value === 'TENGO UN RETIRO EN ATM QUE YO NO HICE' 
            ? 'atm_retiro_no_hice' : 'atm_no_dio_dinero_solicite' ;
        this.taggingService.sendTeliumDataLink({
            'interaction_action':'cuestionario',
            'interaction_label':'continuar',
            'interaction_category' : interactionCategory
        });
        this.router.navigate(['summary-atm']);
    }

   /**
    * 
    * get Interaction View of questionary 
    * @param {*} motive
    * @param {string} description
    * @param {string} commerceInt
    * @returns {Array<any>}
    * @memberof UtilsTddService
    */
    private getInteractionView(){
        let questions: any ;
        let hasCard = this.questionnaire.get('hasCard').value==='Sí' ? 'SI': 'NO'; 
        /*{key: 1, value: "El ATM no me dio el dinero que solicité"}*/
        let motive = this.questionnaire.get('motive').value;

        let subcategory="";
        questions = [
            {key:'TARJETA EN SU PODER',value:hasCard,id:'hasCard'},
            {key:'MOTIVO DE LA ACLARACION',value: motive.value.toUpperCase(), id:'reason'},
            {key: motive.value, value: 'SI'}
        ];
        ;
        switch(motive.key){
            case 1:
                questions.push({key:'CUANTO SOLICITO',value: this.trxAmoutn, id:'howMuchRequest'});
                questions.push({key:'CUANTO LE DIO EL CAJERO',value: this.questionnaire.get('amount').value, id:'howMuchGet' });
                questions.push({key:'MONTO TOTAL DE LA ACLARACION',value:  this.trxAmoutn - this.questionnaire.get('amount').value, id:'totalAmount' });
                subcategory = "NO ENTREGO EFECTIVO";
                break;
            case 2:
                questions.push({key:'MOTIVO',value: this.questionnaire.get('description').value,id:'description'});
                questions.push({key:'INTERACTUO CON EL CAJERO ATM',value:this.questionnaire.get('contactATM').value, id:'interactiveAtm'})
                questions.push({key:'MONTO TOTAL DE LA ACLARACION',value:  this.trxAmoutn, id:'totalAmount' });
                subcategory = "NO RECONOCE RETIRO";
                break;
            case 3:
                questions.push({key:'MONTO TOTAL DE LA ACLARACION',value:  this.trxAmoutn, id:'totalAmount' });
                subcategory = "ORDENES DE PAGO";
                break;
        }

        questions.push({key:'UBICACION ACTUAL',value: this.questionnaire.get('entitySelected').value  || '',id:'entitySelected'});


        let adittionaldata = {
            description: [this.questionnaire.get('description').value],
            lostdate: '',
            location: this.questionnaire.get('entitySelected').value  || ''
        }
        let question=[];
        questions.forEach(element => {
            question.push({Preguntas: element.key.toUpperCase(), Respuestas:element.value});
        });

        this.storage.saveInLocal('additionaldata', adittionaldata);
        this.viewQuestions = questions;
        this.storage.saveInLocal('viewQuestions', questions);
        this.storage.saveInLocal('questionnaire', question);
        this.storage.saveInLocal('subcategory',subcategory);
    }
    /**
     * Show tooltip.
     *
     * @param {*} idMotive
     * @memberof QuestionnaireAtmComponent
     */
    public tooltipShow(idMotive) {
        // Remove Inactive
        let element = document.getElementById('tooltipMotive');
        try {
            element.classList.remove("inactive");
        } catch (e) {

        }


        //Inner HTML to Div
        switch (parseInt(idMotive)) {

            case 1:
                this.tooltipText = 'Se presenta cuando en los movimientos aparecen 2 importes por la misma compra.';
                break;

            case 2:
                this.tooltipText = 'Se presenta cuando se realizó  una compra y el movimiento tiene una cantidad diferente a la que se reconoce.';
                break;

            case 3:
                this.tooltipText = 'Se presenta cuando aparecen distintos o mayor número de importes.';
                break;

            case 4:
                this.tooltipText = 'Se presenta cuando el pago con la tarjeta falla, y aun así se realiza el cargo, aunque se haya pagado con otro medio (efectivo u otra tarjeta).';
                break;

            case 5:
                this.tooltipText = 'Se presenta cuando se realizó una compra, se devolvió el objeto y en el estado de cuenta el cargo fue aplicado.';
                break;

            case 6:
                this.tooltipText = 'Se presenta cuando se hizo una compra, nunca llegó el objeto y aun así el cargo fue aplicado.';
                break;

            case 7:
                this.tooltipText = 'Se presenta cuando se estableció un pago de servicio, se canceló el servicio y aun así se sigue aplicando el cargo.';
                break;

            case 8:
                this.tooltipText = 'A continuación se muestran los movimientos que eligió para mandar a aclarar.';
                break;

            default:
        }

    }

    /**
     * Opener Light Box.
     *
     * @param {*} event
     * @param {*} [idT]
     * @memberof QuestionnaireAtmComponent
     */
    public tooltipOpener(event, idT?) {
        this.tooltipShow(idT);
        let y = event.clientY;
        let x = event.clientX;
        let tooltip = document.getElementById('tooltip-box');
        let backdrop = document.getElementById('backdrop');
        let tooltipText = document.getElementById('tooltip-text');
        let flagBorder = document.getElementById('flag-border');
        let flagColor = document.getElementById('flag-color');

        tooltipText.innerHTML = this.tooltipText;
        tooltip.style.top = (y - 104) + 'px';
        tooltip.style.position = 'fixed';
        tooltip.style.left = (x - 156) + 'px';
        flagColor.style.left = 50 + '%';
        flagBorder.style.left = 50 + '%';
        backdrop.classList.remove("tooltip-hide");
        tooltip.classList.remove("tooltip-hide");


        if (idT === 8) {
            tooltip.style.left = (x - 274) + 'px';
            flagColor.style.left = 90 + '%';
            flagBorder.style.left = 90 + '%';
        }

    }

    /**
     * Clean data selected and internal session.
     * Show history view.
     *
     * @memberof WelcomeComponent
     */
    showClarification(): void {
        this.dataProxy.setDataSource([]);
        this.dataProxy.internalSession = '';
        this.router.navigate(['history']);
    }


}
