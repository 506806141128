import { Component, Injectable } from '@angular/core';

// Evergreen browsers require these.
import 'core-js/es6/reflect';
import 'core-js/es7/reflect';

/**
 * Required to support Web Animations `@angular/animation`.
 * Needed for: All but Chrome, Firefox and Opera. http://caniuse.com/#feat=web-animation
 */

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js';
/**
 ** App Component
 */
@Component({
  selector: 'app-root',
  template: require('./app.component.html')
})
@Injectable()
export class AppComponent {
}
