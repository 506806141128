/**
 * Answers and Questions used in the Questionarie
 *
 * @export
 * @class AnswersQuestionsModel
 */
export class AnswersQuestionsModel {
    public Preguntas = '';
    public Respuestas = '';
}
