import { Component, OnInit, Inject } from '@angular/core';

import { DataService } from '../../services/data.service';
import { DataProxyService } from '../../services/data-proxy.service';

import { ResponseModel } from './../../models/';
import moment from 'moment';

/**
 * Open the Modal Window that shows the quality rating view.
 *
 * @export
 * @class QualityRatingComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'app-quality-rating',
    template: require('./quality-rating.component.html'),
    providers: [
        DataService,
    ]
})
export class QualityRatingComponent implements OnInit {
    protected comments = '';
    protected formRating = undefined;
    private responseModel: ResponseModel;

    /**
     * Creates an instance of QualityRatingComponent.
     * @param {DataService} dataService
     * @param {DataProxyService} dataProxy
     * @memberof QualityRatingComponent
     */
    constructor(
        private dataService: DataService,
        private dataProxy: DataProxyService
    ) { }

    /**
     * Angular Lifecycle hook: When the component it is initialized.
     *
     * @memberof QualityRatingComponent
     */
    public ngOnInit(): void {
        this.responseModel = this.dataProxy.getResponseDAO();
    }

    /**
     * Make a request to the endpoint.
     *
     * @memberof QualityRatingComponent
     */
    public onSubmit(): void {
        const params = {
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
            comment: this.comments,
            folio: this.getFolio(),
            stars: this.formRating,
            buc: 12345678
        };
        this.dataService
            .restRequest(
                '/score/save',
                'POST',
                params,
                'rating',
                this.dataProxy.getAccessToken()
            )
            .subscribe(
                (response) => this.exitApp(),
                (error) => this.exitApp()
            );
    }

    /**
     * Exit app.
     *
     * @private
     * @memberof QualityRatingComponent
     */
    private exitApp(): void {
        this.dataProxy.cleanData();
    }

    /**
     * Get folio.
     *
     * @private
     * @returns {string}
     * @memberof QualityRatingComponent
     */
    private getFolio(): string {
        let folios: Array<string> = [];
        const international = this.responseModel.getInternationalFolio();
        const national = this.responseModel.getNationalFolio();
        if (international) {
            folios.push(international);
        }
        if (national) {
            folios.push(national);
        }
        return folios.join();
    }
}
