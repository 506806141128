import { Injectable } from '@angular/core';
import { DataProxyService } from './../../services/data-proxy.service';
@Injectable()
export class TealiumUtagService {
  private scriptSrc = '';

  /**
   * Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
   * @memberof TealiumUtagService
   */
  constructor(
    private dataProxy: DataProxyService
  ) {
    ( window as any).utag_cfg_ovrd = { noview : true };
    ( window as any).utag_data = {};
  }

  /**
   * Generic script loader with callback
   *
   * @param {string} src
   * @param {() => void} callback
   * @returns {void}
   * @memberof TealiumUtagService
   */
  public getScript( src: string, callback: () => void ) : void {
    const d = document;
    const fn = () => {};
    const o = { callback: callback || fn };
    let s: any;
    let t: any;

    if ( typeof src === 'undefined' ) {
      return;
    }

    s = d.createElement('script'); s.language = 'javascript'; s.type = 'text/javascript'; s.async = 1; s.charset = 'utf-8'; s.src = src;
    if ( typeof o.callback === 'function' ) {
      if ( d.addEventListener ) {
        s.addEventListener('load', () => {o.callback(); }, false);
      } else {
        // old IE support
        s.onreadystatechange = function(){
          if (this.readyState === 'complete' || this.readyState === 'loaded'){this.onreadystatechange = null; o.callback(); }
        };
      }
    }
    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  /**
   * Config settings used to build the path to the utag.js file
   *
   * @memberof TealiumUtagService
   */
  public setConfig() : void {
    let environment = 'dev';
    if(( window as any).location.href.includes("pro")) {
        environment = 'prod';
    } else if(( window as any).location.href.includes("pre")){
        environment = 'qa';
    }
    this.scriptSrc = `https:////tags.tiqcdn.com/utag/santander/mx-aclaraciones/${environment}/utag.js`;
  }

  
  /**
   * Data layer is optional set of key/value pairs
   *
   * @param {string} tealiumEvent
   * @param {*} [data]
   * @returns {void}
   * @memberof TealiumUtagService
   */
  public track(tealiumEvent: string, data?: any) : void {
    if ( this.scriptSrc === '' ) {
      console.info('Tealium config not set.');
      return;
    }
    if ( ( window as any).utag === undefined ) {
      this.getScript( this.scriptSrc, () => {
        ( window as any).utag.track( tealiumEvent, data );
      });
    } else {
      ( window as any).utag.track( tealiumEvent, data );
    }
  }

  /**
   * Send view
   *
   * @param {*} [data]
   * @memberof TealiumUtagService
   */
  public view(data?: any) : void {
    this.track('view', data);
  }

  /**
   * Send link
   *
   * @param {*} [data]
   * @memberof TealiumUtagService
   */
  public link(data?: any) : void {
    this.track('link', data);
  }
}