import { Response } from '@angular/http';
import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {SessionStorageService} from '../../../services/tdd/session-storage.service';
import {UtilsTddService} from '../../../services/tdd/utils-tdd.service';
import { DataService } from '../../../services/data.service';
import { NavigationService } from '../../../services/navigation/navigation.service';

// Alertas
import { Subscription } from 'rxjs';
import { AlertsTddService } from '../../../services/tdd/alerts-tdd.service';
import { DataProxyService } from '../../../services/data-proxy.service';
//Taggeo
import { TaggingService } from '../../../services/tagging.service';
//Components
import { LoaderComponent, AlertComponent } from '../../../partials/';
//Models
import { MoveModel,  UserModel, ExtractModel, QuestionsModel, LoaderModel,
  BlockModel, ResponseModel, AnswersQuestionsModel,
  MultifolioModel, MultifolioModelComplete } from '../../../models';

import { toInteger } from 'lodash';

import { SessionStorageService as SessionService } from 'angular-web-storage';

/**
 * componente que da el resumen de los movimnientos y respuestas del usuario
 * da de alta la aclaracion
 *
 * @export
 * @class SummaryAtmComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-summary-atm',
  template: require('./summary-atm.component.html'),
  providers: [
    DataService,
    NavigationService,
  ]
})
export class SummaryAtmComponent implements OnInit {


  /**
   *
   * subscripcion a servicios para obtener una respuesta y 
   * llevar el contenido a la vista 
   * 
   * @type {Subscription}
   * @memberof SummaryAtmComponent
   */
  subscription: Subscription;


  /**
   * Show user data
   *
   * @memberof SummaryAtmComponent
   */
  public userInfo :any;

  /**
   * the questionsAnswered
   *
   * @type {*}
   * @memberof SummaryAtmComponent
   */
  public questionsAnswered : any = []

  /**
     * preguntas respondidas por el cliente 
     *
     * @type {*}
     * @memberof SummaryATMComponent
     */
    public questions : any;
/**
     * arreglo de estados 
     *
     * @private
     * @type {*}
     * @memberof SummaryATMComponent
     */
    private states : any;

  /**
   * Get movements selected
   *
   * @memberof SummaryAtmComponent
   */
  public movements = this.storage.getFromLocal('multifolio');
  /**
   * subscripcion a evento de bloqueo 
   *
   * @private
   * @type {Subscription[]}
   * @memberof SummaryComponent
   */
  private modalSubscription: Subscription[] = [];
  /**
   * Modal component 
   *
   * @private
   * @type {BsModalRef}
   * @memberof SummaryComponent
   */
  private modalRef: BsModalRef;
  /**
   * Flag to show modal and make clarification
   *
   * @private
   * @type {boolean}
   * @memberof SummaryAtmComponent
   */
  private cancelCardBlockFlag : boolean = false;
  /**
   * Indicate the dummy mode status
   *
   * @private
   * @type {string}
   * @memberof SummaryAtmComponent
   */
  private dummyMode : string = this.storage.getFromLocal('dummy');
  /**
   * spiner que se muestra mientras contesta un servicio 
   *
   * @private
   * @type {LoaderModel}
   * @memberof SummaryComponent
   */
  private loader: LoaderModel;
  /**
   * Check if the user no reported card
   *
   * @private
   * @type {boolean}
   * @memberof SummaryAtmComponent
   */
  private checkNoReportedStoleFlow : boolean;
  /**
   * Check if the user reported card
   *
   * @private
   * @type {boolean}
   * @memberof SummaryAtmComponent
   */
  private checkReportedStoleFlow : boolean;
  /**
   *Creates an instance of SummaryAtmComponent.
   * @param {UtilsTddService} utils
   * @param {SessionStorageService} storage
   * @param {DataService} dataService
   * @param {Router} router
   * @param {AlertsTddService} alertsTddService
   * @param {TaggingService} taggingService
   * @param {NavigationService} navigationService
   * @param {BsModalService} modalService
   * @param {DataProxyService} dataProxy
   * @memberof SummaryAtmComponent
   */
  constructor(
    private utils:UtilsTddService,
    private storage: SessionStorageService,
    private dataService: DataService,
    private router: Router,
    private alertsTddService : AlertsTddService,
    private taggingService: TaggingService,
    private navigationService: NavigationService,
    private modalService: BsModalService,
    public dataProxy: DataProxyService,
    private session: SessionService
  ) {
    /** 
     * Models    
     */ 
    this.loader = new LoaderModel();
  }
  
  /**
   * navega al inicio de la pagina
   * consulta el cuestionario a mostrar del storage
   * inicializa el servicio de urls
   * consulta el canal de la aplicacion
   *
   * @memberof SummaryAtmComponent
   */
  ngOnInit() {
    // GA - Tealium
    this.setDataLayer();
    this.utils.scrolltop();
    this.questions = this.storage.getFromLocal('viewQuestions');
    this.dataService.setUris(this.storage.getFromLocal('enviroment'));
    this.states = this.storage.getFromLocal('states');
    this.dataProxy.getQuestionsStatusService().subscribe((data) => {
      data === 'executeCardBlock' ?
        this.closeModal(() => {
          this.tokenValidator(true);
        })
        : this.cancelCardBlockWarning();
    });
    //Recibir mensaje bloqueo
    this.subscription = this.alertsTddService.getMessage()
      .subscribe(
        message => {
          if(message.response.number === -2){
            this.continueBlock();
          }
        }
    )
    this.getUserInfo();
  }
  /**
   * Sets the dataLayer for Google Analytics
   *
   * @memberof WelcomeComponent
   * @returns {void}
   */
  public setDataLayer(): void {
    this.dataProxy.getCreditCardFullData();
    this.taggingService.pageSettings({
      subsection: 'summary-atm'
    },'credit');
    this.taggingService.sendTeliumDataView();
  }

  /**
     * Tomar los valores que se tienen del cliente 
     */
    private getUserInfo(){
        const fromTddFlow : any = this.storage.getFromLocal('userdata');
            if(this.dataProxy.getCreditCardFullData() !== null) {
                this.userInfo = {
                    cardName : this.dataProxy.creditCardFullData.cardDesc,
                    balance: this.dataProxy.creditCardFullData.balance,
                    cardNumber: this.dataProxy.creditCardFullData.cardNumber
                }
            } else {
                this.userInfo = {
                    cardName : fromTddFlow.cardName,
                    balance: fromTddFlow.saldo,
                    cardNumber: fromTddFlow.cardNumber
                }
            }

            this.questions.forEach(element => {
                switch(element.id){
                    case "reason":
                        this.questionsAnswered.push({
                            question: 'MOTIVO DE LA ACLARACIÓN',
                            answer: element.value
                        }); 
                        break;
                    case "description":
                        this.questionsAnswered.push({
                            question: 'DESCRIPCIÓN',
                            answer: element.value
                        }); 
                        break;
                    case "howMuchRequest":
                        this.questionsAnswered.push({
                            question: 'SOLICITO EN CAJERO ATM',
                            answer: '$'+toInteger(element.value).toFixed(2)+' MXN'
                        }); 
                        break;
                    case "howMuchGet":
                        this.questionsAnswered.push({
                            question: 'MONTO RECIBIDO POR ATM',
                            answer: '$'+toInteger(element.value).toFixed(2)+' MXN'
                        }); 
                        break;
                    case "totalAmount":
                        this.questionsAnswered.push({
                            question: 'MONTO TOTAL DE LA ACLARACIÓN',
                            answer: '$'+toInteger(element.value).toFixed(2)+' MXN'
                        }); 
                        break;
                    case "hasCard":
                        this.questionsAnswered.push({
                            question: 'TARJETA EN SU PODER',
                            answer: element.value
                        }); 
                        break;
                    case "entitySelected":
                        const location = this.states.find(state => state.nombre === element.value);
                        this.questionsAnswered.push({
                            question: 'UBICACIÓN ACTUAL',
                            answer: location === undefined ? this.states[element.value-1].nombre : location.nombre
                        });
                        break;
                    case "interactiveAtm":
                      this.questionsAnswered.push({
                        question: 'INTERACTUÓ CON EL CAJERO ATM',
                        answer: element.value
                    }); 
                    break;
    
                }
            });
    
        }
    
  /**
   * Check if is fraud flow and then show block modal.
   * If else make request to token validator and clarification service
   * 
   *
   * @public
   * @memberof SummaryAtmComponent
   */
  public executeContinue() : void {
    let interactionCategory : string = this.storage.getFromLocal('viewQuestions')[1].value === 'TENGO UN RETIRO EN ATM QUE YO NO HICE' 
            ? 'atm_retiro_no_hice' : 'atm_no_dio_dinero_solicite' ;
    this.taggingService.sendTeliumDataLink({
      'interaction_action':'confirmacion',
      'interaction_label':'continuar',
      'interaction_category' : interactionCategory
    });
    this.tokenValidator(false);
  }


  /**
   * Check card and question answered to show overlay,
   * make claim or block card
   *
   * @private
   * @param {*} response
   * @param {string} flagCardReport
   * @memberof SummaryAtmComponent
   */
  private checkFraduStoleFlow(response: any,flagCardReport : string){
    if(flagCardReport === 'fraud'){
      response.data === 'true' ? this.openModal('block-one') : this.tokenValidator(false);  
    } else {
      response.data === 'true' ? this.checkCardNotBlocking(flagCardReport) : this.checkCardBlocking(flagCardReport);
    }
  }
  /**
   * If card is active check what happened
   *
   * @private
   * @memberof SummaryAtmComponent
   */
  private checkCardNotBlocking(flagCardReport : string) : void {
    if(flagCardReport === 'reported') {
      this.openModal('no-locked');
    } else if(flagCardReport === 'noReported'){
      this.openModal('block-two');
    }
  }
  /**
   * If card is blocked check what happened
   *
   * @private
   * @memberof SummaryAtmComponent
   */
  private checkCardBlocking(flagCardReport : string) : void {
    if(flagCardReport === 'reported') {
      this.tokenValidator(false)
    } else if(flagCardReport === 'noReported') {
      this.openModal('cant-block');
    }
  }
  /**
   *
   *Invoca al modelo de Multifolio para poder enviar todos los datos de cada moviemiento
  *y mostrar el resumen de lo que se va a calarar
  * @returns
  * @memberof SummaryComponent
  */
  private getMultifolioModelComplete() {
    let multifolio = [];
    let ix = 0;
    this.dataProxy.getDataSelected().forEach(element => {
      let objmultifolio = new MultifolioModelComplete();
      objmultifolio.TxrCodigoCom = element.txrCodigoCom;
      objmultifolio.TxrComercio = element.txrComercio;
      objmultifolio.TxrDivisa = element.txrDivisa;
      objmultifolio.TxrFecha = element.txrFecha;
      objmultifolio.TxrHrTxr = this.hourFormat(element.txrHrTxr);
      objmultifolio.TxrModoEntrada = element.txrModoEntrada;
      objmultifolio.TxrMonto = element.txrMonto;
      objmultifolio.TxrMovExtracto = element.txrMovExtracto;
      objmultifolio.TxrNumExtracto = element.txrNumExtracto;
      objmultifolio.TxrReferencia = element.txrReferencia;
      objmultifolio.TxrSucursalAp = element.txrSucursalAp;
      objmultifolio.TxrTipoFactura = element.txrTipoFactura;
      objmultifolio.TxrPAN = element.txrPAN; 
      multifolio.push(objmultifolio);
      ix++;
    });
    return multifolio;
  }
  /**
   * Formating hour.
   *
   * @private
   * @param {string} h
   * @returns {string}
   * @memberof SummaryComponent
   */
  private hourFormat(hour: string): string {
    if (hour === '' || hour === null || typeof hour === 'undefined') {
      return '00:00:00';
    }else{
      return hour.replace('.', ':');
    }
  }

  /**
   * Validate token and excecute clarification service 
   *
   * @private
   * @memberof SummaryAtmComponent
   */
  private tokenValidator(blockFlag : boolean) : void {
    if (this.dummyMode) {
      this.dataService.dummyRequest('assets/data/token.json')
        .subscribe((response) => {
          this.storage.saveInLocal('app-access',response.access_token);
           this.executeClarification();
        });
    } else {
      this.executeClarification();
    }
  }
  

  /**
   * Handle card block response Handle card block response.
   *
   * @private
   * @param {*} r
   * @memberof SummaryComponent
   */
  private handleCardBlockResponse(response: any) {
    if(response.status==='Ok'){
      let blocker: BlockModel = response;
      this.closeModal(() => {
        this.storage.saveInLocal('cardBlocked',blocker);
        this.continueBlock();
      });
      /* if (blocker.operationCancellation) {
        this.closeModal(() => {
          this.storage.saveInLocal('cardBlocked',blocker);
          this.continueBlock();
        });
      } else {
        this.closeModal(() => {
          this.openModal('cancelExecuteBlock');
        });
      } */
    }else{
      this.closeModal(() => {
        this.openModal('cancelExecuteBlock');
      });
    } 
  }

  /**
   * hace el llamado al endpoint para dar de alta la aclaracion
   * dependiendo el ambiente o el modo dummy
   * 
   * hace navegacion a la pantalla de respuesta
   *
   * @memberof SummaryAtmComponent
   */
  executeClarification(){
    this.openModal('loader');
    this.storage.saveInLocal('questionId',5);
    if(this.dummyMode){
      this.dataService.dummyRequest('assets/data/sm-response-tdd.json')
        .subscribe((response) => {
          this.closeModal(() => {
            this.storage.saveInLocal('SMResponse',response);
            this.router.navigate(['result-tdd']);
          });
        });
    }else{
      let SMObject = this.utils.generateSMObject(true);
      this.dataService.restRequest(
        '/clarifications/',
        'POST',
        JSON.stringify(SMObject),
        '',
        this.storage.getFromLocal('app-access'),
        this.session.get('idToken'))
      .subscribe((response) => {
        this.closeModal(() => {
          this.storage.saveInLocal('SMResponse',response);
          this.dataProxy.setDataSource([]);
          this.router.navigate(['result-tdd']);
        });
      }, (e) => {
        this.closeModal();
      });
    }
  }

  /**
   *
   * set the abs of the pay of movement clarificaded  
   * @param {*} cant
   * @returns
   * @memberof SummaryAtmComponent
   */
  removeSign(cant : any){
    return Math.abs(cant);
  }

  //Continuar Bloqueo
  public continueBlock(){
    this.router.navigate(['locked-tdd']);
  }
  /**
   * Open a modal instance.
   *
   * @private
   * @param {string} type
   * @memberof SummaryComponent
   */
  private openModal(type: string): void {
    this.navigationService.tapBack('');
    this.modalSubscription.push(this.modalService.onShown.subscribe((reason: string) => {
    }));
    let options: any = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true
    };
    if(type==='loader'){
      options.class = 'modal-loader';
      this.modalRef = this.modalService.show( LoaderComponent, options);
    }else{
      this.modalRef = this.modalService.show(AlertComponent,options);
      this.modalRef.content.type = type;
    }
    if (type === 'block-one' || type === 'block-two') {
      // Cancel navigation
      this.navigationService.tapBack('');
    }
  }
  /**
   * Close modal.
   *
   * @private
   * @param {*} [cb]
   * @memberof SummaryComponent
   */
  private closeModal(cb?: any): void {
    this.navigationService.tapBack('summary');
    document.getElementById('body').style.removeProperty('overflow');
    setTimeout(() => {
      this.modalRef.hide();
      if (cb) {
        cb();
      };
    }, 1000);
  }
  private cancelCardBlockWarning() : void {
    this.cancelCardBlockFlag = true;
    this.closeModal(() => {
      this.openModal('blockCancel');
    });
  }

  /**
   * Clean data selected and internal session.
   * Show history view.
   *
   * @memberof WelcomeComponent
   */
   showClarification() : void {
    this.dataProxy.setDataSource([]);
    this.dataProxy.internalSession = '';
    this.router.navigate(['history']);
  }
}
