import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Router , NavigationEnd } from '@angular/router';
import { PlatformLocation } from '@angular/common'
import { Subscription } from 'rxjs';
// Services
import { DataService } from './../../services/data.service';
import { DataProxyService } from './../../services/data-proxy.service';
import { NavigationService } from './../../services/navigation/navigation.service';
import { DataServiceManagerService } from './../../services/data-serviceManager.service';
import { SessionStorageService } from './../../services/tdd/session-storage.service';

// Constants
import { ConstantsService } from  './../../services/constants.service';

import { MoveModel,  UserModel, ExtractModel, QuestionsModel, LoaderModel,
  BlockModel, CreditCard, ResponseModel, AnswersQuestionsModel,
  MultifolioModel, MultifolioModelComplete } from './../../models';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { LoaderComponent, AlertComponent } from './../../partials/';

import * as _ from 'lodash';
import moment from 'moment';

/**
 * Vista del resumen de los movimientos a aclarar y las preguntas que contesto el cliente 
 * 
 * @export
 * @class SummaryComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-summary',
  template: require('./summary.component.html'),
  providers: [
    DataService,
    NavigationService,
    DataServiceManagerService
  ]
})
export class SummaryComponent implements OnInit {
  /**
   * contenedor del enviroment 
   *
   * @private
   * @type {*}
   * @memberof SummaryComponent
   */
  private enviroment: any;
  /**
   * ubicacion para usar el tapback 
   *
   * @private
   * @type {string}
   * @memberof SummaryComponent
   */
  private section: string = 'summary';
  /**
   * movimientos seleccionados 
   *
   * @private
   * @type {*}
   * @memberof SummaryComponent
   */
  private moves: any;
  /**
   * si se trata de una simulacion 
   *
   * @private
   * @memberof SummaryComponent
   */
  private dummyMode = false;
  /**
   * flag para ver si se pued ebloquear una tarjeta o no
   *
   * @private
   * @memberof SummaryComponent
   */
  private isBusy = false;
  /**
   * flag si esta corriendo un proceso 
   *
   * @private
   * @memberof SummaryComponent
   */
  private isLoading = false;
  /**
   * total de elementos 
   *
   * @private
   * @memberof SummaryComponent
   */
  private totalItems = 0;
  /**
   * subscripcion a eventos 
   *
   * @private
   * @type {Subscription}
   * @memberof SummaryComponent
   */
  private subscription: Subscription;
  /**
   * subscripcion a evento de bloqueo 
   *
   * @private
   * @type {Subscription[]}
   * @memberof SummaryComponent
   */
  private modalSubscription: Subscription[] = [];
  /**
   * se vacia el contenido del response 
   *
   * @private
   * @type {ResponseModel}
   * @memberof SummaryComponent
   */
  private responseDAO: ResponseModel;
  /**
   * Modal component 
   *
   * @private
   * @type {BsModalRef}
   * @memberof SummaryComponent
   */
  private modalRef: BsModalRef;
  /**
   * the questions model 
   *
   * @private
   * @type {QuestionsModel}
   * @memberof SummaryComponent
   */
  private questions: QuestionsModel;
  /**
   * spiner que se muestra mientras contesta un servicio 
   *
   * @private
   * @type {LoaderModel}
   * @memberof SummaryComponent
   */
  private loader: LoaderModel;
  /**
   * contiene el motivo del bloqueo 
   *
   * @private
   * @memberof SummaryComponent
   */
  private blockMotive = '';
  /**
   * modelo de movimientos 
   *
   * @private
   * @memberof SummaryComponent
   */
  private movements = [];
  /**
   * estatus de un boton antes de realizar una peticion 
   *
   * @private
   * @memberof SummaryComponent
   */
  private enablebtn = true;

  /**
   * Creates an instance of SummaryComponent.
   * @param {DataService} dataService
   * @param {DataProxyService} dataProxy
   * @param {BsModalService} modalService
   * @param {Router} router
   * @param {ConstantsService} constsService
   * @param {NavigationService} navigationService
   * @param {PlatformLocation} location
   * @memberof SummaryComponent
   */
  constructor(private dataService: DataService,
              public dataProxy: DataProxyService,
              private modalService: BsModalService,
              private router: Router,
              private constsService: ConstantsService,
              private navigationService: NavigationService,
              private location: PlatformLocation,
              private storage : SessionStorageService,
              private dataServiceManager: DataServiceManagerService
      ) {
    /**    
     * Search the enviroment
     */ 
    this.enviroment = _.find(constsService.ENVIROMENT, (item) => {
      return item.env === this.dataProxy.getEnviroment();
    });
    /**    
     * Bind methods  
     */ 
    this.executeCardBlock = this.executeCardBlock.bind(this);
    this.executeClarificationRequest = this.executeClarificationRequest.bind(this);
    /** 
     * Models    
     */ 
    this.loader = new LoaderModel();
    /**
     *  Get questions      
     */
    this.questions = this.dataProxy.getQuestions();
    if (!this.questions) {
      this.router.navigate(['questionnaire']);
    }
    location.onPopState(() => {
      this.router.navigate(['/questionnaire']);
  });
  }

  /**
   * Loads initial content :: User data and previously selected items.
   *
   * @memberof SummaryComponent
   */
  public ngOnInit() {
    //this.openModal('block-one');
    /**  
     * Navigation rules
     *  this.navigationService.setTitle('Resumen');   
     * */ 
    this.navigationService.tapBack(this.section);

    this.dataService.setUris(this.dataProxy.getEnviroment());
    this.dummyMode = this.dataProxy.getDummyMode();
    let selectedMoves: Array<MoveModel> = this.dataProxy.getDataSelected();
    if (selectedMoves) {
      this.totalItems = selectedMoves.length;
      this.moves = _(selectedMoves).groupBy('date').toPairs().value();
    }
    this.dataProxy.getQuestionsStatusService().subscribe((data) => this.handleFooterResponse(data));
    // Format the lostDate to 'DD/MMM/YYYY'
    this.questions.lostDate = this.formatDate(this.questions.lostDate) ;
    this.questions.lostDate === 'Invalid date' ? this.questions.lostDate='': this.questions.lostDate=this.questions.lostDate;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
    this.createMovements();
  }

  /**
   * Method that checks:
   *  * If the flow requires to lock cards.
   *
   * @memberof SummaryComponent
   */
  public async validateCardLock() {
    let valid = false;
    if (this.dataProxy.getDummyMode()) {
      /* DUMMY MODE */  
      this.executeLockFlow(true);
    } else {
      /*Consulta bloqueo en MODO NORMAL*/
      this.subscription = this.dataService
        .restRequest('/check-cards/', 'POST',
          { 
            multifolio: this.getMultifolioModelComplete(),
            cuestionario: this.getQuestionsCheckCards()
          }, 'user',
          this.dataProxy.getAccessToken())
        .subscribe((response) => {
          if (response.data === 'true') {
            valid = true;
          }
          this.executeLockFlow(valid);
        });
    }
  }

   /**
    * Method to create the movements to show.
    *
    * @memberof SummaryComponent
    */
   createMovements(){
    for (let moves of this.moves) {
      for(let move of moves[1]){
        this.movements.push(move);
      }
    }
   }
 
  /**
   * Listen the interaction of the user and validate the native session.
   *
   * @private
   * @param {Event} $event
   * @memberof SummaryComponent
   */
  @HostListener('window:scroll', ['$event'])
  private onWindowScroll($event: Event): void {
    this.navigationService.validateSession();
  }
  /**
   * Retrieve ParsedDate.
   *
   * @private
   * @param {string} dateToBeParsed
   * @returns
   * @memberof SummaryComponent
   */
  private retrieveParsedDate(dateToBeParsed: string) {
    moment.locale('es');
    return moment(dateToBeParsed, 'DD-MM-YYYY').format('dddd DD [de] MMMM, YYYY');
  }

  /**
   * Retrieve parsed date from position at.
   *
   * @private
   * @param {number} n
   * @returns
   * @memberof SummaryComponent
   */
  private retrieveParsedDateFromPositionAt(n: number) {
    return this.retrieveParsedDate(this.moves[n].toString());
  }

  /**
   * Transaction Handler: Handle footer response.
   *
   * @private
   * @param {string} v
   * @memberof SummaryComponent
   */
  private handleFooterResponse(v: string) {
    switch (v) {
      case 'validatedQuestionnaire':
        this.validatedQuestionnaire();
        break;
      case 'cancelBlocker':
        this.closeModal(() => {
          this.openModal('blockCancel');
        });
        break;
      case 'cancelExecuteBlocker':
        this.closeModal(() => {
          this.openModal('cancelExecuteBlock');
        });
        break;
      case 'executeCardBlock':
        this.closeModal(() => {
          this.checkToken(this.executeCardBlock);
        });
        break;
      default:
        break;
    }
  }

  /**
   * Validate the questionaire to open the block modal.
   *
   * @private
   * @memberof SummaryComponent
   */
  private validatedQuestionnaire(): void {
    this.enablebtn = false;
    this.validateCardLock();
  }

  /**
   * Excecute lock flow
   *
   * @private
   * @param {boolean} isValidLock
   * @memberof SummaryComponent
   */
  private executeLockFlow(isValidLock:boolean){
      if (this.getBlockCardOne() && !this.modalRef) {
        this.validateNoInteraction(isValidLock);
      } else if (this.getBlockCardTwo() && !this.modalRef) {
        this.validateNonReported(isValidLock);
      } else if (this.getBlockedCardReported() && !this.modalRef) {
        this.validateReported(isValidLock);
      } else if (!this.isBusy && this.router.url === '/summary') {
        this.checkToken(this.executeClarificationRequest);
      }

  }
  /**
   * Validate the questionaire to open the block modal in flow of no interaction with the commerce.
   *
   * @private
   * @param {*} goToLock
   * @memberof SummaryComponent
   */
  private validateNoInteraction(goToLock: any) {
    if (goToLock) {
      this.openModal('block-one');
    } else {
      this.storage.saveInLocal('checkBlock',goToLock);
      this.checkToken(this.executeClarificationRequest);
    }
  }

  /**
   * Validate the questionaire to open the block modal
   * in the flow non reported.
   *
   * @private
   * @param {*} goToLock
   * @memberof SummaryComponent
   */
  private validateNonReported(goToLock: any): void {
    if (goToLock) {
      this.openModal('block-two');
    } else {
      this.openModal('cant-block');
    }
  }

  /**
   * Validate the questionaire to open the block modal
   * in the flow the card was reported
   *
   * @private
   * @param {*} goToLock
   * @memberof SummaryComponent
   */
  private validateReported(goToLock: any): void {
    if (goToLock) {
      this.openModal('no-locked');
    } else {
      this.checkToken(this.executeClarificationRequest);
    }
  }

  /**
   * Method that checks:
   *  * If the user hasn't the card.
   *  * if the  card is not locked.
   *
   * @private
   * @returns
   * @memberof SummaryComponent
   */
  private getBlockedCardReported() {
    let block: boolean = false;
    if (Number(this.questions.hasCard) === 2 && this.questions.whatHappens.getKey() === 'S-01') {
      block = true;
    }
    return block;
  }


  /**
   * Method that checks:
   *  * If the user has the card
   *  * If the user do NOT interact with the commerce
   *
   * @private
   * @returns {boolean}
   * @memberof SummaryComponent
   */
  private getBlockCardOne(): boolean {
    let block: boolean = false;
    if (Number(this.questions.hasCard) === 1 && Number(this.questions.haveContact) === 2) {
      block = true;
    }
    return block;
  }

  /**
   * Method that checks:
   *  * If the user do not have the card.
   *  * What happen with your card: "Me la robaron o la extravié y no la he reportado".
   *
   * @private
   * @returns {boolean}
   * @memberof SummaryComponent
   */
  private getBlockCardTwo(): boolean {
    let block: boolean = false;
    if (Number(this.questions.hasCard) === 2 && this.questions.whatHappens.getKey() === 'S-03') {
      block = true;
    }
    return block;
  }

  /**
   * Execute card block.
   *
   * @private
   * @memberof SummaryComponent
   */
  private executeCardBlock() {
    if (!this.isBusy) {
      this.isBusy = true;
      let ccfd: CreditCard = this.dataProxy.getCreditCardFullData();
      if (this.questions.whatHappens.getKey() === 'S-03') {
        this.blockMotive = 'robo';
      } else {
        this.blockMotive = 'fraude';
      }
      let defaultMessage = 'Estamos bloqueando su tarjeta, un momento por favor.';
      this.loader.setMessage(defaultMessage);
      this.openModal('loader');
      if (this.dataProxy.getDummyMode() || this.dataProxy.getNoLock()) {
          /* DUMMY MODE */
          this.subscription = this.dataService.dummyRequest('assets/data/lock-card.json')
            .subscribe((response) => this.handleCardBlockResponse(response));
      } else {
        const endpoint = `/lock/lock_code/${this.blockMotive}/path/front`;
        const params = {
          multifolio: this.getMultifolioModel()
        };
        this.subscription = this.dataService
          .restRequest(endpoint, 'POST', '', 'cards', this.dataProxy.getAccessToken())
          .subscribe(
            (response) => {
              this.handleCardBlockResponse(response)
            }
              ,
            (error) => {
              this.questions.blocker.operationReposition = false;
              this.questions.blocker.operationCancellation = false;
              this.questions.blocker.operationCancellationMsg = 'no fue posible cancelar la tarjeta';
              this.questions.blocker.operationRepositionMsg = 'no fue posible reponer la tarjeta';
              this.isBusy = false;
              this.closeModal(() => {
                this.openModal('cancelExecuteBlock');
              });
            });
      }
    }
  }

  /**
   * Check token expiration time.
   *
   * @private
   * @param {*} cb
   * @memberof SummaryComponent
   */
  private checkToken(cb: any) {
    if (this.dummyMode) {
      this.dataService.dummyRequest('assets/data/token.json')
        .subscribe((response) => {
          this.dataProxy.setAccessToken(response.access_token);
          cb();
        });
    } else {
      cb();
    }
  }

  /**
   * Handle card block response Handle card block response.
   *
   * @private
   * @param {*} r
   * @memberof SummaryComponent
   */
  private handleCardBlockResponse(r: any) {
    if(r.status==='Ok'){
      let blocker: BlockModel = r;
      this.isBusy = false;
      if (blocker.operationCancellation) {
        this.closeModal(() => {
          this.dataProxy.questions.blocker = blocker;
          this.router.navigate(['locked']);
        });
      } else {
        this.closeModal(() => {
          this.openModal('cancelExecuteBlock');
        });
      }
    }else{
      this.closeModal(() => {
        this.openModal('cancelExecuteBlock');
      });
    } 
  }

  /**
   * Execute clarification request.
   *
   * @private
   * @returns {(boolean | undefined)}
   * @memberof SummaryComponent
   */
  private executeClarificationRequest(): boolean | undefined  {
    if (!this.isBusy) {
      this.isBusy = true;
    } else {
      this.isBusy = false;
      return false;
    }
    if (this.isBusy) {
      let serviceManagerObject = this.dataServiceManager.getServiceManager();
      let message = '';
      this.openModal('loader');
      if (this.dataProxy.getDummyMode()) {
        /* DUMMY MODE */
        this.subscription = this.dataService.dummyRequest('assets/data/sm-response.json')
          .subscribe((response) => this.handleServiceManagerRequest(response));
      } else {
        /*ALTA DE ACLARACION MODO NORMAL*/
        this.subscription = this.dataService
        .restRequest(
          '/clarifications/',
          'POST',
          JSON.stringify(serviceManagerObject),
          '',
          this.dataProxy.getAccessToken())
        .subscribe((response) => this.checkServiceManagerError(response), (e) => {
          this.closeModal();
        });
      }
    }
  }

  /**
   * Get type.
   *
   * @private
   * @returns {number}
   * @memberof SummaryComponent
   */
  private getType(): number {
    let type = null;
    type = Math.floor((Math.random() * 6) + 1);
    return type;
  }

  /**
   * Handle service manager request.
   *
   * @private
   * @param {*} r
   * @memberof SummaryComponent
   */
  private checkServiceManagerError(r: any): void {
    // Check if the reponse is an error
    if (this.checkInvalidSession(r)) {
      this.dataService.handleError('',{name: r.mensaje});
    } else if (this.checkInternalErrors(r)) {
      this.dataService.handleError('',{name: r.mensaje});
    } else {
      this.handleServiceManagerRequest(r);
    }
  }

  /**
   * Check if the session is invalid.
   *
   * @private
   * @param {*} r
   * @returns {boolean}
   * @memberof SummaryComponent
   */
  private checkInvalidSession(r: any): boolean {
    let error = false;
    if (!_.isUndefined(r.codigoMensaje)) {
      if (r.codigoMensaje === 'MSG-001') {
        error = true;
      }
    }
    return error;
  }

  /**
   * Check if there are some errors on the middleware.
   *
   * @private
   * @param {*} r
   * @returns {boolean}
   * @memberof SummaryComponent
   */
  private checkInternalErrors(r: any): boolean {
    let error = false;
    if (!_.isUndefined(r.status)) {
      if (r.status === 'Error') {
        error = true;
      }
    }
    return error;
  }

  /**
   * Validate if in the response exist a Error.
   *
   * @private
   * @param {*} r
   * @memberof SummaryComponent
   */
  private validateSMHandler(r: any){
    // Check if the reponse is an error
    if (!_.isUndefined(r.codigoMensaje)) {
      if (r.codigoMensaje === 'MSG-001') {
        this.dataService.handleError('', { name: r.mensaje });
      }
    }
    if(r.status==="Error"){
      this.dataService.handleError('', {name: r.status});
    }
  }

  /**
   * Handle service manager request.
   *
   * @private
   * @param {*} r
   * @memberof SummaryComponent
   */
  private handleServiceManagerRequest(r: any): void {
    this.validateSMHandler(r);
    let response: ResponseModel = this.dataProxy.getResponseDAO();
    let dateC = '';
    if (this.dataProxy.getDummyMode()) {
      response.setResult(301); // DUMMY
    }
    // Set the current date before the request
    const currentDate = moment().format('DD/MMM/YYYY HH:mm').toString();
    response.setCurrentDate(currentDate.split('.').join(''));
    if (r.Messages) {
      let nationalFolios = [];
      let internationalFolios = [];
      let payment = 'false';
      let date = moment();
      for (let i of r.Messages) {
        if (this.getPayment(i) === 'true') {
          payment = 'true';
        }
        const internationalFolio = this.getFolioDate(i, 'INTERNACIONAL');
        const nationalFolio = this.getFolioDate(i, 'NACIONAL');
        let temporaldate = this.greaterDateValue(internationalFolio, nationalFolio);
        date = this.greaterDateValue(date, temporaldate);


        let tempFoliosQ = this.getFolio(i, 'Internacional');
        let tempArrayF = this.extractFolio(tempFoliosQ);
        internationalFolios = this.concatArray(internationalFolios,tempArrayF);

        tempFoliosQ = this.getFolio(i, 'Nacional');
        tempArrayF = this.extractFolio(tempFoliosQ);
        nationalFolios = this.concatArray(nationalFolios,tempArrayF)
      }
      response.setInternationalFolio(internationalFolios);
      response.setNationalFolio(nationalFolios);
      response.setGreaterDate(date);
      response.setPayment(payment);
      response.setAmount(r.wvrinboxrn.monto * 1);
      response.setResult(301);
    }
    response.setDateCommitment(dateC);
    response.setTotalAmount(this.getTotalAmount());
    response.setName(this.dataProxy.creditCardFullData.userName);
    response.setVisaCard(r.wvrinboxrn.VisaCarta);
    response.setOldCard(this.dataProxy.creditCardFullData.cardNumber);
    this.dataProxy.setResponseDAO(response);
    response.setNewCard(this.dataProxy.questions.blocker.panReposition);
    this.closeModal(() => {

      if(r.wvrinboxrn.AptoDefinitivo === 'true'){
        this.storage.saveInLocal('tdcSmResponse',r);
        this.storage.saveInLocal('tdcSmResponseCleaned',response);
        this.router.navigate(['definitivePayment']);
      }else{
        this.router.navigate(['result']);
      }

    });
  }


  /**
  *method thath receives  strign with folios or null and returns an aaray of folios 
  *
  * @private
  * @param {*} tempFolio
  * @returns
  * @memberof LockedComponent
  */
  private extractFolio(tempFolio){
    let tempArray =[];
    if(tempFolio !== null){
      tempFolio.includes('|') ? tempArray = tempFolio.split('|') : tempArray.push(tempFolio);
    }
    return tempArray;
  }
  /**
  *Method that concats two arrays some like a.concat(b) butin the same scope
  *
  * @private
  * @param {*} origin
  * @param {*} toConcat
  * @returns
  * @memberof LockedComponent
  */
  private concatArray(origin,toConcat){
    toConcat.forEach(function(element) {
      origin.push(element);
    });
    return origin;
  }
  /**
   * Compare dates and return the most away.
   *
   * @private
   * @param {*} international
   * @param {*} national
   * @returns {*}
   * @memberof SummaryComponent
   */
  private greaterDateValue(international, national): any {
    let greater = null;
    if (international && national) {
      greater = (international.isAfter(national)) ? international : national ;
    } else if (international) {
      greater = international;
    } else if (national) {
      greater = national;
    }
    return greater;
  }

  /**
   * Get total amount.
   *
   * @private
   * @returns {number}
   * @memberof SummaryComponent
   */
  private getTotalAmount(): number {
    let totalSum = 0;
    _.each(this.dataProxy.getDataSelected(), (item: MoveModel) => {
      totalSum += item.amount;
    });
    return totalSum;
  }

  
  /**
   * Get payment.
   *
   * @private
   * @param {string[]} r
   * @returns {*}
   * @memberof SummaryComponent
   */
  private getPayment(r: string[], ): any {
    const find = _.find(r, (item: any) => {
      return item.match(new RegExp(`^Abono: `));
    });
    if (!_.isUndefined(find)) {
      return (find.split(': ')[1] !== 'undefined') ? find.split(': ')[1] : null;
    }
    return null;
  }

  /**
   * Get folio date.
   *
   * @private
   * @param {string[]} r
   * @param {string} v
   * @returns {*}
   * @memberof SummaryComponent
   */
  private getFolioDate(r: string[], v: string): any {
    const find = _.find(r, (item: any) => {
      return item.match(new RegExp(`^${v} next_breach ==> `));
    });
    if (!_.isUndefined(find)) {
      return moment(find.split('==> ')[1], 'DD/MM/YYYY HH::mm:ss');
    }
    return null;
  }

  /**
   * Get folio.
   *
   * @private
   * @param {string[]} r
   * @param {string} v
   * @returns {*}
   * @memberof SummaryComponent
   */
  private getFolio(r: string[], v: string): any {
    const find = _.find(r, (item: any) => {
      return item.match(new RegExp(`^${v}: `));
    });
    if (!_.isUndefined(find)) {
      if(find.split(': ')[1].includes(' ')){
        let a =0;
        let tempFolio='';
        let arrayFolio = find.split(': ')[1].split(' ');
        if(arrayFolio[0]!== 'undefined' && arrayFolio[1]!== 'undefined'){
          return `${arrayFolio[0]}|${arrayFolio[1]}`;
        }else{
          arrayFolio[0]!== 'undefined'? tempFolio= tempFolio+ arrayFolio[0] :  a+=1;
          arrayFolio[1]!== 'undefined'? tempFolio= tempFolio+ arrayFolio[1] :  a+=1;
          tempFolio === '' ? tempFolio =  null : a+=1;
          return tempFolio;
        }
      }
      return (find.split(': ')[1] !== 'undefined') ? find.split(': ')[1] : null;
    }
    return null;
  }







 

  /**
   * Get the Multifolio.
   *
   * @private
   * @returns
   * @memberof SummaryComponent
   */
  private getMultifolioModel() {
    let multifolio = [];
    let ix = 0;
    _.each(this.dataProxy.getDataSelected(), (item: MoveModel) => {
      let objmultifolio = new MultifolioModel();
      objmultifolio.AcctTrnId = item.id;
      objmultifolio.AcctStmtId = item.txrNumExtracto;
      /* objmultifolio.TxrCodigoCom = item.txrCodigoCom;
      objmultifolio.TxrComercio = item.txrComercio;
      objmultifolio.TxrDivisa = item.txrDivisa;
      objmultifolio.TxrFecha = item.txrFecha;
      //objmultifolio.TxrHrTxr = item.txrHrTxr;
      objmultifolio.TxrHrTxr = this.formatHour(item.txrHrTxr);
      objmultifolio.TxrModoEntrada = item.txrModoEntrada;
      objmultifolio.TxrMonto = item.txrMonto;
      objmultifolio.TxrMovExtracto = item.txrMovExtracto;
      objmultifolio.TxrNumExtracto = item.txrNumExtracto;
      objmultifolio.TxrReferencia = item.txrReferencia;
      //objmultifolio.TxrReferencia = item.txrMovExtracto;
      objmultifolio.TxrSucursalAp = item.txrSucursalAp;
      objmultifolio.TxrTipoFactura = item.txrTipoFactura;
      objmultifolio.TxrPAN = item.txrPAN; */
      multifolio.push(objmultifolio);
      ix++;
    });
    return multifolio;
  }


/**
 *
 *Invoca al modelo de Multifolio para poder enviar todos los datos de cada moviemiento
 *y mostrar el resumen de lo que se va a calarar
 * @returns
 * @memberof SummaryComponent
 */
private getMultifolioModelComplete() {
    let multifolio = [];
    let ix = 0;
    _.each(this.dataProxy.getDataSelected(), (item: MoveModel) => {
      let objmultifolio = new MultifolioModelComplete();
      objmultifolio.TxrCodigoCom = item.txrCodigoCom;
      objmultifolio.TxrComercio = item.txrComercio;
      objmultifolio.TxrDivisa = item.txrDivisa;
      objmultifolio.TxrFecha = item.txrFecha;
      objmultifolio.TxrHrTxr = this.formatHour(item.txrHrTxr);
      objmultifolio.TxrModoEntrada = item.txrModoEntrada;
      objmultifolio.TxrMonto = item.txrMonto;
      objmultifolio.TxrMovExtracto = item.txrMovExtracto;
      objmultifolio.TxrNumExtracto = item.txrNumExtracto;
      objmultifolio.TxrReferencia = item.txrReferencia;
      objmultifolio.TxrSucursalAp = item.txrSucursalAp;
      objmultifolio.TxrTipoFactura = item.txrTipoFactura;
      objmultifolio.TxrPAN = item.txrPAN; 
      multifolio.push(objmultifolio);
      ix++;
    });
    return multifolio;
  }

  /**
   * Get questions and answers.
   *
   * @private
   * @returns {*}
   * @memberof SummaryComponent
   */
  private getQuestionsAndAnswers(): any {
    let question = [];
    if (Number(this.questions.hasCard) === 1) {
      if( this.questions.motive.key !== 'IC-205' ){
        question = question.concat(this.getQuestionsHaveCard());
      }
    } else {
      let objquestion = new AnswersQuestionsModel();
      objquestion.Preguntas = '¿Reporto usted su tarjeta de crédito o débito como robada o extraviada a Banco Santander?';
      if(this.questions.whatHappens.getTitle() === 'La reporté como robada o extraviada.'){
        objquestion.Respuestas = 'SI';
        question.push(objquestion);
      }else if( this.questions.whatHappens.getTitle() === 'Me la robaron o la extravié y no la he reportado.' ){
        objquestion.Respuestas = 'NO';
        question.push(objquestion);
      }
    }

    return question;
  }

  /**
   *
   * Get the firts answer of questionary and then show 
   * the next steps for each question
   *
   * @private
   * @returns {*}
   * @memberof SummaryComponent
   */
  private getQuestionsCheckCards(): any {
    const hasCard : string = this.questions.hasCard == 1 ? 'SI' : 'NO';
    let cardHappens : string = this.questions.whatHappens.getTitle();
    let cardHappensLabel : string = '¿Qué sucedió con su tarjeta?';
    if(this.questions.haveContact == '2') {
      cardHappens = 'NO';
      cardHappensLabel = '¿Interactuó con el comercio durante la compra?';
    }
    return [
      {
        Preguntas: '¿Tiene la tarjeta en su poder?',
        Respuestas: hasCard
      },
      {
        Preguntas: cardHappensLabel,
        Respuestas: cardHappens
      }
    ]
  }

  /**
   * Get questions and answers when the client have card.
   *
   * @private
   * @returns {*}
   * @memberof SummaryComponent
   */
  private getQuestionsHaveCard():any{
    let question = [
      {Preguntas:'¿Tiene la tarjeta en su poder?',Respuestas:'SI'}
    ];
    let objquestion = new AnswersQuestionsModel();
    objquestion.Preguntas = '¿Interactuó con el comercio durante la compra?';
    if (this.questions.haveContact === '1') {
      objquestion.Respuestas = 'SI';
    } else {
      objquestion.Respuestas = 'NO';
    }
    question.push(objquestion);
    question = question.concat(this.getAdditionalQuestionary());
    return question;
  }

  /**
   * Get additional questions when.
   *
   * @private
   * @returns {*}
   * @memberof SummaryComponent
   */
  private getAdditionalQuestionary():any{
    let questionary = [
      {Preguntas:'Cargo duplicado',Respuestas:'NO'},
      {Preguntas:'Monto alterado',Respuestas:'NO'},
      {Preguntas:'Cargos adicionales',Respuestas:'NO'},
      {Preguntas:'Servicios no proporcionados',Respuestas:'NO'},
      {Preguntas:'Mercancia defectuosa',Respuestas:'NO'},
      {Preguntas:'Pago por otro medio',Respuestas:'NO'},
      {Preguntas:'Cancelación de servicio',Respuestas:'NO'},
      {Preguntas:'Otro',Respuestas:'NO'}
    ];
    switch(this.questions.motive.key) { 
      case 'IC-201': { 
        questionary[0].Respuestas='SI'; 
        break; 
      } 
      case 'IC-202': { 
        questionary[1].Respuestas='SI'; 
        break; 
      } 
      case 'IC-203': { 
        questionary[2].Respuestas='SI';  
        break; 
      } 
      case 'IC-204': { 
        questionary[3].Respuestas='SI'; 
        break; 
      }  
      case 'IC-206': { 
        questionary[5].Respuestas='SI'; 
        break; 
      } 
      case 'IC-207': { 
        questionary[6].Respuestas='SI'; 
        break; 
      }
      default: { 
        break; 
      } 
   } 
   return questionary;
  }

  /**
   * Get additional questions when
   *
   * @returns any
  
  getAdditionalQuestions():any{
    let question = [];
    
    if (this.questions.motive !== this.questions.defaultMotive) {
      let objquestion = new AnswersQuestionsModel();
      objquestion.Preguntas = '¿Cuál es el motivo de la aclaración?';
      objquestion.Respuestas = this.questions.motive.title;
      question.push(objquestion);
    }

    if (this.questions.additionalComments.length > 0) {
      if (this.questions.motive.key === 'IC-201' ||
        this.questions.motive.key === 'IC-206' ||
        this.questions.motive.key === 'IC-207' ||
        this.questions.motive.key === 'IC-202') {

        let objquestion = new AnswersQuestionsModel();
        objquestion.Preguntas = 'Descripción';
        objquestion.Respuestas = this.questions.additionalComments;
        question.push(objquestion);

      } else if (this.questions.motive.key === 'IC-203') {
        let objquestion = new AnswersQuestionsModel();
        objquestion.Preguntas = 'Datos de consumo';
        objquestion.Respuestas = this.questions.additionalComments;
        question.push(objquestion);

      } else if (this.questions.motive.key === 'IC-205' ||
        this.questions.motive.key === 'IC-204') {

        let objquestion = new AnswersQuestionsModel();
        objquestion.Preguntas = 'Comentarios';
        objquestion.Respuestas = this.questions.additionalComments;
        question.push(objquestion);
      }
    }
    return question;
  }
 */

  /**
   * Format Date with momentJS.
   *
   * @private
   * @param {string} v
   * @returns {string}
   * @memberof SummaryComponent
   */
  private formatDate(v: string): string {
    moment.locale('es');
    return moment(v).format('DD/MMM/YYYY').toString().replace(/\./g, '');
  }

  /**
   * Format hour.
   *
   * @private
   * @param {string} h
   * @returns {string}
   * @memberof SummaryComponent
   */
  private formatHour(h: string): string {
    if (h === '' || h === null || typeof h === 'undefined') {
      return '00:00:00';
    }else{
      return h.replace('.', ':');
    }
  }

  /**
   * Open a modal instance.
   *
   * @private
   * @param {string} type
   * @memberof SummaryComponent
   */
  private openModal(type: string): void {
    this.navigationService.tapBack('');
    this.modalSubscription.push(this.modalService.onShown.subscribe((reason: string) => {
    }));
    let options: any = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true
    };
    if(type==='loader'){
      options.class = 'modal-loader';
      this.modalRef = this.modalService.show( LoaderComponent, options);
    }else{
      this.modalRef = this.modalService.show(AlertComponent,options);
      this.modalRef.content.type = type;
    }
    if (type === 'block-one' || type === 'block-two') {
      // Cancel navigation
      this.navigationService.tapBack('');
    }
  }

  /**
   * Close modal.
   *
   * @private
   * @param {*} [cb]
   * @memberof SummaryComponent
   */
  private closeModal(cb?: any): void {
    this.navigationService.tapBack(this.section);
    document.getElementById('body').style.removeProperty('overflow');
    setTimeout(() => {
      this.modalRef.hide();
      if (cb) {
        cb();
      };
    }, 1000);
  }
  /**
   * Clean data selected and internal session.
   * Show history view.
   *
   * @memberof WelcomeComponent
   */
  showClarification() : void {
    this.dataProxy.setDataSource([]);
    this.dataProxy.internalSession = '';
    this.router.navigate(['history']);
  }

}
