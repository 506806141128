import { Injectable,Inject } from '@angular/core';
import { DataService } from './data.service';
import { DataProxyService } from './data-proxy.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';


/**
 * the tagging service 
 * @export
 * @class TaggingService
 */
@Injectable()
export class StatesService {

    constructor( private dataService: DataService,
        public dataProxy: DataProxyService){

    }

    /**
     * Get the states from a JSON file.
     *
     * @private
     * @memberof PreloaderComponent
     */
    private getDummyStates(): any {
        return  this.dataService.dummyRequest('assets/data/states.json');        
    }

      /**
   * Process States.
   *
   * @private
   * @param {*} v
   * @memberof PreloaderComponent
   */

    /**
      * Get states catalog, if the size if the response is zero, get from the dummy file.
      *
      * @private
      * @memberof PreloaderComponent
      */
    public getStatesCatalog(dummyMode:any): Observable<any> {
        if (dummyMode) {
            return this.getDummyStates();
        } else {
            try{
          return this.dataService.restRequest(
            '/states',
            'GET',
            '',
            'catalogs',
            this.dataProxy.getAccessToken()
          )
        }catch(error){
            return this.getDummyStates();
        }
        
    }
}

      /**
   * Process States.
   *
   * @private
   * @param {*} v
   * @memberof PreloaderComponent
   */
  public processStates(v: any): any {
    let idxstate= _.findIndex(v, ['clave' , 9]);
    if (idxstate !== -1) v[idxstate].nombre = 'Ciudad de México';
    idxstate= _.findIndex(v, ['clave' , 12]);
    if (idxstate !== -1)  v[idxstate].nombre = 'Guerrero';
    idxstate= _.findIndex(v, ['clave' , 15]);
    if (idxstate !== -1)  v[idxstate].nombre = 'Estado de México';
    return v;
  }


}