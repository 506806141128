import moment from 'moment';

/**
 * History model
 *
 * @export
 * @class HistoryModel
 */
export class HistoryModel {
  /**
   * identificador de incidente 
   *
   * @type {string}
   * @memberof HistoryModel
   */
  public IncidentID: string;
  /**
   * Categoria del folio
   *
   * @type {string}
   * @memberof HistoryModel
   */
  public Category: string;
  /**
   * sub categoria 
   *
   * @type {string}
   * @memberof HistoryModel
   */
  public Subcategory: string;
  /**
   * fecha de alta 
   *
   * @type {string}
   * @memberof HistoryModel
   */
  public OpenTime: string;
  /**
   * fecha compromiso 
   *
   * @type {string}
   * @memberof HistoryModel
   */
  public FechaCompromiso: string;
  /**
   * estatus abierto 
   *
   * @type {string}
   * @memberof HistoryModel
   */
  public Open: string;
  /**
   * codigo de resolucion 
   *
   * @type {string}
   * @memberof HistoryModel
   */
  public ResolutionCode: string;
  /**
   * fecha de cerrado 
   *
   * @type {string}
   * @memberof HistoryModel
   */
  public CoseTime: string;
  /**
   * codigo de causa 
   *
   * @type {string}
   * @memberof HistoryModel
   */
  public CauseCode: string;
  /**
   * the number 3 
   *
   * @type {string}
   * @memberof HistoryModel
   */
  public Numero3: string;
  /**
   * fecha de inicio 
   *
   * @type {string}
   * @memberof HistoryModel
   */
  public IndexedDate: string;
  /**
   * the codeClass
   *
   * @memberof HistoryModel
   */
  public codeClass = 'orange';
  /**
   * the status default 
   *
   * @memberof HistoryModel
   */
  public status = 'En proceso';

  /**
   * Format values
   *
   * @memberof HistoryModel
   */
  public formatValues() {
    this.OpenTime = this.applyDateFormat(this.OpenTime);
    this.FechaCompromiso = this.applyDateFormat(this.FechaCompromiso);
    this.CoseTime = this.applyDateFormat(this.CoseTime);
    this.IndexedDate = moment(this.OpenTime, 'DD/MM/YYYY').format('YYYY-MM').toString();
    // TODO: Format the category
    // this.Category = this.formatCategory(this.Category);

    if (this.Open.toLowerCase() === 'closed') {
      this.status = 'Cerrado';
      if ( this.CauseCode !== null ) {
        this.getResolutionColor();
      }
    }
  }

  /**
   * Format category
   *
   * @param {string} category
   * @returns {string}
   * @memberof HistoryModel
   */
  public formatCategory(category: string): string {
    switch(category) {
      case 'TARJETA DE CREDITO TARJETAHABIENTES':
        category = 'TARJETA DE CREDITO';
        break;
      default:
    }
    return category;
  }

  /**
   * Get the resolution color of the movement
   *
   * @memberof HistoryModel
   */
  public getResolutionColor(): void {
    if (
        this.CauseCode.toLowerCase().lastIndexOf('favor del banco') > -1
        ||
        this.CauseCode.toLowerCase().lastIndexOf('improcedente') > -1
    ) {
      this.codeClass = 'grey';
    } else if (
        this.CauseCode.toLowerCase().lastIndexOf('favor del cliente') > -1
        ||
        this.CauseCode.toLowerCase().lastIndexOf('atendido') > -1
        ||
        this.CauseCode.toLowerCase().lastIndexOf('procedente') > -1
      ) {
      this.codeClass = 'softgreen';
    } else {
      this.codeClass = 'black';
    }
    if (this.CauseCode.toLowerCase() === 'declinado') {
      this.status = 'DECLINADO';
      this.codeClass = 'red';
    }
  }

  /**
   * Apply date format
   *
   * @param {string} v
   * @returns {string}
   * @memberof HistoryModel
   */
  public applyDateFormat(v: string): string {
    let res = '';
    if (v) {
      let val: string = v.substr(0, 10);
      res =  moment(val, 'YYYY-MM-DD')
        .format('DD/MM/YYYY')
        .toString();
    }
    return res;
  }
}
