import { Component } from '@angular/core';

/**
 *
 *
 * @export
 * @class NoConnectionComponent
 */
@Component({
  selector: 'app-no-connection',
  template:require('./no-connection.component.html')
})
export class NoConnectionComponent {

}
