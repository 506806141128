/**
 * contains the elements on the storage service
 * 
 */
export const STORAGE_CONST = {
    respositionType: 'respositionType',
    cardRepositionResponse: 'cardRepositionResponse',
    tdcSmResponse: 'tdcSmResponse',
    tdcSmResponseCleaned: 'tdcSmResponseCleaned',
    checkBlock: 'checkBlock' 
};