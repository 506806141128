/**
 * Response object used in the summary component
 *
 * @export
 * @class ResponseModel
 */
export class ResponseModel {
  /**
   * maximo de numeros a mostrar 
   *
   * @memberof ResponseModel
   */
  public CARD_SHOW_LIMIT = 4;
  /**
   * the result status 
   *
   * @memberof ResponseModel
   */
  public result = 501;
  /**
   * folio de alaracion (H)
   *
   * @type {string}
   * @memberof ResponseModel
   */
  public folio: string;
  /**
   * folio de aclaracion (I)
   *
   * @type {string}
   * @memberof ResponseModel
   */
  public iFolio: string;
  /**
   * tarjeta bloqueada 
   *
   * @type {string}
   * @memberof ResponseModel
   */
  public oldCard: string;
  /**
   * resposicion de la tarjeta 
   *
   * @type {string}
   * @memberof ResponseModel
   */
  public newCard: string;
  /**
   * monto total de la aclaracion 
   *
   * @type {number}
   * @memberof ResponseModel
   */
  public totalAmount: number;
  /**
   * fecha del comite 
   *
   * @type {string}
   * @memberof ResponseModel
   */
  public dateCommitment: string;
  /**
   * flag si se requiere documentacion adicional 
   *
   * @memberof ResponseModel
   */
  public needsLetter = false;
  /**
   * flag si se ah cancelado la compra 
   *
   * @memberof ResponseModel
   */
  public cancelConfirmationNeeded = false;
  /**
   * nombre de usuario 
   *
   * @type {string}
   * @memberof ResponseModel
   */
  public name: string;
  /**
   * abonado 
   *
   * @type {string}
   * @memberof ResponseModel
   */
  public payment: string;
  /**
   * fecha mas alta 
   *
   * @type {*}
   * @memberof ResponseModel
   */
  public greater: any;
  /**
   * folio internacional 
   *
   * @type {string}
   * @memberof ResponseModel
   */
  public internationalFolio: string;
  /**
   * folio nacional 
   *
   * @type {string}
   * @memberof ResponseModel
   */
  public nationalFolio: string;
  /**
   * monto 
   *
   * @type {number}
   * @memberof ResponseModel
   */
  public amount: number;
  /**
   * fecha de alta 
   *
   * @type {string}
   * @memberof ResponseModel
   */
  public currentDate: string;
  /**
   * documento visa 
   *
   * @type {string}
   * @memberof ResponseModel
   */
  public visaCard: string;

  /**
   * Set date commitment
   *
   * @param {string} v
   * @memberof ResponseModel
   */
  public setVisaCard(v: string): void {
    this.visaCard = v;
  }

  /**
   * Get date of the commitment
   *
   * @returns {string}
   * @memberof ResponseModel
   */
  public getVisaCard(): string {
    return this.visaCard;
  }

  /**
   * Set the date of the commitment
   *
   * @param {string} v
   * @memberof ResponseModel
   */
  public setDateCommitment(v: string): void {
    this.dateCommitment = v;
  }

  /**
   * Get the date commitment
   *
   * @returns {string}
   * @memberof ResponseModel
   */
  public getDateCommitment(): string {
    return this.dateCommitment;
  }

  /**
   * Set the old card
   *
   * @param {string} v
   * @memberof ResponseModel
   */
  public setOldCard(v: string): void {
    const l: number = v.length;
    this.oldCard = v.substring(l - this.CARD_SHOW_LIMIT, l);
  }

  /**
   * Set payment
   *
   * @param {string} v
   * @memberof ResponseModel
   */
  public setPayment(v: string): void {
    this.payment = v;
  }

  /**
   * Set the greater date
   *
   * @param {*} v
   * @memberof ResponseModel
   */
  public setGreaterDate(v: any): void {
    let value = null;
    if (v && v !== 'Invalid date') {
      value = v.format('DD [de] MMMM [de] YYYY');
    }
    this.greater = value;
  }

  /**
   * Get the greater date
   *
   * @returns {*}
   * @memberof ResponseModel
   */
  public getGreaterDate(): any {
    return this.greater;
  }

  /**
   * Set the international folio
   *
   * @param {*} v
   * @memberof ResponseModel
   */
  public setInternationalFolio(v: any): void {
    this.internationalFolio = v;
  }

  /**
   * Get the international folio
   *
   * @returns {string}
   * @memberof ResponseModel
   */
  public getInternationalFolio(): string {
    return this.internationalFolio;
  }

  /**
   * Set the national folio
   *
   * @param {*} v
   * @memberof ResponseModel
   */
  public setNationalFolio(v: any): void {
    this.nationalFolio = v;
  }

  /**
   * Get the national folio
   *
   * @returns {string}
   * @memberof ResponseModel
   */
  public getNationalFolio(): string {
    return this.nationalFolio;
  }

  /**
   * Set the amount
   *
   * @param {number} v
   * @memberof ResponseModel
   */
  public setAmount(v: number): void {
    this.amount = v;
  }

  /**
   * Set the current date
   *
   * @param {string} v
   * @memberof ResponseModel
   */
  public setCurrentDate(v: string): void {
    this.currentDate = v;
  }

  /**
   * Set the new card
   *
   * @param {string} v
   * @memberof ResponseModel
   */
  public setNewCard(v: string): void {
    const l: number = v.length;
    this.newCard = v.substring(l - this.CARD_SHOW_LIMIT, l);
  }

  /**
   * Set the name
   *
   * @param {string} v
   * @memberof ResponseModel
   */
  public setName(v: string) {
    const aux = v.split('/');
    this.name = aux[0];
  }

  /**
   * Set the result
   *
   * @param {number} v
   * @memberof ResponseModel
   */
  public setResult(v: number): void {
    this.result = v;
  }

  /**
   * Get the result
   *
   * @returns {number}
   * @memberof ResponseModel
   */
  public getResult(): number {
    return this.result;
  }

  /**
   * Get Total Amount
   *
   * @param {number} v
   * @memberof ResponseModel
   */
  public setTotalAmount(v: number): void {
    this.totalAmount = v;
  }

  /**
   * Get Total Amount
   *
   * @returns {number}
   * @memberof ResponseModel
   */
  public getTotalAmount(): number {
    return this.totalAmount;
  }

  /* Codes
    301, // Clarification approved
    401, // Clarification denied
    501, // Clarification queued
    601  // User request for later
  */
}
