import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertsTddService } from '../../../services/tdd/alerts-tdd.service';


/**
 *
 * component use for create alerts button 
 * @export
 * @class AlertsTddComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-alerts-tdd',
  template: require('./alerts-tdd.component.html')
})
export class AlertsTddComponent implements OnInit {
/**
 *Creates an instance of AlertsTddComponent.
 * @memberof AlertsTddComponent
 */


  visible = false;
  @Input() value_header = "";
  message: number;
  subscription: Subscription;
  titles = [
            'Bloqueo de Tarjeta',
            '¡Atención!'
  ];
  instructions = [
                  'Para evitar que le sigan haciendo cargos y continuar con la aclaración es necesario llevar a cabo un <span class="bold">Bloqueo Total</span> de su tarjeta.<br /><br />Una vez realizado este proceso le haremos llegar el nuevo plástico al domicilio que ha registrado en el banco.<br /><br /><span class="bold">Esta acción NO puede revertirse.<br>Tendrá un costo establecido en su contrato.</span>',
                  'Lo sentimos, el sistema no reconoce que su tarjeta ha sido reportada como robada o extraviada, para evitar que le sigan haciendo cargos favor de  volver a contestar el cuestionario con la opción “Me la robaron o la extravié y no la he reportado” o comuníquese a la <span class="bold">Super<span class="bold mRed">Línea</span></span><br />Desde CDMX y el interior de la República<br /><span class="bold">55 5169 4300 </span><br />para levantar su aclaración.',
                  'Al no realizar el bloqueo de la tarjeta de débito, queda bajo su responsabilidad seguir recibiendo cargos.',
                  'Por el momento no es posible realizar su petición.<br />Por favor inténtelo más tarde o comuníquese a la <span class="bold">Super<span class="bold mRed">Línea</span></span><br />Desde CDMX y el interior de la República<br /><span class="bold">55 5169 4300 </span><br />para levantar su aclaración.',
                  'Para levantar su aclaración, favor de comunicarse a la <span class="bold">Super<span class="bold mRed">Línea</span></span><br />Desde CDMX y el interior de la República<br /><span class="bold">55 5169 4300 </span>'
                ];
  value_step = 1;
  texto = '';
  title = '';

/**
 *Creates an instance of AlertsTddComponent.
 * @param {AlertsMain} alertsMain
 * @memberof AlertsTddComponent
 */
constructor(private alertsMain: AlertsTddService) {
  this.subscription = this.alertsMain.getMessage()
      .subscribe(
        message => {
          //this.value_label_step = message.response.title;
          this.setAlert(message.response.number, message.response.title, message.response.visibility);
        }
    )

}
/**
 *
 * Init method
 * @memberof AlertsTddComponent
 */
ngOnInit() {
}



  /**
   *
   * Recibir Mensaje Tip Alerta
   * @param {number} step
   * @param {number} title
   * @param {boolean} visibility
   * @memberof AlertsTddComponent
   */
  setAlert(step: number, title: number, visibility: boolean){
    
      if(title >= 0){
        this.title = this.titles[title]
      }
      this.texto = this.instructions[step];
      this.visible = visibility;
      this.value_step = step;

  }

  /**
   *
   * create  a Alert Button with message 
   * @param {number} step
   * @param {boolean} visibility
   * @param {number} title
   * @memberof AlertsTddComponent
   */
  alertButton(step: number, visibility: boolean, title: number){
    this.alertsMain.sendMessage(step, visibility, title);
    this.title = '';
  }

}
