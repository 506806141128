//Core elements
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
//Taggeo
import { TaggingService } from '../../../services/tagging.service';
//Services
import {SessionStorageService} from '../../../services/tdd/session-storage.service';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { DataService } from '../../../services/data.service';
import {UtilsTddService} from '../../../services/tdd/utils-tdd.service';
//Components
import { LoaderComponent, AlertComponent } from '../../../partials/';
/**
 *
 *
 * @export
 * @class LockedTddComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-locked-tdd',
  template: require('./locked-tdd.component.html'),
  providers: [
    NavigationService,
    DataService
  ]
})
export class LockedTddComponent implements OnInit {
   /**
   * Show user data
   *
   * @memberof SummaryTddComponent
   */
  public userdata = this.storage.getFromLocal('userdata');
  /**
   * Lock card response
   *
   * @memberof LockedTddComponent
   */
  public blocker : any = this.storage.getFromLocal('cardBlocked');
  /**
   * The new pan getter from block card service
   *
   * @type {string}
   * @memberof LockedTddComponent
   */
  public newPan : string;
  //TOFIX: remove temporal flag when reposition is developed
  public newPanLenght : boolean;
  /**
   * Get card blocked.
   *
   * @memberof LockedTddComponent
   */
  public oldCard = this.storage.getFromLocal('pan');
  /**
   * subscripcion a evento de bloqueo 
   *
   * @private
   * @type {Subscription[]}
   * @memberof SummaryComponent
   */
  private modalSubscription: Subscription[] = [];
  /**
   * Modal component 
   *
   * @private
   * @type {BsModalRef}
   * @memberof SummaryComponent
   */
  private modalRef: BsModalRef;
  /**
   * Indicate the dummy mode status
   *
   * @private
   * @type {string}
   * @memberof SummaryTddComponent
   */
  private dummyMode : string = this.storage.getFromLocal('dummy');
  /**
   * Indicate the reposition type to show it on result view
   *
   * @private
   * @memberof LockedComponent
   */
  private repositionType = '';
  /**
   * Enable or disable continue button according flow
   *
   * @private
   * @type {boolean}
   * @memberof LockedTddComponent
   */
  private enableContinueButton : boolean = true;
  /**
  *Creates an instance of LockedTddComponent.
  * @param {TaggingService} taggingService
  * @param {SessionStorageService} storage
  * @param {NavigationService} navigationService
  * @param {BsModalService} modalService
  * @param {DataService} dataService
  * @param {Router} router
  * @param {UtilsTddService} utils
  * @memberof LockedTddComponent
  */
  constructor(
    private taggingService: TaggingService,
    private storage: SessionStorageService,
    private navigationService: NavigationService,
    private modalService: BsModalService,
    private dataService: DataService,
    private router: Router,
    private utils: UtilsTddService
  ) { }
  /**
   * After card replenish, make clarification
   *
   * @memberof LockedTddComponent
   */
  public registerClaim() : void {
    let interactionAction : string = 
    this.repositionType === "1" ? "reposicion_inmediata" : "reposicion_personalizada";
    this.taggingService.sendTeliumDataLink(
      {
        'interaction_action':interactionAction,
        'interaction_label':'continuar'
      },
      this.storage.getFromLocal('viewQuestions')
    );
    if(!this.blocker.operationReposition && this.blocker.operationExpressReposition && this.repositionType === '2') {
      //Replace card
      this.tokenValidator(true);
    } else {
      //Make clarification
      this.tokenValidator(false);
    }
  }
  /**
   * Validate token and excecute clarification service 
   *
   * @private
   * @memberof SummaryTddComponent
   */
  private tokenValidator(replaceCard : boolean) : void {
    if (this.dummyMode) {
      this.dataService.dummyRequest('assets/data/token.json')
        .subscribe((response) => {
          this.storage.saveInLocal('app-access',response.access_token);
          this.checkRepositionSelected(replaceCard);
        });
    } else {
      this.checkRepositionSelected(replaceCard);
    }
  }
  private checkRepositionSelected(replaceCard : boolean) : void {
    replaceCard ? this.executeCardRepositionRequest() : this.executeClarification(false);
  }
  /**
   * Execute card reposition request
   *
   * @private
   * @memberof LockedComponent
   */
  private executeCardRepositionRequest(): void {
    this.openModal('loader');
    if (this.dummyMode) {
      /* DUMMY MODE */
      this.dataService.dummyRequest('assets/data/reposition.json')
        .subscribe((response) => {
          this.storage.saveInLocal('cardRepositionResponse',response);
          this.executeClarification(true);
        });
    } else {
      /*ALTA DE REPOSICION MODO NORMAL*/
      this.dataService
        .restRequest(
          '/repositions',
          'GET',
          {},
          'cards',
          this.storage.getFromLocal('app-access'),
          this.storage.getFromLocal('client'))
        .subscribe((response) => {
          this.storage.saveInLocal('cardRepositionResponse',response);
          this.executeClarification(true);
        });
    }
  }
  
  /**
   * Make clarification
   *
   * @private
   * @memberof LockedTddComponent
   */
  private executeClarification(withoutReposition : boolean) : void {
    if(!withoutReposition){this.openModal('loader');}
    if(this.dummyMode){
      this.dataService.dummyRequest('assets/data/sm-response-tdd.json')
        .subscribe((response) => {
          this.closeModal(() => {
            this.storage.saveInLocal('SMResponse',response);
            if(response.wvrinboxrn.AptoDefinitivo === 'true'){
              this.storage.saveInLocal('tdcSmResponse',response);
              const tdcSmResponseCleaned = this.utils.handleServiceManagerRequest(response);
              this.storage.saveInLocal('tdcSmResponseCleaned',tdcSmResponseCleaned);
              this.router.navigate(['definitivePayment-tdd']);
            }else{
              this.router.navigate(['result-tdd']);
            }
          });
        });
    }else{
      let SMObject = this.utils.generateSMObject();
      this.dataService.restRequest(
        '/clarifications/',
        'POST',
        JSON.stringify(SMObject),
        '',
        this.storage.getFromLocal('app-access'),
        this.storage.getFromLocal('client'))
      .subscribe((response) => {
        this.closeModal(() => {
          this.storage.saveInLocal('SMResponse',response);
          if(response.wvrinboxrn.AptoDefinitivo === 'true'){
            this.storage.saveInLocal('tdcSmResponse',response);
            const tdcSmResponseCleaned = this.utils.handleServiceManagerRequest(response)
            this.storage.saveInLocal('tdcSmResponseCleaned',tdcSmResponseCleaned);
            this.router.navigate(['definitivePayment-tdd']);
          }else{
            this.router.navigate(['result-tdd']);
          }
        });
      });
    }
  }
  /**
   * Open a modal instance.
   *
   * @private
   * @param {string} type
   * @memberof SummaryComponent
   */
  private openModal(type: string): void {
    this.navigationService.tapBack('');
    this.modalSubscription.push(this.modalService.onShown.subscribe((reason: string) => {
    }));
    let options: any = {
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: true
    };
    if(type==='loader'){
      options.class = 'modal-loader';
      this.modalRef = this.modalService.show( LoaderComponent, options);
    }else{
      this.modalRef = this.modalService.show(AlertComponent,options);
      this.modalRef.content.type = type;
    }
    if (type === 'block-one' || type === 'block-two') {
      // Cancel navigation
      this.navigationService.tapBack('');
    }
    
  }
  /**
   * Close modal.
   *
   * @private
   * @param {*} [cb]
   * @memberof SummaryComponent
   */
  private closeModal(cb?: any): void {
    this.navigationService.tapBack('summary');
    document.getElementById('body').style.removeProperty('overflow');
    setTimeout(() => {
      this.modalRef.hide();
      if (cb) {
        cb();
      };
    }, 1000);
  }
  /**
   * Save option into local storage
   *
   * @public
   * @memberof LockedComponent
   */
  public toggleRepositionType() : void {
    this.enableContinueButton = true;
    this.storage.saveInLocal('respositionType',this.repositionType);
  }
  /**
   * Check block case to enable and disable continue button.
   *
   * @private
   * @memberof LockedTddComponent
   */
  private enableDisableContinue() : void {
    if(!this.blocker.operationReposition && this.blocker.operationExpressReposition) {
        this.enableContinueButton = false;
    }
  }
  /**
   *
   *
   * @memberof LockedTddComponent
   */
  ngOnInit() {
    this.enableDisableContinue();
    this.dataService.setUris(this.storage.getFromLocal('enviroment'));
    this.newPan = this.blocker.panReposition.slice(-4);
    this.storage.saveInLocal('newPan',this.newPan);
    this.newPanLenght = this.newPan.length > 0 ? true : false;
    if((!this.blocker.operationReposition && !this.blocker.operationExpressReposition) || (this.blocker.operationReposition && !this.blocker.operationExpressReposition)){
      this.storage.saveInLocal('respositionType','3');
    }
    this.setDataLayer();
  }

  /**
   * Sets the dataLayer for Google Analytics
   *
   * @memberof LockedTddComponent
   * @returns {void}
   */
   public setDataLayer(): void {
    this.taggingService.pageSettings({
      subsection: 'locked-tdd',
      claimType: this.storage.getFromLocal('viewQuestions'),
      cardType: this.userdata.cardName
    });
    this.taggingService.sendTeliumDataView();
  }

}
