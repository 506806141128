import { Component, OnInit } from '@angular/core';
import {SessionStorageService} from '../../../../services/tdd/session-storage.service';

/**
 *Cars component tto import
 *
 * @export
 * @class CardTddComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-card-tdd',
  template: require('./card-tdd.component.html')
})
export class CardTddComponent implements OnInit {
  //cardData
  userdata : any;
/**
 *Creates an instance of CardTddComponent.
 * @param {SessionStorageService} storage
 * @memberof CardTddComponent
 */
constructor(
    private storage: SessionStorageService,

  ) { 

  this.userdata = this.storage.getFromLocal('userdata');

  }
/**
 *
 *
 * @memberof CardTddComponent
 */
ngOnInit() {
  }

}
