import { Component } from '@angular/core';

/**
 * show end of session no refresh 
 *
 * @export
 * @class NoContentComponent
 */
@Component({
  selector: 'app-no-content',
  template:require('./no-content.component.html')
})
export class NoContentComponent {

}
