import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataProxyService } from './../../services/data-proxy.service';
// Services
import { NavigationService } from './../../services/navigation/navigation.service';
import { DataService } from './../../services/data.service';
import { ConstantsService } from './../../services/constants.service';
// Models
import { HistoryModel, HistoryListModel } from './../../models/';

import moment from 'moment';
import * as _ from 'lodash';
import * as md5 from 'blueimp-md5';

/**
 * History page component.
 *
 * @export
 * @class HistoryComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-history',
  template: require('./history.component.html'),
  providers: [
    DataService,
    NavigationService
  ]
})
export class HistoryComponent implements OnInit, OnDestroy {
  /**
   * show overlay
   *
   * @memberof HistoryComponent
   */
  public isLoading = true;
  /**
   * text of button 
   *
   * @memberof HistoryComponent
   */
  public text = "Ver más";
  /**
   * contains the list complete
   *
   * @type {Array<any>}
   * @memberof HistoryComponent
   */
  public fullList: Array<any> = [];
  /**
   * contains the list of selected 
   *
   * @type {Array<string>}
   * @memberof HistoryComponent
   */
  public selected: Array<string> = [];
  /**
   * contains the enviroment
   *
   * @private
   * @type {*}
   * @memberof HistoryComponent
   */
  private enviroment: any;
  /**
   * flag of dummy mode 
   *
   * @private
   * @memberof HistoryComponent
   */
  private dummyMode = false;
  /**
   * token of client 
   *
   * @private
   * @memberof HistoryComponent
   */
  private token = '';


 

  /**
   * Creates an instance of HistoryComponent.
   * @param {Router} router
   * @param {ActivatedRoute} route
   * @param {DataProxyService} dataProxy
   * @param {DataService} dataService
   * @param {ConstantsService} constantsService
   * @param {NavigationService} navigationService
   * @memberof HistoryComponent
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataProxy: DataProxyService,
    private dataService: DataService,
    private constantsService: ConstantsService,
    private navigationService: NavigationService
  ) {}

  /**
   * Angular Lifecycle hook: When the component it is initialized.
   *
   * @memberof HistoryComponent
   */
  public ngOnInit(): void {
    if(this.dataProxy.internalSession===undefined){
      this.dataProxy.clearData();
    }
    this.dataProxy.internalSession=undefined;
    this.dataProxy.sessionparam = {idCC:"ACLC"};
    // Get and Set the enviroment
    this.dataService
      .restRequest(
        'config.json',
        'GET',
        {},
        'config'
      )
      .subscribe(
        (response) => this.parseConfiguration(response),
        (error) => this.dataProxy.setEnviroment('dev')
      );
  }

  /**
   * Listen the interaction of the user and validate the native session
   *
   * @param {Event} $event
   * @memberof HistoryComponent
   */
  @HostListener('window:scroll', ['$event'])
  public onWindowScroll($event: Event): void {
    this.navigationService.validateSession();
  }

  /**
   * Format the title
   *
   * @param {string} v
   * @returns {string}
   * @memberof HistoryComponent
   */
  public formatTitle(v: string): string {
    moment.locale('es');
    return moment(v, 'YYYY-MM-DD').format('MMMM <br /> YYYY');
  }

  /**
   * Open/Close the selected item
   *
   * @param {string} val
   * @param {string} id
   * @returns {boolean}
   * @memberof HistoryComponent
   */
  public toggleItem(val: string, id: string): boolean {


    let element = document.getElementById('arrow'+id);
  
    if(element.classList.contains("active")){
      element.classList.remove("active");

    }else{
      element.classList.add("active");
    }


    const idx: number = _.findIndex(this.selected, (v: string) => { return v === val; });
    this.navigationService.validateSession();
    let res = false;
    if(idx > -1){
      this.selected.splice(idx, 1);
    } else {
      this.selected.push(val);
      res = true;
    }
    return res;
  
  }

  /**
   * Check if the item is selected
   *
   * @param {string} val
   * @returns {boolean}
   * @memberof HistoryComponent
   */
  public checkIfSelected(val: string): boolean {
    
    const idx: number = _.findIndex(this.selected, (v: string) => { return v === val; });
    return (idx > -1) ? true : false;
  }

  /**
   * Parse the alias of the configuration
   *
   * @private
   * @param {*} config
   * @memberof HistoryComponent
   */
  private parseConfiguration(config: any): void {
    // Search the enviroment
    this.enviroment = _.find(this.constantsService.ENVIROMENT, (item) => {
      return item.env === config.ENV_VAR;
    });
    if (!_.isUndefined(this.enviroment)) {
      this.dataProxy.setEnviroment(this.enviroment.env);
      this.dataService.setUris(this.enviroment.env);
      this.getQueryParams();
    } else {
      this.handleError('No enviroment');
    }
  }

  /**
   * Get the params in the URL
   *
   * @private
   * @memberof HistoryComponent
   */
  private getQueryParams(): void {
    let paramDummy: any = '';
    let comeFromMoves= this.dataProxy.getDummyMode();
    this.route.queryParams.subscribe((params) => {
      this.token = params['token'];
      paramDummy = params['dummy'];
      // paramDummy = 'true';

      this.dataProxy.setDummyMode(false);
      if (paramDummy === 'true' || comeFromMoves === true) {
        this.dummyMode = true;
        this.dataProxy.setDummyMode(true);
      }
      this.postOAuthToken();
    });
  }

  /**
   * Post OAuth Token
   *
   * @private
   * @memberof HistoryComponent
   */
  private postOAuthToken(): void {
    if (this.dummyMode) {
      this.dataService.dummyRequest('assets/data/token.json')
        .subscribe((response) => this.processTokenOAuth()); // DUMMY MODE
    } else if(this.dataProxy.getIdToken()!=='' && this.dataProxy.getIdToken()!== undefined && this.dataProxy.getIdToken()!==null){
      this.getHistory();
    }else{
      this.processTokenOAuth();
    }
  }

  /**
   * Process token OAuth
   *
   * @private
   * @param {*} r
   * @memberof HistoryComponent
   */
  private processTokenOAuth(): void {
    if (this.dummyMode) {
      this.getHistory();
    } else {
      this.getID();
    }
  }

  /**
   * Get the ID from the token validator
   *
   * @private
   * @memberof HistoryComponent
   */
  private getID(): void {
    const params = {
      token: this.token
    };
    this.dataService
      .restRequest('/token_validator', 'POST', null, 'sso_token', this.token)
      .subscribe(
        (response) => {
          if (response.id) {
            this.dataProxy.setIdToken(response.id);
            this.dataProxy.setBuc(response.buc);
            this.getHistory();
          }
        },
        (error) => this.handleError(error)
      );
  }

  /**
   * Get the history from the middleware
   *
   * @private
   * @memberof HistoryComponent
   */
  private getHistory(): void {
    if (this.dummyMode) {
      this.dataService
      .restRequest(
        'assets/data/history.json',
        'GET',
        {},
        'config'
      )
      .subscribe(
        (response) => this.processHistory(response),
        (error) => this.handleError(error)
      );
    } else {
      this.dataService.restRequest(
        '/folios',
        'GET',
        {},
        'folios',
        this.dataProxy.getAccessToken()
      ).subscribe((response) => this.processHistory(response));
    }
  }

  /**
   * Process the response of the microservice
   *
   * @private
   * @param {*} r
   * @memberof HistoryComponent
   */
  private processHistory(r: any): void {
    this.isLoading = false;
    let received: Array<HistoryModel> = [];
    _.each(r, (v: any) => {
        let item: HistoryModel = new HistoryModel();
        item.IncidentID = v.IncidentID;
        item.Category = v.Category;
        item.Subcategory = v.Subcategory;
        item.OpenTime = v.OpenTime;
        item.FechaCompromiso = v.FechaCompromiso;
        item.Open = v.Open;
        item.ResolutionCode = v.ResolutionCode;
        item.CoseTime = v.CoseTime;
        item.CauseCode = this.getCause(v.CauseCode);
        item.Numero3 = v.Numero3;
        // Format the values of each item
        item.formatValues();
        received.push(item);
    });
    // Group each item by date
    let grouped: Object = _.chain(received)
      .groupBy('IndexedDate')
      .map((list, date) => ({ list, date }))
      .value();
    // Add each item to the list
    _.each(grouped, (v: any) => {
      _.each(v.list, (l: any) => {
        if(l.IncidentID.indexOf('H') >= 0){
          l.status = "Cerrado";
          l.hFolio = true;
        }
      });
      this.fullList.push(v)
    });
    // Sort the formatted dates in descending order
    this.fullList = _.sortBy(this.fullList, (o) => {
      return moment(o.date);
    }).reverse();
  }

  /**
   * Get the formatted cause
   *
   * @private
   * @param {string} cause
   * @returns {string}
   * @memberof HistoryComponent
   */
  private getCause(cause: string): string {
    let formattedCause = cause;
    if (cause !== null) {
      if (cause.toLowerCase().lastIndexOf('favor del banco') > -1) {
        formattedCause = 'IMPROCEDENTE';
      } else if (cause.toLowerCase().lastIndexOf('favor del cliente') > -1) {
        formattedCause = 'PROCEDENTE';
      }
    }
    return formattedCause;
  }

  /**
   * Handle error
   *
   * @private
   * @param {*} error
   * @memberof HistoryComponent
   */
  private handleError(error: any): void {
    this.router.navigate(['no-connection']);
  }


  /**
   * Opener Light Box
   *
   * @param {*} event
   * @param {*} id
   * @memberof HistoryComponent
   */
  public tooltipOpener(event, id){
    
    //Define tooltipeText
    let ttText = 'En esta sección aparecerán todas las aclaraciones que ha levantado por este y otros medios (Contact Center, Sucursales, SuperLínea) de un período de 6 meses atrás a la fecha actual.';

   
    let y = event.clientY;
    let x = event.clientX;
    let tooltip = document.getElementById('tooltip-box');
    let backdrop = document.getElementById('backdrop');
    let tooltipText  = document.getElementById('tooltip-text');
    let flagBorder = document.getElementById('flag-border');
    let flagColor = document.getElementById('flag-color');

    tooltipText.innerHTML = ttText;
    tooltip.style.top = (y+20+ window.scrollY)+'px';
    tooltip.style.position = 'absolute';
    tooltip.style.left = (x-156)+'px';
    //flagColor.style.left = (x-14)+'px';
    //flagBorder.style.left = (x-14)+'px'  ;
    flagBorder.classList.remove("flag-border");
    flagColor.classList.remove("flag-color");
    flagBorder.classList.add("flag-border-up");
    flagColor.classList.add("flag-color-up");
    backdrop.classList.remove("tooltip-hide");
    tooltip.classList.remove("tooltip-hide");

  }



  /**
   * Show moves
   *
   * @param {*} id
   * @memberof HistoryComponent
   */
  showMoves(id){

    let button= document.getElementById('button'+id);
    let element= document.getElementById('listMoves'+id);
    let txtB= document.getElementById('txtButton'+id);

    if(button.classList.contains('active')){
      element.classList.remove('show');
      button.classList.remove('active');
      txtB.innerHTML = "Ver más"
    }else{
      element.classList.add('show');
      button.classList.add('active');
      txtB.innerHTML = "Ver menos"
    }
  }
  public ngOnDestroy() {
    this.dataProxy.internalSession = '';
    this.dataProxy.dataSourceSelected([]);
  }

}
