import { Injectable } from '@angular/core';
import { HttpHeaders} from '@angular/common/http';
import * as _ from 'lodash';
import moment from 'moment';
import {SessionStorageService} from './session-storage.service';
import { DataService } from './../../services/data.service';
import { DataProxyService } from './../../services/data-proxy.service';
import { TddMoveModel, QuestionsModel, LoaderModel,
    BlockModel, ResponseModel, AnswersQuestionsModel,
    MultifolioModel, MultifolioTddModel } from './../../models';

//Services

@Injectable()
export class UtilsTddService {
  private responseDAO: ResponseModel;
  private userData = this.storage.getFromLocal('userdata');
  private CONSTANTS : any = this.constants();

  /**
   *Creates an instance of UtilsTddService.
    * @param {SessionStorageService} storage
    * @param {DataService} dataService
    * @param {DataProxyService} dataProxyService
    * @memberof UtilsTddService
    */
  constructor(
    private storage: SessionStorageService,
    private dataService: DataService,
    public dataProxyService: DataProxyService
  ) {

  }

  /**
   * scroll top method 
   * @memberof UtilsTddService
   */
  scrolltop(){
    setTimeout(()=>{
        window.scrollTo(0,0);
    },100)
        
  }

  /**
   * 
   * generate View Questions
   * @param {*} motive motive general 
   * @param {string} description Description of motive of a clarification
   * @param {*} date date of movement 
   * @param {string} location location of the movement
   * @param {string} commerceInt information of the commerce 
   * @returns {Array<any>} 
   * @memberof UtilsTddService
   */
  generateViewQuestions(motive:any,description:string,date:any,location:string, commerceInt:string,motiveSelectedId:number):Array<any>{
    description = description.replace(/[^a-zA-Z0-9 _\\-\\.:,;áéíóúÁÉÍÓÚÜü¿?"¡!#$%&()=]/g,"");
    let questions : Array<any> = [];
    questions = Number(motive.id) <= 7 ? this.getInteractionView(motive,description,commerceInt) : this.createStoleModelView(description,motive,date);
    questions.push({key:'Ubicación actual',value:location,id:'location'});
    return questions;
  }

  /**
   * Model view to show on summary from stole
   *
   * @private
   * @param {string} description
   * @returns {Array<any>}
   * @memberof UtilsTddService
   */
  private createStoleModelView(description:string,motive:any,date:any) : Array<any> {
    let questions : any = [
      {key:'Tarjeta en su poder',value:'No',id:'hasCard'},
      {key:'Qué sucedió con su tarjeta',value:description,id:'whatHappen'}
    ];
    if(motive.id === "8") {
      date = date.replace(/\./gi,'');
      questions.push({key: 'Fecha de robo o extravío',value: date,id: 'stolenDate'});
    }

    return questions;
  }
  /**
   * 
   * get Interaction View of questionary 
   * @param {*} motive
   * @param {string} description
   * @param {string} commerceInt
   * @returns {Array<any>}
   * @memberof UtilsTddService
   */
  getInteractionView(motive:any, description:string, commerceInt:string):Array<any>{
    if(commerceInt === 'SI'){ commerceInt = 'Sí'; }
    let questions = [
      {key:'Tarjeta en su poder',value:'Sí',id:'hasCard'},
      {key:'Interactuó con el comercio',value:commerceInt,id:'commerceInteract'}
    ];

    if(commerceInt === 'Sí'){
        questions.push(
            {key:'Motivo',value:motive.description,id:'motive'},
            {key:'Descripción',value:description,id:'description'}
        )
    }
    return questions;
  }

  /**
   * set the format Date.
   *
   * @private
   * @param {string} v
   * @returns {string}
   * @memberof WelcomeComponent
   */
  private formatDate(v: string): string {
    return moment(v, 'YYYY-MM-DD')
      .format('YYYY-MM-DD[T06:00:00+00:00]').toString();
  }

  /**
   * set the format hour.
   *
   * @private
   * @param {string} h
   * @returns {string}
   * @memberof SummaryComponent
   */
  private formatHour(h: string): string {
    if (h === '' || h === null || typeof h === 'undefined') {
      return '00:00:00';
    } else {
      return h.replace('.', ':');
    }
  }


   /**
   * Get the Multifolio of the movements clarificaded
   *
   * @private
   * @returns
   * @memberof SummaryComponent
   */
  private getMultifolioModel(moves, isAtm = false) {
    let multifolio = [];
    let ix = 0;
    _.each(moves, (item: TddMoveModel) => {
      let objmultifolio = new MultifolioTddModel();
      objmultifolio.AcctTrnId = item.id;
      objmultifolio.Date = moment(item.date, 'MM-DD-YYYY').format('YYYY-MM');
      if (isAtm) {
        objmultifolio.AcctStmtId = item.txrNumExtracto;
      } else {
        delete objmultifolio['AcctStmtId'];
      }
      multifolio.push(objmultifolio); ix++;
    });
    return multifolio;
  }


  /**
   * generate the move informations of the view 
   *
   * @param {*} v
   * @param {*} m
   * @returns
   * @memberof UtilsTddService
   */
  generateMove(v,m){
    let dateStr = moment(v.acctTrnInfo.origDt, 'YYYY-MM-DD').format('MM-DD-YYYY').toString();
    let periodStr: string =  moment(dateStr, 'MM-DD-YYYY').format('MMMM YYYY');
    let fecha = this.formatDate(v.acctTrnInfo.origDt);
    let parsedStrDate  = dateStr.split('-');
    let typedDate = new Date(Number(parsedStrDate[2]),Number(parsedStrDate[0])-1,Number(parsedStrDate[1]));
    let nmove: TddMoveModel= new TddMoveModel(
      v.acctTrnId,
      v.acctTrnInfo.trnType.desc,
      v.acctTrnInfo.totalCurAmt.amt.toString(),
      dateStr,
      periodStr,
      v.acctTrnInfo.networkTrnData.merchNum,
      v.acctTrnInfo.networkTrnData.merchName,
      v.acctTrnInfo.origCurAmt.curCode.curCodeValue,
      fecha,
      v.acctTrnInfo.trnTime,
      v.acctTrnInfo.networkTrnData.posEntryCapability,
      v.acctTrnInfo.totalCurAmt.amt,
      this.storage.getFromLocal('ccdata').cardRec.cardInfo.acctRef.acctInfo.fiData.branchIdent,
      v.acctTrnId,
      m,
      v.acctTrnInfo.salesSlipRefNum,//dfef
      v.acctTrnInfo.trnType.trnTypeCode,
      v.acctTrnInfo.acctKeys.cardKeys.cardNum,
      typedDate,
      v.acctTrnInfo.clacon,//TxrClacon
      v.acctTrnInfo.refEmisor//TxrRefEmisor
    )
    return nmove;
    
  }

  /**
   * generate Service Mannager Object for clarifications  
   * @returns
   * @memberof UtilsTddService
   */
  generateSMObject(isAtm = false) : any {
    return {
      wvrinboxrn:{
        Categoria:'TARJETA DE DEBITO',//this.storage.getFromLocal('category'),
        Descripcion:this.storage.getFromLocal('additionaldata').description,
        EntidadFed:this.storage.getFromLocal('additionaldata').location.toString(),
        FechaRobada:this.storage.getFromLocal('additionaldata').lostdate,
        Subcategoria:this.storage.getFromLocal('subcategory'),
        VisaCarta:'false',
        cuestionario:this.storage.getFromLocal('questionnaire'),
        multifolio: this.getMultifolioModel( this.storage.getFromLocal('multifolio'), isAtm)
      }
    }
  }
    /**
     * Return files needed according to the reason.
     *
     * @param {string} reason
     * @returns {Array<any>}
     * @memberof UtilsTddService
     */
    getRequirementsResult(reason:string) : Array<any>{
      const requirementsResult = {
        receiptCorrectAmount : [{value:this.CONSTANTS.LABELS.RECEIPT_CORRECT_AMOUNT_DOC}],
        receiptRecognized : [{value:this.CONSTANTS.LABELS.RECEIPT_RECOGNIZED_DOC}],
        receiptPayment : [{value:this.CONSTANTS.LABELS.RECEIPT_PAYMENT_DOC}],
        receiptReturn : [{value:this.CONSTANTS.LABELS.RECEIPT_RETURN_DOC}],
        letterVoucher : [
            {value:this.CONSTANTS.LABELS.LETTER_VOUCHER_DOC}, 
            {value:this.CONSTANTS.LABELS.LETTER_VOUCHER_COPY},  
        ],
        voucherCancel : [{value:this.CONSTANTS.LABELS.VOUCHER_CANCEL_DOC}],
      }
      return requirementsResult[reason];   
    }

    /**
     *
     * handle Service Manager Request
     * @param {*} serviceResponse
     * @returns {*}
     * @memberof UtilsTddService
     */
    handleServiceManagerRequest(serviceResponse: any): any {
      this.validateSMHandler(serviceResponse);
      let response: any = new ResponseModel();
      let dateC = '';
      if (this.storage.getFromLocal('dummy')) {
          response.setResult(301); // DUMMY
      }
      const currentDate = moment().format('DD/MMM/YYYY HH:mm').toString();
      response.setCurrentDate(currentDate.split('.').join(''));
      if(serviceResponse.Messages){
        let nationalFolios : Array<any> = [];
        let internationalFolios : Array<any> = [];
        let payment = false;
        let date = moment();
        for(let item of serviceResponse.Messages){
            if (this.getPayment(item) === 'true') payment = true;
            const internationalFolio = this.getFolioDate(item, 'INTERNACIONAL');
            const nationalFolio = this.getFolioDate(item, 'NACIONAL');
            let temporaldate = this.greaterDateValue(internationalFolio, nationalFolio);
            date = this.greaterDateValue(date, temporaldate);
            let tempFoliosQ = this.getFolio(item, 'Internacional');
            let tempArrayF = this.extractFolio(tempFoliosQ);
            internationalFolios = this.concatArray(internationalFolios,tempArrayF);
            tempFoliosQ = this.getFolio(item, 'Nacional');
            tempArrayF = this.extractFolio(tempFoliosQ);
            nationalFolios = this.concatArray(nationalFolios,tempArrayF);
        }
        response.setInternationalFolio(internationalFolios);
        response.setNationalFolio(nationalFolios);
        response.setGreaterDate(date);
        response.setPayment(payment);
        response.setAmount(serviceResponse.wvrinboxrn.monto * 1);
        response.setResult(301);
      }
      response.setDateCommitment(dateC);
      response.setTotalAmount(serviceResponse.wvrinboxrn.monto * 1);
      response.setName(this.userData.name);
      response.setVisaCard(serviceResponse.wvrinboxrn.VisaCarta);
      response.setOldCard(this.userData.cardNumber);
      return response;
    }

    /**
     * validate Service Mannager Handler
     *
     * @param {*} serviceResponse
     * @memberof UtilsTddService
     */
    validateSMHandler(serviceResponse: any){
      // Check if the reponse is an error
      if (!_.isUndefined(serviceResponse.codigoMensaje)) {
        if (serviceResponse.codigoMensaje === 'MSG-001') {
          this.dataService.handleError('',{ name: serviceResponse.mensaje });
        }
      }
      if(serviceResponse.status==="Error"){
        this.dataService.handleError('',{name: serviceResponse.status});
      }
    }

    /**
     *
     * get Payment of the service mannager 
     * @param {string[]} serviceResponseItem
     * @returns {*}
     * @memberof UtilsTddService
     */
    getPayment(serviceResponseItem: string[], ): any {
      const find = _.find(serviceResponseItem, (item: any) => { return item.match(new RegExp(`Abono: `)); });
      if (!_.isUndefined(find)) {
        return (find.split(': ')[1] !== 'undefined') ? find.split(': ')[1] : null;
      }
      return null;
    }

    /**
     * get Folio Date of service mannager 
     *
     * @param {string[]} serviceResponseItem
     * @param {string} v
     * @returns {*}
     * @memberof UtilsTddService
     */
    getFolioDate(serviceResponseItem: string[], v: string): any {
      const find = _.find(serviceResponseItem, (item: any) => {
        return item.match(new RegExp(`^${v} next_breach ==> `));
      });
      if (!_.isUndefined(find)) {
        return moment(find.split('==> ')[1], 'DD/MM/YYYY HH::mm:ss');
      }
      return null;
    }

    /**
     * get the greater Date Value 
     *
     * @param {*} international
     * @param {*} national
     * @returns {*}
     * @memberof UtilsTddService
     */
    greaterDateValue(international, national): any {
      let greater = null;
      if (international && national) {
        greater = (international.isAfter(national)) ? international : national;
      } else if (international) {
        greater = international;
      } else if (national) {
        greater = national;
      }
      return greater;
    }

    /**
     * get  all folios clarificaded 
     *
     * @param {string[]} serviceResponseItem
     * @param {string} folioType
     * @returns {*}
     * @memberof UtilsTddService
     */
    getFolio(serviceResponseItem: string[], folioType: string): any {
      const find = _.find(serviceResponseItem, (item: any) => {
        return item.match(new RegExp(`^${folioType}: `));
      });
      if (!_.isUndefined(find)) {
        if(find.split(': ')[1].includes(' ')){
          let a =0;
          let tempFolio='';
          let arrayFolio = find.split(': ')[1].split(' ');
          if(arrayFolio[0]!== 'undefined' && arrayFolio[1]!== 'undefined'){
            return `${arrayFolio[0]}|${arrayFolio[1]}`;
          }else{
            arrayFolio[0]!== 'undefined'? tempFolio= tempFolio+ arrayFolio[0] :  a+=1;
            arrayFolio[1]!== 'undefined'? tempFolio= tempFolio+ arrayFolio[1] :  a+=1;
            tempFolio === '' ? tempFolio =  null : a+=1;
            return tempFolio;
          }
        }
        return (find.split(': ')[1] !== 'undefined') ? find.split(': ')[1] : null;
      }
      return null;
    }

    /**
     * get extract Folio array 
     *
     * @param {*} tempFolio
     * @returns
     * @memberof UtilsTddService
     */
    extractFolio(tempFolio){
      let tempArray =[];
      if(tempFolio !== null){
        tempFolio.includes('|') ? tempArray = tempFolio.split('|') : tempArray.push(tempFolio);
      }
      return tempArray;
    }

    /**
     * concat Array of folios clarificaded 
     * 
     * @param {*} origin
     * @param {*} toConcat
     * @returns
     * @memberof UtilsTddService
     */
    concatArray(origin,toConcat){
      toConcat.forEach(function(element) {
        origin.push(element);
      });
      return origin;
    }

    /**
     * get the Headers of the Request 
     *
     * @returns
     * @memberof UtilsTddService
     */
    public getHeadersRequest(){
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Bearer '+ this.storage.getFromLocal('app-access'),
          'KeyValue': this.storage.getFromLocal('client') || 'keyvalue'
        })
      };
      return httpOptions;
    }
     /**
     * Constants collection of the clarifications module 
     *
     * @returns {object}
     * @memberof UtilsTddService
     */
    constants() : object {
      return {
        STORAGE: {
          CHANNEL: 'chanel',
          SM_RESPONSE: 'SMResponse',
          QUESTION_ID: 'questionId',
        },
        LABELS: {
          PAYMENT_AMOUNT: 'Monto abonado',
          PAYMENT_DESCRIPTION: 'Hemos realizado un abono temporal a su tarjeta',
          PAYMENT_DESCRIPTION_DESCRIPTION: 'Mientras llevamos a cabo una investigación, y para no afectar su línea de crédito, hicimos un abono temporal.',
          CLARIFICATION_AMOUNT: 'Monto de la aclaración',
          CLARIFICATION_REGISTER: 'Hemos dado de alta su aclaración',
          CLARIFICATION_REGISTER_DESCRIPTION: 'Llevaremos a cabo una investigación para darle una pronta respuesta.',
          SUPERMOBILE: 'SuperMóvil',
          SUPERWALLET: 'SuperWallet',
          NATIONAL_FOLIO: 'Folio nacional',
          INTERNATIONAL_FOLIO: 'Folio internacional',
          NATIONALS_FOLIOS: 'Folios nacionales',
          INTERNATIONALS_FOLIOS: 'Folios internacionales',
          FOLIO_NUMBER: 'Número de folio',
          FOLIOS_NUMBER: 'Números de folio',
          CARD_REPOSITION: 'Nuevo número de tarjeta',
          RECEIPT_CORRECT_AMOUNT: 'receiptCorrectAmount',
          RECEIPT_RECOGNIZED: 'receiptRecognized',
          RECEIPT_PAYMENT: 'receiptPayment',
          RECEIPT_RETURN: 'receiptReturn',
          LETTER_VOUCHER: 'letterVoucher',
          VOUCHER_CANCEL: 'voucherCancel',
          RECEIPT_CORRECT_AMOUNT_DOC: 'Comprobante de compra con el importe correcto de la transacción realizada.',
          RECEIPT_RECOGNIZED_DOC: 'Comprobante de compra con el importe del (los) cargo(s) que sí reconoce.',
          RECEIPT_PAYMENT_DOC: 'Comprobante que demuestre el pago al comercio.',
          RECEIPT_RETURN_DOC: 'Comprobante de devolución emitido por el comercio.',
          LETTER_VOUCHER_DOC: 'Carta firmada que contenga el detalle de lo sucedido.',
          LETTER_VOUCHER_COPY: 'Copia del comprobante con la fecha de entrega comprometida por el comercio.',
          VOUCHER_CANCEL_DOC: 'Comprobante o folio de cancelación.'
        }
      };
    }

}