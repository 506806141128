/**
 * @author garodriguez@santander.com.mx
 * @description
 *  Reload session when user interacts with view components
 */
import { Directive, EventEmitter, HostListener, 
    Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataService } from './../../services/data.service';

/**
 * refrescar la sesion del usuario 
 *
 * @export
 * @class ReloadSessionDirective
 */
@Directive({
  selector: '[appReloadSession]',
  providers : [
    DataService
  ]
})
export class ReloadSessionDirective {
    /**
     * Creates an instance of ReloadSessionDirective.
     * @param {DataService} dataService
     * @memberof ReloadSessionDirective
     */
    constructor(
        private dataService: DataService
    ) {}

    /**
     * When click event is triggered get a request to reload session
     *
     * @param {*} event
     * @memberof ReloadSessionDirective
     */
    @HostListener('click', ['$event'])
    public clickEvent(event) {
        this.dataService.reloadRequest('reload_session');
    }
}
