import { Injectable, NgZone } from '@angular/core';
import * as _ from 'lodash';


/**
 * Navigation class that uses the global Connect variable.
 *
 * @export
 * @class NavigationService
 */
@Injectable()
export class NavigationService {

    /**
     * Creates an instance of NavigationService.
     * @param {NgZone} zone
     * @memberof NavigationService
     */
    constructor (private zone: NgZone) {}

    /**
     * Set the title of the native header app.
     *
     * @param {string} value
     * @memberof NavigationService
     */
    public setTitle(value: string): void {
        if (typeof window.Connect.setTitle === 'function') {
            try {
                window.Connect.setTitle(value);
            } catch (e) {
            }
        }
    }

    /**
     * Go to the previous section of the native app.
     *
     * @memberof NavigationService
     */
    public goToRoot(): void {
        if (typeof window.Connect.goToRoot === 'function') {
            try {
                window.Connect.goToRoot();
            } catch (e) {
            }
        }
    }



    /**
     * Function that hides the tooltip screen.
     *
     * @memberof NavigationService
     */
    public hideTooltip(){
        let tooltip = document.getElementById('tooltip-box');
        let backdrop = document.getElementById('backdrop');
        backdrop.classList.add("tooltip-hide");
        tooltip.classList.add("tooltip-hide");
      }

    /**
     * When the users tap the native back button.
     *
     * @param {string} [section='']
     * @param {Function} [cb=undefined]
     * @memberof NavigationService
     */
    public tapBack(section: string = '', cb: Function = undefined): void {
        this.hideTooltip();
        window.userDidTapBackButton = () => {
            switch (section) {
                case 'welcome':
                case 'filters':
                    this.zone.run(() => cb());
                    break;
                case 'questionnaire':
                case 'summary':
                case 'section':
                    try {
                        window.Connect.goBack();
                    } catch (e) {
                    }
                break;
                default:
                    return false;
            }
        };
    }

    /**
     * Validate the native app session.
     *
     * @memberof NavigationService
     */
    public validateSession(): void {
        if (typeof window.Connect.validaSesion === 'function') {
            try {
                window.Connect.validaSesion();
            } catch (e) {
            }
        }
    }

    /**
     * Hide the native back button.
     *
     * @memberof NavigationService
     */
    public hideBackButton(): void {
        if (typeof window.Connect.hideBackButton === 'function') {
            try {
                window.Connect.hideBackButton();
            } catch (e) {
            }
        }
    }
}
