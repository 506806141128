import { Injectable, Output, EventEmitter } from '@angular/core';
import {  UserModel, ExtractModel, QuestionsModel, MoveModel, StateModel, LoaderModel, SubcategoryModel, 
    CreditCard, ResponseModel, MotiveModel } from './../models';
// import { PersistenceService, StorageType } from 'angular-persistence';
import { SessionStorageService, LocalStorageService } from 'angular-web-storage';
import * as _ from 'lodash';

import { ConstantsService } from  './constants.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Console } from 'console';
import { Location } from '@angular/common';

/**
 * DataProxy clase que almacena los datos utilizados en la aplicacion
 * haciendo uso del Session Storage con un formato definido
 * 
 *
 * @export
 * @class DataProxyService
 */
@Injectable({
  providedIn: 'root'
})
export class DataProxyService {
    @Output() questionsStatusService: EventEmitter<string>;

    /**
     * flag lock card 
     *
     * @memberof DataProxyService
     */
    public noLock = false;
    /**
     * flag dummy mode 
     *
     * @memberof DataProxyService
     */
    public dummyMode = false;
    /**
     * credit card model 
     *
     * @type {CreditCard}
     * @memberof DataProxyService
     */
    public creditCardFullData: CreditCard = null;
    /**
     * the acces token 
     *
     * @memberof DataProxyService
     */
    public accessToken = '';
    /**
     * the data filter 
     *
     * @type {Object}
     * @memberof DataProxyService
     */
    public filteredData: Object = {};
    /**
     *  data whitout filters 
     *
     * @type {Object}
     * @memberof DataProxyService
     */
    public rawData: Object = {};
    /**
     * the user data 
     *
     * @type {UserModel}
     * @memberof DataProxyService
     */
    public userData:  UserModel = null;
    /**
     * the categories list 
     *
     * @type {Array<string>}
     * @memberof DataProxyService
     */
    public categories: Array<string> = [];
    /**
     * the sub categories list 
     *
     * @type {Array<SubcategoryModel>}
     * @memberof DataProxyService
     */
    public subcategories: Array<SubcategoryModel> = [];
    /**
     * the list state
     *
     * @type {Array<StateModel>}
     * @memberof DataProxyService
     */
    public states: Array<StateModel> = Array<StateModel>();
    /**
     * transacction object 
     *
     * @type {Object}
     * @memberof DataProxyService
     */
    public transactions: Object = {};
    /**
     * the response DAO
     *
     * @type {ResponseModel}
     * @memberof DataProxyService
     */
    public responseDAO: ResponseModel;
    /**
     * the array of extracts 
     *
     * @type {Array<ExtractModel>}
     * @memberof DataProxyService
     */
    public extracts: Array<ExtractModel> = Array<ExtractModel>();
    /**
     * the questions model 
     *
     * @type {QuestionsModel}
     * @memberof DataProxyService
     */
    public questions: QuestionsModel = new QuestionsModel();
    /**
     * Working with concurrent selected data
     *
     * @type {Array<MoveModel>}
     * @memberof DataProxyService
     */
    public dataSource: Array<MoveModel> = Array<MoveModel>();
    /**
     * Show selected data on menu on selection data pressed
     *
     * @type {Array<MoveModel>}
     * @memberof DataProxyService
     */
    private _dataSelected: Array<MoveModel> = Array<MoveModel>();
    /**
     * the loader model 
     *
     * @type {LoaderModel}
     * @memberof DataProxyService
     */
    public loader: LoaderModel;
    /**
     * the ccdata object
     *
     * @type {Object}
     * @memberof DataProxyService
     */
    public ccData: Object = {};
    /**
     * the enviroment 
     *
     * @memberof DataProxyService
     */
    public enviroment = '';
    /**
     * the motive model 
     *
     * @type {MotiveModel}
     * @memberof DataProxyService
     */
    public motive: MotiveModel = null;
    /**
     * the token id 
     *
     * @memberof DataProxyService
     */
    public idToken = '';
    /**
     * the pan 
     *
     * @memberof DataProxyService
     */
    public pan = '';
    /**
     * the buc 
     *
     * @memberof DataProxyService
     */
    public buc = '';
    /**
     * the old card 
     *
     * @memberof DataProxyService
     */
    public oldCard = '';
    /**
     * the session param 
     *
     * @memberof DataProxyService
     */
    private _sessionparam: any = undefined;
    /**
     * the internal session 
     *
     * @memberof DataProxyService
     */
    private _internalSession: any = undefined;

    /**
     * Creates an instance of DataProxyService.}
     * haciendo uso de la persistencia para session storage
     * y de las constantes utilizadas
     * @param {PersistenceService} persistenceService
     * @param {ConstantsService} constsService
     * @memberof DataProxyService
     */
    constructor (
      // private persistenceService: PersistenceService,
      public constsService: ConstantsService,
      private session: SessionStorageService,
      private storage: LocalStorageService,
      private route: Router,
      private location: Location
    ) {

      
      const currentUrl = this.location.path();
      if (currentUrl.includes('?token') || currentUrl.includes('tdd?token')) {
        this.session.clear();
        this.storage.clear();
      }
      
      this.idToken = session.get('idToken');  
      this.pan = session.get('pan');
      this.buc = session.get('buc');
      this.userData = session.get('userData');
      this.extracts = session.get('extracts');
      this.rawData = session.get('rowData');
      this.dataSource = session.get('dataSource');
      this.states = session.get('states');
      this.categories = session.get('categories');
      this.subcategories = session.get('subCategories');
      this.dummyMode = session.get('dummyMode');
      this.noLock = session.get('noLock');
      this.oldCard = session.get('oldCard');
      this.internalSession = session.get('sessionparam');
      this.dataSelected = storage.get('dataSelected');
      this.questions = storage.get('questions');
      this.ccData = storage.get('ccData');
      this.responseDAO = storage.get('responseDao');
      const loader = storage.get('loader');
      if (loader) {
        this.loader = new LoaderModel(loader.message);
      }
      this.accessToken = storage.get('accessToken');
      this.enviroment = storage.get('enviroment');

        // persistenceService.defineProperty(
        //     this, 'questionsStatusService', 'questionsStatusService',
        //     {type: StorageType.MEMORY});


        // persistenceService.defineProperty(
        //     this, 'motives', 'motivesHandler',
        //     {type: StorageType.MEMORY});

    }

    /**
     * Get the Lock Flag.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getNoLock() {
      return this.noLock;
    }

    /**
     * Set the Lock Flag
     *
     * @param {boolean} v
     * @memberof DataProxyService
     */
    public setNoLock(v: boolean): void {
      this.noLock = v;
      this.session.set('noLock', v);
    }

    /**
     * Get the Token.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getIdToken() {
      return this.idToken;
    }

    /**
     * Set the token.
     *
     * @param {string} v
     * @memberof DataProxyService
     */
    public setIdToken(v: string): void {
      this.idToken = v;
      this.session.set('idToken', v);
    }

    /**
     * Get the PAN.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getPan() {
      return this.pan;
    }

    /**
     * Set the PAN.
     *
     * @param {string} v
     * @memberof DataProxyService
     */
    public setPan(v: string): void {
      this.pan = v;
      this.session.set('pan', v);
    }

    /**
     * Get the BUC.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getBuc() {
      return this.buc;
    }

    /**
     * Set the BUC.
     *
     * @param {string} v
     * @memberof DataProxyService
     */
    public setBuc(v: string): void {
      this.buc = v;
      this.session.set('buc', v);
    }

    /**
     * Get the dummy mode.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getDummyMode() {
      return this.dummyMode;
    }

    /**
     * Set the dummy mode.
     *
     * @param {boolean} v
     * @memberof DataProxyService
     */
    public setDummyMode(v: boolean): void {
      this.dummyMode = v;
      this.session.set('dummyMode', v);
    }

    /**
     * Get the access token.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getAccessToken() {
      return this.accessToken;
    }

    /**
     * Set the access token.
     *
     * @param {string} v
     * @memberof DataProxyService
     */
    public setAccessToken(v: string): void {
      this.accessToken = v;
      this.storage.set('accessToken', v);
    }

    /**
     * Get the CC Data.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getCCData() {
      return this.ccData;
    }

    /**
     * Set the CC Data.
     *
     * @param {*} v
     * @memberof DataProxyService
     */
    public setCCData(v): void {
      this.ccData = v;
      this.storage.set('ccData', v);
      this.setCreditCardFullData(v);
    }

    /**
     * Set the Credit Card full data.
     * guarda los datos de la tarjeta ccon el modelo definido
     *
     * @param {*} v
     * @memberof DataProxyService
     */
     public setCreditCardFullData(v: any): void {
      if (!_.isUndefined(v.cardRec)) {
        this.creditCardFullData = new CreditCard(
          v.cardRec.cardInfo.cardNum,
          v.cardRec.cardInfo.partyKeys.partyId,
          v.cardRec.cardInfo.acctRef.acctId,
          v.cardRec.cardInfo.acctRef.acctInfo.fiData.branchIdent,
          v.cardRec.cardInfo.brand,
          v.cardRec.cardInfo.cardEmbossName,
          this.getBalance('OUTSTANDING'),
          this.getBalance('MINPAYMENT'),
          v.cardRec.cardInfo.closeStmtDt,
          v.cardRec.cardInfo.cardType,
          this.getLimit('MAX'),
          v.cardRec.cardInfo.acctRef.acctInfo.desc,
          v.cardRec.cardInfo.acctRef.acctInfo.productIdent
        );
      }
    }

    /**
     * Get the Credit Card full data.
     * regresa los datos de la tarjeta con el formato definido
     * y haciendo uso de el modelo de datos de tarjeta
     *
     * @returns {CreditCard}
     * @memberof DataProxyService
     */ 
     public getCreditCardFullData(): CreditCard {
      if (this.getCCData()) {
        const v: any = this.getCCData();
        let ret: CreditCard = new CreditCard(
          v.cardRec.cardInfo.cardNum,
          v.cardRec.cardInfo.partyKeys.partyId,
          v.cardRec.cardInfo.acctRef.acctId,
          v.cardRec.cardInfo.acctRef.acctInfo.fiData.branchIdent,
          v.cardRec.cardInfo.brand,
          v.cardRec.cardInfo.cardEmbossName,
          this.getBalance('OUTSTANDING'),
          this.getBalance('MINPAYMENT'),
          v.cardRec.cardInfo.closeStmDt,
          v.cardRec.cardInfo.cardType,
          this.getLimit('MAX'),
          v.cardRec.cardInfo.acctRef.acctInfo.desc,
          v.cardRec.cardInfo.acctRef.acctInfo.productIdent
        );
        this.creditCardFullData = ret;
        return ret;
      } else {
        return null;
      }
    }

    /**
     * Obtiene el limite de credito que se encuentra dentro de CCdata
     *
     * @param {string} type
     * @returns {number}
     * @memberof DataProxyService
     */
    public getLimit(type: string): number {
      let res = 0;
      const full: any = this.getCCData();
      const v: any = full.cardRec.cardInfo.cardTrnLimit;
      _.forEach(v, (item: any) => {
        if (item.limitType === type) {
          res = parseFloat(item.curAmt.amt);
        }
      });
      return res;
    }

    /**
     * Set the user data.
     *Guarda los datos del usuario
     * @param {UserModel} v
     * @memberof DataProxyService
     */
    public setUserData(v: UserModel): void {
      this.userData = v;
      this.session.set('userData', v);
    }

    /**
     * Get the user data.
     *
     * @returns {*}
     * @memberof DataProxyService
     */
    public getUserData(): any {
      return this.userData;
    }

    /**
     * Get the user extracts.
     *
     * @returns {*}
     * @memberof DataProxyService
     */
    public getUserExtracts(): any {
      return this.userData.extracts;
    }

    /**
     * Get user extracts count.
     * obtiene el numero de extractos que el cliente tiene permitido
     *
     * @returns {number}
     * @memberof DataProxyService
     */
    public getUserExtractsCount(): number {
      if (this.userData) {
        return this.userData.extracts.length;
      } else {
        return 0;
      }
    }

    /**
     * Set the extracts.
     *
     * @param {Array<ExtractModel>} v
     * @memberof DataProxyService
     */
    public setExtracts(v: Array<ExtractModel>): void {
      this.extracts = v;
      this.session.set('extracts', v);
    }

    /**
     * Get the extracts.
     *
     * @returns {Array<ExtractModel>}
     * @memberof DataProxyService
     */
    public getExtracts(): Array<ExtractModel> {
      return this.extracts;
    }

    /**
     * Set raw data.
     *
     * @param {*} v
     * @memberof DataProxyService
     */
    public setRawData(v): void {
      this.rawData = v;
      this.session.set('rawData', v);
    }

    /**
     * Get raw data.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getRawData() {
      return this.rawData;
    }

    /**
     * Get Data source.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getDataSource() {
      return this.dataSource;
    }

    /**
     * Set data source.
     *
     * @param {Array<MoveModel>} v
     * @memberof DataProxyService
     */
    public setDataSource(v:Array<MoveModel>): void {
      this.dataSource = v;
      this.session.set('dataSource', v);
    }

    /**
     * Get data selected.
     *
     * @returns {*}
     * @memberof DataProxyService
     */
    public getDataSelected(): any {
      if (!this.dataSource) {
        return null;
      } else {
        return this.dataSource;
      }
    }

    /**
     * Set states
     *
     * @param {Array<StateModel>} v
     * @memberof DataProxyService
     */
    public setStates(v: Array<StateModel>): void {
      this.states = v;
      this.session.set('states', v);
    }

    /**
     * Get the states array.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getStates() {
      return this.states;
    }

    /**
     * Get state id.
     * obtiene el id del estado seleccionado en el cuestionario
     *
     * @param {string} desc
     * @returns {number}
     * @memberof DataProxyService
     */
    public getStateID(desc: string): number {
      let ret: StateModel = null;
      _.each(this.getStates(), (o: StateModel) => {
        if (o.nombre.toLowerCase() === desc.toLowerCase()) {
          ret = o;
        }
      });
      return ret.clave;
    }

    /**
     * Get categories.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getCategories() {
      return this.categories;
    }

    /**
     * Set categories.
     * Guarda las categorias de la aplicacion, parseando el JSON de entrada
     * @param {*} v
     * @memberof DataProxyService
     */
    public setCategories(v: any) {
      if(!this.categories){
        this.categories = [];
        _.each(v.content, (item: any) => {
          let title: string = item.categoryrn.Name.toString();
          if(title.lastIndexOf('.') !== title.length){
            this.categories.push(title);
          }
        });
        this.session.set('categories', this.categories);
      }
    }

    /**
     * Get subcategories.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getSubcategories() {
      return this.subcategories;
    }

    /**
     * Set subcategories.
     *
     * @param {*} v
     * @memberof DataProxyService
     */
    public setSubcategories(v: any){
      if(!this.subcategories){
        this.subcategories = [];
        _.each(v.content, (item: any) => {
          let subcat:SubcategoryModel = item.subcategoryrn;
          let title: string = subcat.category;
          if (title.lastIndexOf('.') !== title.length) {
            this.subcategories.push(subcat);
          }
        });
        this.session.set('subCategories', this.subcategories);
      }
    }

    /**
     * Data source selected.
     *
     * @param {Array<MoveModel>} v
     * @memberof DataProxyService
     */
    public dataSourceSelected(v: Array<MoveModel>){
      this.dataSource = v;
    }

    /**
     * Get selected count
     *
     * @returns
     * @memberof DataProxyService
     */
    public getSelectedCount() {
      if(this.dataSource){
        return this.dataSource.length;
      } else {
        return 0;
      }
    }

    /**
     * Get questions.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getQuestions() {
      return this.questions;
    }

    /**
     * Set questions.
     *
     * @param {QuestionsModel} v
     * @memberof DataProxyService
     */
    public setQuestions(v: QuestionsModel) {
      this.questions = v;
      this.storage.set('question', v);
    }

    /**
     * Get loader.
     *
     * @returns {LoaderModel}
     * @memberof DataProxyService
     */
    public getLoader(): LoaderModel {
      return this.loader;
    }

    /**
     * Set loader.
     *
     * @param {LoaderModel} loader
     * @memberof DataProxyService
     */
    public setLoader(loader: LoaderModel): void {
      this.loader = loader;
      this.storage.set('loader', loader);
    }

    /**
     * Call questionnaire.
     *
     * @param {string} v
     * @memberof DataProxyService
     */
    public callQuestionnaire(v: string) {
      this.questionsStatusService.emit(v);
    }

    /**
     * Get questions status service.
     *
     * @returns {EventEmitter<string>}
     * @memberof DataProxyService
     */
    public getQuestionsStatusService(): EventEmitter<string> {
      if (!this.questionsStatusService) {
        this.questionsStatusService  = new EventEmitter(true);
      }
      return this.questionsStatusService;
    }

    /**
     * Set response DAO.
     *
     * @param {*} v
     * @memberof DataProxyService
     */
    public setResponseDAO(v): void {
      this.responseDAO = v;
      this.storage.set('responseDao', v);
    }

    /**
     * Get response DAO.
     *
     * @returns {ResponseModel}
     * @memberof DataProxyService
     */
    public getResponseDAO(): ResponseModel {
      if(!this.responseDAO){
        this.responseDAO = new ResponseModel();
      }
      return this.responseDAO;
    }

    /**
     * Clear data.
     *
     * @memberof DataProxyService
     */
    public clearData():void {
      this.cleanData();
    }

    /**
     * Clean data.
     * quita del storage los elementos variables por cliente
     *
     * @memberof DataProxyService
     */
    public cleanData() {
      // this.persistenceService.remove('dataSource');
      // this.persistenceService.remove('dataSelected');
      // this.persistenceService.remove('questions');
      // this.persistenceService.remove('userData');
      this.dataSource = null;
      this.dataSelected = null;
      this.questions = null;
      this.setIdToken(undefined);
      this.setExtracts(Array<ExtractModel>());
      this.setUserData(undefined);
    }

    /**
     * Get enviroment.
     *
     * @returns
     * @memberof DataProxyService
     */
    public getEnviroment() {
      return this.enviroment || localStorage.getItem('enviroment');
    }

    /**
     * Set enviroment
     *
     * @param {*} enviroment
     * @memberof DataProxyService
     */
    public setEnviroment(enviroment) {
      this.enviroment = enviroment;
      localStorage.setItem('enviroment', enviroment);
    }

    /**
     * Get old card.
     *
     * @returns {string}
     * @memberof DataProxyService
     */
    public getOldCard(): string {
      return this.oldCard;
    }

    /**
     * Set the old card.
     *
     * @param {string} v
     * @memberof DataProxyService
     */
    public setOldCard(v: string): void {
      const l: number = v.length;
      this.oldCard = v.substring(l - 4, l);
      this.session.set('oldCard', this.oldCard);
    }

    /**
     * Get balance.
     * obtiene el  balance los datos de la tarjeta del cliente
     * @private
     * @param {string} type
     * @returns {number}
     * @memberof DataProxyService
     */
    private getBalance(type: string): number {
      let res = 0;
      const full: any = this.getCCData();
      const v: any = full.cardRec.cardInfo.acctRef.acctInfo.acctBal;
      _.forEach(v, function(item: any){
        if(item.balType){
          if(item.balType.balTypeValues === type){
            res = parseFloat(item.curAmt.amt);
          }
        }
      });
      return res;
    }

    public set dataSelected(v: Array<MoveModel>) {
      this._dataSelected = v;
      this.storage.set('dataSelected', v);
    }

    public get dataSelected(): Array<MoveModel> {
      return this._dataSelected;
    }

    public set sessionparam(v: any) {
      this._sessionparam = v;
      this.storage.set('sessionparam', v);
    }

    public get sessionparam(): any {
      return this._sessionparam;
    }

    public set internalSession(v: any) {
      this._internalSession = v;
      this.storage.set('internalSession', v);
    }

    public get internalSession(): any {
      return this._internalSession;
    }
}
