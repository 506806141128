import moment from 'moment';

/**
 *
 *
 * @export
 * @class UtilsService
 */
export class UtilsService {

  /**
   * Retrieve parsed date.
   *
   * @param {string} dateToBeParsed
   * @returns
   * @memberof UtilsService
   */
  public retrieveParsedDate(dateToBeParsed: string) {
    moment.locale('es');
    return moment(dateToBeParsed, 'DD-MM-YYYY').format('dddd DD [de] MMMM, YYYY');
  }
  /**
     * Get SSO Token from native instance.
     *
     * @returns {*}
     * @memberof UtilsService
     */
    getNativeToken() : any {
      if (typeof window.Connect !== "undefined") {
          try {
            return window.Connect.getSSOToken();
          } catch(err) {
            return null;
          }
        } else{
          return null;
        }
      
  }
}
