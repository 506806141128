import moment from 'moment';
import { ExtractModel } from './extract.model';
import * as _ from 'lodash';

/**
 * User object used in the preloader, welcome and summary component
 *
 * @export
 * @class UserModel
 */
export class  UserModel {
  /**
   * buc of user 
   *
   * @memberof UserModel
   */
  public buc = '';
  /**
   * user name 
   *
   * @memberof UserModel
   */
  public name = '';
  /**
   * the number of the card 
   *
   * @memberof UserModel
   */
  public cardNumber = '';
  /**
   * limit of the credit card 
   *
   * @memberof UserModel
   */
  public creditLimit = 0;
  /**
   * the balance 
   *
   * @memberof UserModel
   */
  public balance = 0;
  /**
   * payment minimun 
   *
   * @memberof UserModel
   */
  public minPayment = 0;
  /**
   * title of percen
   *
   * @memberof UserModel
   */
  public percentile = '';
  /**
   * name of the card 
   *
   * @memberof UserModel
   */
  public cardName = '';
  /**
   * the cut off
   *
   * @memberof UserModel
   */
  public cutoff = '';
  /**
   * the extracts 
   *
   * @type {Array<ExtractModel>}
   * @memberof UserModel
   */
  public extracts: Array<ExtractModel> = [];

  /**
   * Creates an instance of UserModel.
   * @param {string} _buc
   * @param {string} _name
   * @param {string} _cardNumber
   * @param {string} _creditLimit
   * @param {string} _balance
   * @param {string} _minPayment
   * @param {string} _cardName
   * @param {string} _cutoff
   * @param {Array<any>} _extracts
   * @memberof UserModel
   */
  constructor(_buc: string,
              _name: string,
              _cardNumber: string,
              _creditLimit: string,
              _balance:string,
              _minPayment:string,
              _cardName: string,
              _cutoff:string,
              _extracts: Array<any>) {
    this.buc         = _buc;
    this.name        = _name;
    this.cardNumber  = _cardNumber;
    this.creditLimit = parseFloat(_creditLimit);
    this.balance     = parseFloat(_balance);
    this.minPayment  = parseFloat(_minPayment);
    this.cardName    = _cardName;
    this.cutoff      = _cutoff;
    let auxExtracts: Array<ExtractModel> = [];
    let con = 0;
    for (let i=0; i<_extracts.length;i++){
      _extracts[i].acctStmtId = parseInt(_extracts[i].acctStmtId);
    }
    let extractData: Array<ExtractModel> = _.orderBy(_extracts, ['acctStmtId']).reverse();

    extractData = extractData.slice(0,4);

    _.each(extractData, (v: any) => {
      if (v.acctStmtId) {
        auxExtracts.push(new ExtractModel(v.acctStmtId));
      }
    });

    if (auxExtracts.length > 0) {
      this.extracts = auxExtracts;
    }
    this.percentile = this.getUsedCreditPercentile();
  }

  /**
   * Get used credit percentile
   *
   * @returns {string}
   * @memberof UserModel
   */
  public getUsedCreditPercentile(): string{
    let perc: number = (this.balance / this.creditLimit) * 100;
    return perc.toFixed(2).toString().concat('%');
  }

  /**
   * Get extract count
   *
   * @returns
   * @memberof UserModel
   */
  public getExtractCount() {
    return this.extracts.length | 0;
  }
}
