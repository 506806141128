/**
  *
  * MultifolioTddModel object used on the clarification request
  * @export
  * @class MultifolioTddModel
  */
 export class MultifolioTddModel {
    /**
     * Folio ID
     *
     * @type {string}
     * @memberof MultifolioTddModel
     */
    public AcctTrnId: string;
    /**
     * Folio date
     *
     * @type {string}
     * @memberof MultifolioTddModel
     */
    public Date: string; 

    /**
     * Folio Id
     *
     * @type {string}
     * @memberof MultifolioTddModel
     */
    public AcctStmtId: string;
 }