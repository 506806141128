import { Component, OnInit,ViewChild,forwardRef } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, ParamMap, Route } from '@angular/router';
import { SessionStorageService } from '../../../services/tdd/session-storage.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { DataService } from '../../../services/data.service';
import { UtilsTddService } from '../../../services/tdd/utils-tdd.service';
import { NavigationService } from '../../../services/navigation.service/index';
import { DataProxyService } from '../../../services/data-proxy.service';

import { TaggingService } from '../../../services/tagging.service';

// Import Tooltips
import moment from 'moment';
import * as _ from 'lodash';
import { TooltipTddComponent } from '../generics';

/**
 * Componente que muestra la pantalla de movimientos 
 * posibles a aclarar
 * Ademas de los filtros y el repositorio de movimientos
 *
 * @export
 * @class WelcomeComponentTDD
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
    selector: 'app-welcome-tdd',
    template: require('./welcome-tdd.component.html'),
    providers: [
      DataService,
      NavigationService
    ]
})
    export class WelcomeTddComponent implements OnInit {

      @ViewChild(forwardRef(() =>TooltipTddComponent), {static:true}) tooltip:TooltipTddComponent;
      
  //SETEO VARIABLES

  // Token
  token : string;

  //Spinner
  spinner: boolean;

  //Filtros

  sfApply : boolean = false;
  filterApply : boolean = false;
  filterName : string = '';
  enable : boolean = false;
  actualFilter : number;

  //Channel
  chanelType : string = '';

  //MOvimientos globales
  globalMoves = [];
  allMoves = [];
  viewMoves =[];
  filterMoves = [];
  filterNameApply = '';

  //Selected Moves
  smSelectedMoves = [];
  viewSelectedMoves =[];

  //Bandera carga movimientos
  isLoadingMoves:boolean;

  // Assign value to set on hideToggle function
  public toggleId = '';

  // Movement to find 
  findMovement = '';
  public userdata = this.storage.getFromLocal('userdata');
  private filterDesc = '';
  public selectedItemsCount : number = 0;
  private navToggle : boolean = false;
  public isHideNav : boolean = false;
  public isFiltered : boolean = false;

/**
 *Creates an instance of WelcomeTddComponent.
 * @param {ActivatedRoute} route
 * @param {Router} router
 * @param {Location} location
 * @param {SessionStorageService} storage
 * @param {HttpClient} http
 * @param {DataService} dataService
 * @param {TaggingService} taggingService
 * @param {UtilsTddService} utils
 * @param {DataProxyService} dataProxy
 * @memberof WelcomeTddComponent
 */
constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private storage: SessionStorageService,
    private http: HttpClient,
    private dataService: DataService,
    private utils: UtilsTddService,
    private navigationService: NavigationService,
    private taggingService: TaggingService,
    public dataProxy: DataProxyService
  ) {
    this.toggleNav = this.toggleNav.bind(this);
    //DEC VARIBALES

    //Spinner
    this.spinner = true;

    //GET URL PARAMETERS
    route.paramMap.subscribe(params => {
      this.token = params.get('token');
    });

    //Get Channel
    this.chanelType = this.storage.getFromLocal('chanel');
    //this.chanelType = 'wallet';


    //For Navigation Rules
    this.hideToggle = this.hideToggle.bind(this);
  }


  /**
   * Metodo que carga las urls al servicio dataService
   * hace el llamado a los movimientos del usuario
   *
   * @memberof WelcomeTddComponent
   */
  ngOnInit() {
    this.saveSession();
    this.dataService.setUris(this.storage.getFromLocal('enviroment'));
    this.isLoadingMoves = true;
    this.callMoveService(0,0);

    //Tap Back Button
    this.navigationService.tapBack('welcome', this.navigationService.goToRoot);
    //TALIUM TAG
    this.setDataLayer();
  }

   




  /**
   * Hace el cambio de los movimientos por mes a un array global
   * los movimientos de la vista son generados de la lista global
   * Generar movimientos 
   *
   * @memberof WelcomeTddComponent
   */
  generateMovesArray(){
    this.allMoves = [];
    let extract =0;
    for (let i of this.globalMoves){
      for (let j of i){
        this.allMoves.push(this.utils.generateMove(j,extract));
      }
      extract +=1;
    }
    this.viewMoves = this.generateViewMoves(this.allMoves);
  }

  
  /**
   * Filtrar Moviemientos
   * Genera un array de el mes seleccionado
   * recibe el indice para tomarlo del arreglo de meses
   * genera la vista del filtro
   *
   * @param {*} moves
   * @param {number} index
   * @memberof WelcomeTddComponent
   */
  generateFilterMoves(moves:any,index:number){
    this.filterMoves=[];
    for (let i of moves){
      this.filterMoves.push(this.utils.generateMove(i,index));
    }
    this.viewMoves = this.generateViewMoves(this.filterMoves);

  }



  /**
   *
   * FILTRADO POR PALABRAS
   * @param {string} id
   * @memberof WelcomeTddComponent
   */
  inteligentFilter(id: string){
    if(id === ""){
      this.viewMoves = this.generateViewMoves(this.allMoves);
      this.filterApply = false;
      this.filterName = '';
    } else {
      this.filterMoves=[];
      this.filterApply = true;
      this.filterName = id;
      this.findMovement = id;
      for(let i of this.allMoves){
        if(i.txrComercio.toLowerCase().indexOf(id.toLowerCase()) > -1 ){
          this.filterMoves.push(i);
        }
      }
      this.viewMoves = this.generateViewMoves(this.filterMoves);
    }
  
  }
  /**
   *
   * Cambiar letras mayusculas en la busqueda
   * @param {string} commerceName
   * @param {string} findMovement
   * @returns
   * @memberof WelcomeTddComponent
   */
  public highlightFilter(commerceName: string, findMovement : string) {
    if(findMovement.length > 0){
      return commerceName.replace(new RegExp(findMovement, 'gi'), (match) => {
        return `<span class="bold">${match}</span>`;
      });
    } else {
      return commerceName;
    }
  }

  /**
   * delete Filters
   *
   * @memberof WelcomeTddComponent
   */
  deleteFilters(){
    this.findMovement = '';
  }

  

  /**
   * Generar Movimientos Vista
   * genera un mapa con fecha,array
   * la fecha se usa como llave para la vista
   * el array contiene los movimientos de esa fecha
   * 
   * 
   * @param {*} moves
   * @returns {*}
   * @memberof WelcomeTddComponent
   */
  generateViewMoves(moves:any):any{
    let tempArray = [];
    if(moves.length>0){
      for(let move of moves){
        let index = _.findIndex(tempArray,(o)=>{
          return o.key.getTime() === move.typeDate.getTime();
        })
        if(index >= 0){
          tempArray[index].value.push(move);
        } else {
          tempArray.push({key:move.typeDate,value:[move]});
        }
      }
    }
    return tempArray;

  }

  
  /**
   *LLAMADO AL SERVICIO D EMOVIMIENTOS
   *
   * hace el llamado de movimientos
   * se hace el llamado 4 veces o al segundo reintenro
   * una vez terminado el llamado genera el array global y de la vista
   * 
   * 
   * @private
   * @param {number} month
   * @param {number} retry
   * @memberof WelcomeTddComponent
   */
  private callMoveService(month:number,retry:number){
    let endpoint = this.getMovesEndpoint(month);
    const httpOptions = this.utils.getHeadersRequest();
    if(this.globalMoves.length < 4 ){
      this.http.get(endpoint,httpOptions).subscribe(
        (data : any) => {
          this.globalMoves.push(data.acctTrnRecComercios);
          this.callMoveService(month+1,0);
        },
        err => {
          if(retry < 1){
            this.callMoveService(month,retry+1);
          }else{
            this.isLoadingMoves = false;
            this.generateMovesArray();
          }
        }
      );  
    }else{
      this.isLoadingMoves = false;
      this.generateMovesArray();
    }
  }

  /**
   *Selected Moves View
   *
   * genera el array que se muestra en el repositorio de movimientos
   * mapa fecha - array
   * @memberof WelcomeTddComponent
   */
  generateSelectedView(){
    this.viewSelectedMoves=[];
    this.viewSelectedMoves = this.generateViewMoves(this.smSelectedMoves);
    this.viewSelectedMoves = _.orderBy(this.viewSelectedMoves,'key','desc')
  }

  /**
   * Movimientos Seleccionados
   * agrega los movimientos que se vayan seleccionando en la vista
   * a un mapa fecha - array
   * si la fecha del movimiento existe en el mapa lo agrega a su array
   * si no agrega la llave y al array con el movimiento
   *
   * @param {*} move
   * @returns {*}
   * @memberof WelcomeTddComponent
   */
  public selectedMoves (move: any): any {

    const index = _.findIndex(this.smSelectedMoves,(o)=>{
      return o.id === move.id;
    })
    
    if(index >= 0){
      this.smSelectedMoves.splice(index,1);
    }else{
      this.smSelectedMoves.push(move);
    }
    this.selectedItemsCount = Object.keys(this.smSelectedMoves).length;
    this.dataProxy.dataSourceSelected(this.smSelectedMoves);
    this.checkSelectedElements(move)
  }


  // OBTENER FECHA
  /**
   * regresa el año que se debe consultar
   * recibe el numero de mes que se va a restar a la fecha actual
   *
   * @param {number} month
   * @returns
   * @memberof WelcomeTddComponent
   */
  getMovesYear(month:number){
    return Number( moment().subtract(month,'months').format('YYYY'));
  }

  /**
   * regresa el mes que se debe mostrar en la vista - filtro
   * recibe el numero de mes que se va a restar a la fecha actual
   *
   * @param {number} month
   * @returns
   * @memberof WelcomeTddComponent
   */
  getMonthName(index:number){
    if(index === 0){
      return "Corte actual"
    }else{
      let tempstr = moment().subtract(index,'months').format('MMMM');
      return tempstr.charAt(0).toUpperCase() + tempstr.slice(1);
    }
  }

  /**
   * regresa el mes que se debe consultar
   * recibe el numero de mes que se va a restar a la fecha actual
   *
   * @param {number} month
   * @returns
   * @memberof WelcomeTddComponent
   */
  getMovesMonth(month:number){
    return Number(moment().subtract(month,'months').format('MM'));
  }

  /**
   * genera el endpoint que se debe consultar
   * dependiendo de el ambiente y del modo dummy
   *
   * @param {number} month
   * @returns {string}
   * @memberof WelcomeTddComponent
   */
  getMovesEndpoint(month:number):string{
    let endpoint = '';
    if (this.storage.getFromLocal('dummy')) {
      endpoint = 'assets/data/moves-tdd.'+month+'.json'; 
    } else{ 
      endpoint = this.dataService.getApiURL('debit')+'/movements/years/'+this.getMovesYear(month)+'/months/'+this.getMovesMonth(month);
    }
    return endpoint;
  }

  //GET CLIENT ID
  /**
   * una vez cargado el elemento de la vista 
   * esconde el spinner
   *
   * @private
   * @memberof WelcomeTddComponent
   */
  private saveSession(): void {
    // session
      this.spinner=false;

  }
  
  /**
   * Check if movement is selected and return true
   * to add "active" class.
   *
   * @param {*} element
   * @returns {boolean}
   * @memberof WelcomeTddComponent
   */
  checkSelectedElements(element : any) : boolean{
    let value = false;
    const index = _.findIndex(this.smSelectedMoves,(o)=>{
      return o.id === element;
    })
    if(index >= 0) {
      value = true;
    }
    return value;
  }


  /**
   * muestra un lightbox cuando se da click
   * filtro o repositorio de movimientos
   *
   * @param {*} id
   * @memberof WelcomeTddComponent
   */


  showToggle(){

    let element = document.getElementById(this.toggleId);
    element.classList.add("animate");

    //Tap Back Button
    this.navigationService.tapBack('filters',  this.hideToggle);

  }
  
  /**
   * esconde un lightbox cuando se da click
   * filtro o repositorio de movimientos
   *
   * @param {*} id
   * @memberof WelcomeTddComponent
   */
  hideToggle(){
    let element = document.getElementById(this.toggleId);
    element.classList.remove("animate");
    element.classList.add("animateReverse");
    setTimeout(function(){
      element.classList.remove("animateReverse");
    }, 700);
    
    if(this.toggleId === 'filtersComp' && this.enable){
      this.filterApply = true;
    }


    //Tap Back Button
    this.navigationService.tapBack('filters', this.navigationService.goToRoot);
    this.toggleId = '';
    this.toggleNav();
  }


  /**
   * muestra el lightbox del child tooltip
   * genera el mensaje del tooltip y lo pasa como parametro al child
   *
   * @param {*} evt
   * @param {*} id
   * @memberof WelcomeTddComponent
   */
  public showTooltip(evt:any, id){
    let text ='';
    if(id === 1){
      text = 'Por favor toque “x” para eliminar un movimiento que ya no desee mandar a aclarar.';
    }else{
      text = 'Es importante señalar que se pueden aclarar movimientos de hasta 3 meses anteriores a la fecha actual.';
    }
    this.tooltip.showTooltip(evt,text);
  }

  /**
   * metodo que recibe del hijo la accion de continuar
   * guarda los movimientos seleccionados en sesion y navega a el cuestionario
   *
   * @memberof WelcomeTddComponent
   */
  executeContinue(){
    this.storage.saveInLocal('isConsumer',true);
    this.taggingService.sendTeliumDataLink(
      {
        'interaction_action':'detalle_movimientos',
        'interaction_label':'continuar'
      }
    );
    this.storage.saveInLocal('multifolio',this.smSelectedMoves);
    this.router.navigate(['questionnaire-tdd']);
  }



  /**
   * muestra la aplicación de los filtros en la vista
   *
   * @memberof WelcomeTddComponent
   */
  showFilterApply(){
    if(this.sfApply){
      this.sfApply = false;
    }else{
      this.sfApply = true;
    }
  }
  /**
   * Sets the dataLayer for Google Analytics
   *
   * @memberof WelcomeComponent
   * @returns {void}
   */
   public setDataLayer(): void {
    this.taggingService.pageSettings({
      subsection: 'welcome_tdd'
    });
    this.taggingService.sendTeliumDataView();
  }

  removeSign(cant : any){
    return Math.abs(cant);
  }

  /**
   * Show moves.
   *
   * @param {*} id
   * @memberof WelcomeComponent
   */
  public showMoves(id){
    let button= document.getElementById('button'+id);
    let element= document.getElementById('listMoves'+id);
    let txtB= document.getElementById('txtButton'+id);

    if(button.classList.contains('active')){
      this.taggingService.sendTeliumDataLink({
        'interaction_category': 'seleccion_movimiento', 
        'interaction_action':'detalle_movimientos',
        'interaction_label':'ver_menos'
      });
      element.classList.remove('show');
      button.classList.remove('active');
      txtB.innerHTML = "Ver más"
    }else{
      this.taggingService.sendTeliumDataLink({
        'interaction_category': 'seleccion_movimiento', 
        'interaction_action':'detalle_movimientos',
        'interaction_label':'ver_mas'
      });
      element.classList.add('show');
      button.classList.add('active');
      txtB.innerHTML = "Ver menos"
    }
  }
  /**
   * Clean data selected and internal session.
   * Show history view.
   *
   * @memberof WelcomeComponent
   */
   public showClarification() : void {
    this.taggingService.sendTeliumDataLink({
      'interaction_category': 'menu_lateral', 
      'interaction_action':'click_boton', 
      'interaction_label':'consulta_de_aclaraciones'
    });
    //TODO: check navigation
    /* this.dataProxy.setDataSource([]);
    this.dataProxy.internalSession = '';
    this.router.navigate(['history']); */
  }
  /**
   * Retrieve parsed date.
   *
   * @private
   * @param {*} dateToBeParsed
   * @returns
   * @memberof WelcomeComponent
   */
  public retrieveParsedDate(dateToBeParsed: any) {
    dateToBeParsed = dateToBeParsed.toString();
    moment.locale('es');
    return moment(dateToBeParsed, 'DD-MM-YYYY')
      .format('DD [<br />] MMMM');
     
  }

  /**
   * Highlight the title according to the filter.
   *
   * @public
   * @param {string} title
   * @returns {string}
   * @memberof WelcomeComponent
   */
  public highlightTitle(title: string): string {
    if (!this.filterDesc || this.filterDesc.length === 0) {
      return title;
    }
    return title.replace(new RegExp(this.filterDesc, 'gi'), (match) => {
      return `<span class="highlightText">${match}</span>`;
    });
  }
  /**
    * Opener Light Box.
    *
    * @param {*} event
    * @param {*} id
    * @memberof WelcomeComponent
    */
   public tooltipOpener(event, id){
    this.taggingService.sendTeliumDataLink({
      'interaction_category': 'seleccion_movimiento', 
      'interaction_action':'movimiento_seleccionados', 
      'interaction_label':'ayuda'
    });
    //Define tooltipeText
    let ttText = '';

    if(id){
      ttText = 'Por favor toque “x” para eliminar un movimiento que ya no desee mandar a aclarar.';
    }else{
      ttText = 'Es importante señalar que se pueden aclarar movimientos de hasta 3 meses anteriores a la fecha actual.';
    }

   
    let y = event.clientY;
    let x = event.clientX;
    let tooltip = document.getElementById('tooltip-box');
    let backdrop = document.getElementById('backdrop');
    let tooltipText  = document.getElementById('tooltip-text');
    let flagBorder = document.getElementById('flag-border');
    let flagColor = document.getElementById('flag-color');

    tooltipText.innerHTML = ttText;
    tooltip.style.top = (y-104)+'px';
    tooltip.style.position = 'fixed';
    tooltip.style.left = (x-156)+'px';
    flagColor.style.left = 50+'%';
    flagBorder.style.left = 50+'%'  ;
    backdrop.classList.remove("tooltip-hide");
    tooltip.classList.remove("tooltip-hide");

    if(id === 1){
      tooltip.style.left = (x-274)+'px';
      flagColor.style.left = 90+'%';
      flagBorder.style.left = 90+'%'  ;
    }

  }
  /**
   * Toggle nav.
   *
   * @private
   * @memberof WelcomeComponent
   */
  private toggleNav(): void {
    // Check if the div is open
    if (!this.navToggle) {
      this.navigationService.tapBack('filters', this.toggleNav);
    } else {
      this.navigationService.tapBack('filters', this.navigationService.goToRoot);
    }
    //TODO: check the error this.navigationService.validateSession();
    this.navToggle = !this.navToggle;
    this.isHideNav = !this.isHideNav;
  }
  /**
   * Display filter by periods modal
   *
   * @memberof WelcomeTddComponent
   */
  public showPeriodFilters() : void {
    this.toggleId = 'selectedMovComp';
    this.toggleNav();
    this.generateSelectedView();
  }
  /**
   * Hide filter by period modal
   *
   * @memberof WelcomeTddComponent
   */
  public applyPeriodFilter() : void {
    let showHideMovementsElement : HTMLElement = document.querySelectorAll('[name="showHideMovements"]')[0] as HTMLElement;
    if(showHideMovementsElement !== undefined) { showHideMovementsElement.click(); }
    this.filterApply = true;
    this.hideToggle();
  }
  /**
   * Clean filter when Limpiar Filtros is clicked
   *
   * @memberof WelcomeTddComponent
   */
  public cleanPeriodFilter() : void {
    this.toggleNav();
    this.filterApply=false;
    this.sfApply = false;
    this.generateMovesArray();
    this.deleteFilters();
    this.filterNameApply = '';
    this.enable = false;
    this.filterName='';
    this.isFiltered = false;
  }
  public cleanPeriodFilterFromIcon() : void {
    this.filterApply=false;
    this.sfApply = false;
    this.generateMovesArray();
    this.deleteFilters();
    this.filterNameApply = '';
    this.enable = false;
    this.filterName='';
    this.isFiltered = false;
  }
}
