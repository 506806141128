import { Injectable } from '@angular/core';
import { InternalStateType } from '../../app/models/internal-state.model.state'

/**
 * the state of app 
 *
 * @export
 * @class AppStateService
 */
@Injectable()
export class AppStateService {

  /**
   * contains the state 
   *
   * @type {InternalStateType}
   * @memberof AppStateService
   */
  public _state: InternalStateType = { };

 /**
  * Already return a clone of the current state.
  *
  * @memberof AppStateService
  */
 public get state() {
    return this._state = this._clone(this._state);
  }


 /**
  *
  * Never allow mutation
  *
  * @memberof AppStateService
  */
 public set state(value) {
    throw new Error('do not mutate the `.state` directly');
  }

  
/**
 * the getter of state
 *
 * @param {*} [prop]
 * @returns
 * @memberof AppStateService
 */

public get(prop?: any) {
    /**
     * Use our state getter for the clone.
     */
    const state = this.state;
    return state.hasOwnProperty(prop) ? state[prop] : state;
  }

/**
 * set state 
 *
 * @param {string} prop
 * @param {*} value
 * @returns
 * @memberof AppStateService
 */
public set(prop: string, value: any) {
    /**
     * Internally mutate our state.
     */
    return this._state[prop] = value;
  }
  
/**
 * Simple object clone to json formart
 *
 * @private
 * @param {InternalStateType} object
 * @returns
 * @memberof AppStateService
 */

private _clone(object: InternalStateType) {
    return JSON.parse(JSON.stringify( object ));
  }
}
