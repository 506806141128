import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as md5 from 'blueimp-md5';
import { SessionStorageService } from './tdd/session-storage.service';
import { DataProxyService } from './data-proxy.service';
import { TealiumUtagService } from './tealium/utag.service'
import version from '../../../package.json';

/**
 * Window interface for the TypeScript compiler
 */
declare global {
    interface Window {
        ga: any;
        utag_data: any;
        dataLayer: any;
        utag: any;
    }
}
/**
 * the tagging service 
 * @export
 * @class TaggingService
 */
@Injectable()
export class TaggingService {
    /**
     *  the type of custom Dimensions
     *
     * @private
     * @type {Array<{index: string, label: string}>}
     * @memberof TaggingService
     */
    private customDimensions: Array<{index: string, label: string}> = [];
    /**
     * the types of events 
     *
     * @private
     * @type {Array<{index: string, label: string}>}
     * @memberof TaggingService
     */
    private events: Array<{index: string, label: string}> = [];
    /**
     * save the tealium config
     *
     * @private
     * @type {Array<{index: string, label: string}>}
     * @memberof TaggingService
     */
    private pageSettingsConfig : any;

    /**
     * Creates an instance of TaggingService.
     * @param {SessionStorageService} storage
     * @param {TealiumUtagService} tealium
     * @param {DataProxyService} dataProxy
     * @memberof TaggingService
     */
    constructor(
        private storage: SessionStorageService,
        private tealium: TealiumUtagService,
        public dataProxy: DataProxyService
    ) {
        // Google Analytics custom dimensions
        this.customDimensions = [
            {index: '1', label: 'section'},
            {index: '2', label: 'subsection1'},
            {index: '3', label: 'subsection2'},
            {index: '4', label: 'subsection3'},
            {index: '5', label: 'page'},
            {index: '6', label: 'screenView'},
            {index: '7', label: 'userID'},
            {index: '8', label: 'movement_id'},
            {index: '9', label: 'got_card'},
            {index: '10', label: 'commerce_interactions'},
            {index: '11', label: 'clarification_reason'},
            {index: '12', label: 'current_location'},
            {index: '13', label: 'federal_entity'},
            {index: '14', label: 'card_incident'},
            {index: '15', label: 'loss_date'},
            {index: '16', label: 'amount'},
            {index: '17', label: 'funnel'},
            {index: '18', label: 'process'},
            {index: '19', label: 'card_number'},
            {index: '20', label: 'search_movements'},
            {index: '21', label: 'filter_movements'},
            {index: '22', label: 'filter_movements-cut_date'},
            {index: '23', label: 'selected_movements'},
            {index: '24', label: 'delete_selected_movements'},
            {index: '25', label: 'select_movement_icon'},
            {index: '26', label: 'select_movement_description'},
            {index: '27', label: 'show_alert'},
            {index: '28', label: 'select_reason'},
            {index: '29', label: 'total_amount'},
            {index: '30', label: 'total_amount_paid'},
        ];
        // Tealium events
        this.events = [
            {index: '1', label: 'interaction_category'},
            {index: '2', label: 'interaction_action'},
            {index: '3', label: 'interaction_label'}
        ];
    }

    /**
     * Add data to the Google Tag and Tealium data layers.
     *
     * @param {...any[]} args
     * @memberof TaggingService
     */
    public gtag(...args: any[]): void {
        _.assign(window.dataLayer, args);
        _.assign(window.utag_data, args);
    }

    /**
     * Set value for the User ID.
     *
     * @param {string} value
     * @memberof TaggingService
     */
    public setUserID(value: string): void {
        window.ga('set', 'userId', value);
        this.setDimenson('7', value);
    }

    /**
     * Set value for custom dimension at index "idx".
     *
     * @param {string} index
     * @param {string} value
     * @memberof TaggingService
     */
    public setDimenson(index: string, value: string): void {
        window.ga('set', 'dimension' + index, value);
        this.updateDataLayer(index, value);
    }

    /**
     * Set the pagename concatenating the values of other tags.
     *
     * @memberof TaggingService
     */
    public setPageName(): void {
        let pageName = '';
        const tags: String[] = [
            'section',
            'subsection1',
            'subsection2',
            'funnel',
        ];
        tags.forEach((tag) => {
            pageName += '/' + _.get(window.utag_data, tag);
        });
        window.utag_data['pagename'] = pageName;
    }

    /**
     * Add the key: value pair to the object.
     *
     * @param {string} index
     * @param {string} value
     * @returns {string}
     * @memberof TaggingService
     */
    public updateDataLayer(index: string, value: string): string {
        const item = _.find(this.customDimensions, {index});
        window.dataLayer[item.label] = value;
        window.utag_data[item.label] = value;
        return item.label;
    }

    /**
     * Track the user actions.
     *
     * @param {*} data
     * @memberof TaggingService
     */
    public uTagView(data: any): void {
        let dataView: Object = {};
        _.each(data, (value, key) => {
            this.setDimenson(key, value);
            dataView[this.updateDataLayer(key, value)] = value;
        });
        this.gtag(dataView);
        if (!_.isUndefined(window.utag)) {
            window.utag.view(dataView);
        }
    }

    /**
     * Send event.
     *
     * @param {string} category
     * @param {string} action
     * @param {string} label
     * @memberof TaggingService
     */
    public sendEvent(category: string, action: string, label: string): void {
        window.ga('send', 'event', category, action, label, {
            'dimension1': '', // Custom Dimension 1
            'dimension2': 'Some value 2', // Custom Dimension 2
            'hitCallback': () => {}
        });
    }

    /**
     * Send the custom dimension value with a pageview hit.
     *
     * @memberof TaggingService
     */
    public send(pName:any = ''): void {
        window.ga('send', 'pageview', pName );
    }
    /**
     * Setup when page init
     *
     * @memberof TaggingService
     */
    public pageSettings(configByPage : any, productType? : string) : void {
        let cardType : string;
        if(productType === 'credit' && this.dataProxy.getCreditCardFullData() !== null) {
            cardType = this.dataProxy.creditCardFullData.cardDesc;
        } else {
            cardType = this.storage.getFromLocal('userdata').cardName
        }
        this.pageSettingsConfig = {
            'tag_tipoSitio': 'Privado',
            'tag_idiomaPagina': 'Espanol',
            'tag_canalBanco': 'Aclaraciones',
            'section': 'Aclaraciones',
            'subsection1': configByPage.subsection,
            'subsection2': '', 
            'subsection3': '',
            'tag_titulo': `|${configByPage.subsection}`,
            'tag_url': `/${configByPage.subsection}`,
            'tag_versionApp': `${version}`,
            'tag_Marca_dispositivo': '',
            'tag_Sistema_operativo': `${this.getBrowserName()}`,
            'tag_TipoDispositivo': '',
            'tag_userId': `${md5(this.storage.getFromLocal('buc'), 'mx-aclaraciones-cs')}`,
            'tag_tipoDeAclaraciones': this.mappingCategoryAction(configByPage.claimType),
            'tag_TipodDeTarjeta': `${cardType.toLowerCase().replace(/ /g, '_')}`,
            'tag_CanalProcedencia': 'SuperNet',
        };
    }
    /**
     * Connect to telium config and send info
     *
     * @memberof TaggingService
     */
    public sendTeliumDataView() : void {
        this.tealium.setConfig();
        this.tealium.view(this.pageSettingsConfig);
    }
    /**
     * Connect to telium config and send info
     *
     * @memberof TaggingService
     */
    public sendTeliumDataLink(pageSettingsConfigLink : any, questionnaire? : any) : void {
        if(!pageSettingsConfigLink.hasOwnProperty('interaction_category')){
            pageSettingsConfigLink['interaction_category'] = this.mappingCategoryAction(questionnaire);
        }
        this.tealium.link(pageSettingsConfigLink);
    }
    /**
     * Create category value
     *
     * @private
     * @param {any[]} questions
     * @returns {string}
     * @memberof TaggingService
     */
    private mappingCategoryAction(questions : any[]) : string {
        if(questions === null || questions === undefined) { return 'seleccion_movimiento' }
        let categoryAction : string;
        if(questions[0].value === "Sí" && questions[1].value === "Sí") {
            switch (questions[2].value) {
            case "Cargo duplicado":
                categoryAction = 'cargos_duplicados';
            break;
            case "Monto alterado":
                categoryAction = 'monto_alterado';
            break;
            case "Cargos adicionales al autorizado":
                categoryAction = 'cargo_adicionales';
            break;
            case "Pago por otro medio":
                categoryAction = 'pago_otro_medio';
            break;
            case "Devolución no aplicada":
                categoryAction = 'devolucion_no_aplicada';
            break;
            case "Mercancías o servicios no proporcionados":
                categoryAction = 'servicios_no_proporcionados';
            break;
            case "Cancelación de servicio":
                categoryAction = 'cancelacion_servicio';
            break;
            default:
            break;
            }
        } else if(questions[0].value === "Sí" && questions[1].value.toUpperCase() === "NO") {
            categoryAction = "fraude_no_intereaccion_comercio";
        } else if(questions[0].value.toUpperCase() === "NO") {
            switch (questions[1].value) {
                case "La reporté como robada o extraviada":
                    categoryAction = 'robo_extraviada';
                    break;
                case "Me la robaron o la extravié y no la he reportado":
                    categoryAction = 'robo_no_reporte';
                    break;
                default:
                    break;
            }
        }
        return categoryAction;
    }
    /**
     * Get browser name
     *
     * @private
     * @returns {string}
     * @memberof TaggingService
     */
    private getBrowserName() : string {
        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
            return 'Opera';
        } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
            return 'Chrome';
        } else if(navigator.userAgent.indexOf("Safari") != -1) {
            return 'Safari';
        } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
            return 'Firefox';
        } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.DOCUMENT_NODE)) {
            return 'IE';
        } else {
            return 'Unknown';
        }
    }
}
