import { Pipe, PipeTransform } from '@angular/core';

/**
 *
 *
 * @export
 * @class MaskingPanPipe
 * @implements {PipeTransform}
 */
@Pipe({name: 'maskingPan'})
export class MaskingPanPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {string} value
   * @returns {string}
   * @memberof MaskingPanPipe
   */
  transform(value: string): string {
    let newstr = '';
    newstr = value.replace(' ', '');
    newstr = newstr.replace('/', '');
    newstr = newstr.replace('-', '');
    newstr = '***'+newstr.substr(-4);
    return newstr;
  }
}
