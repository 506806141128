import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe que convierte la fecha recibida como string a 
 * un formato definido
 * Dia de Mes, año
 *
 * @export
 * @class CustomMovesDatePipe
 * @implements {PipeTransform}
 */
@Pipe({name: 'customMovesDate'})
export class CustomMovesDatePipe implements PipeTransform {
  /**
   * Transform
   * Realiza un split por guion bajo y conviente el
   * primer caracter a Mayuscula de cada elemento
   *
   * @param {string} value
   * @returns {string}
   * @memberof CustomMovesDatePipe
   */
  public transform(value: string): string {
    let p = value.split('_');
    p[0]= p[0].charAt(0).toUpperCase() + p[0].slice(1);
    p[1]= p[1].charAt(0).toUpperCase() + p[1].slice(1);

    return `${ p[0] + p[1] } `;
  }
}
